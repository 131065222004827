//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    textOnGreenBlockInfo: {
        firmName: [<span><b>Создать сайт</b></span>,' - означает использовать по полной все коммуникационные возможности, которые предоставляют современные цифровые технологии и получить преимущество над конкурентами используя наш почти ',<span>20 летний</span>,' опыт веб разработок.']
    }
}

const landingTextOnGreenBlockReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingTextOnGreenBlockReducer;