import React from 'react';

const Service8 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Поддержка сайта</b> – это услуга, которая заключается в том, чтобы обеспечить сайту бесперебойную
                    работу и постоянное развитие. Комплексное сопровождение сайта основывается на постоянном
                    отслеживании работоспособности интернет ресурса, оперативном устранении возникших сбоев, выполнения
                    целого комплекса действий, чтобы свести к нулю возможные проблемы в его работе.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/12-support_1.jpg" className="mn-image"
                                                alt="Обслуживание сайтов" title="Обслуживание сайтов - D.Logic"/></div>
                <p>&nbsp;</p>

                <p>Но это не все. Для сайта может понадобиться внесение изменений в функционал, добавление других
                    разделов, языков, сервисов, обновление дизайна, структуры и содержимого. А также, работы по
                    поисковому продвижению, рекламе (например, Google и соцсети).</p>

                <p>Как можно видеть из выше перечисленного, функционирование веб-ресурса может сопровождаться достаточно
                    большим количеством разнообразных работ. Чтобы сайт помогал получать доход и привлекать клиентов, им
                    нужно заниматься комплексно. Понятно, что комплексная поддержка и сопровождение сайта может быть
                    разной, но в любом случае эту работу лучше предоставить специалистам своего дела.</p>

                <div className="mn-padding-8"/>
                <div className="mn-panel mn-blue mn-card-4 mn-center mn-padding-16 inner-panel-1">
                    <b>(099)370-35-37</b><br/> Звоните. Ответим на все ваши вопросы.</div>
                <div className="mn-padding-8"/>

                <p>Мы всегда готовы обеспечить стабильность и безопасность работы сайтов наших клиентов. Выполнить, при
                    необходимости, модернизацию сайта, дополнить его другими сервисами и возможностями. Помочь с
                    регистрацией доменного имени и предоставить услуги хостинга.</p>

                <h2 className="service-subtitle sub-dop">Комплекс услуг по поддержке веб-сайтов</h2>
                <p>Как правило, комплексная поддержка сайта содержит в себе такие работы:</p>
                <ul>
                    <li>наполнение веб-ресурса информацией</li>
                    <li>внесение изменение в функционал сайта</li>
                    <li>внесение изменений в дизайн web-сайта</li>
                    <li>устранение сбоев в работе</li>
                    <li>устранение ошибок персонала при работе с сайтом</li>
                    <li>написание seo-текстов</li>
                    <li>ежемесячная техническая поддержка сайта</li>
                    <li>настройки корпоративной почты</li>
                    <li>услуга хостинга</li>
                    <li>приобретение доменного имени</li>
                    <li>настройка систем безопасности</li>
                    <li>прочее..</li>
                </ul>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/12-support_2.jpg" className="mn-image"
                                                       alt="Поддержка сайтов" title="Сопровождение сайтов - D.Logic"/>
                </div>
                <p>&nbsp;</p>

                <p>Complex поддержка интернет сайта особенно подходит для корпоративного портала, интернет магазина,
                    сайта каталога, корпоративного сайта, интернет ресурсов с разветвленной структурой и насыщенными
                    сервисами. Сюда входят все основные услуги по наполнению, переделыванию, техническому сопровождению
                    и исправлению от сбоев.</p>

                <div className="mn-padding-24">
                    <div className="mn-cell-row">
                        <div className="mn-container mn-cell mn-cell-middle mn-teal mn-mobile fgg-1">
                            75<sup>%</sup>
                        </div>
                        <div className="mn-container mn-cell mn-cell-middle mn-teal mn-mobile fgg-2">
                            Созданных нами проектов<br/>находятся у нас на поддержке
                        </div>
                    </div>
                </div>
                <p>Если вам нужна комплексная поддержка сайта, заказывайте у нас услугу <b>Поддержка сайта</b>. Наши
                    цены очень демократичные. В этом вы можете убедиться, если позвоните к нам по <b>(099)370-35-37,
                        (044)383-20-18</b>.</p>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Что такое комплексная поддержка сайта?</h2>
                <h3>Поддержка веб-сайта</h3>
                <p>- это комплекс услуг, который включает в себя такие работы, как наполнение информацией, изменение
                    функционала интернет ресурса, исправления ошибок и сбоев, продвижение и развитие.</p>
                <p>&nbsp;</p>

                <h3>Обслуживание сайта</h3>
                <p>Студия D.Logic окажет поддержку вашим сайтам в плане администрирования, верстке, программированию,
                    развитию и разработке дополнительных сервисов, настройки хостинга, настройки сервера. Это будет
                    особенно логичным, если ваш сайт разрабатывали наши специалисты. В этом случае возрастет качество и
                    скорость комплексной поддержки.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/12-support_3.jpg" className="mn-image"
                                                       alt="Поддержка и сопровождение сайтов"
                                                       title="Сопровождение и поддержка сайтов - D.Logic"/></div>
                <p>&nbsp;</p>

                <h3>Сопровождение web сайта</h3>
                <p>В каком бы состоянии не находился ваш сайт, мы готовы принять его на постоянное сопровождение и
                    техническое обслуживание.</p>
                <p>&nbsp;</p>

                <h3>Комплексная поддержка сайта</h3>
                <p>- это когда нужно настроить веб сервер, панель управления, помочь настроить корпоративную почту,
                    настроить политики безопасности и доступ к настройкам сайта, вносить правки на сайт, исправить
                    ошибки, устранять сбои и постоянно заботиться о развитии и продвижении сайта.</p>
                <p>&nbsp;</p>

                <h3>Профессиональная поддержка сайта</h3>
                <p>Профессиональная поддержка web-сайта – это когда за интернет ресурсом закреплен отдельный мастер или
                    команда специалистов разного профиля, а именно ими могут быть программист, верстальщик, маркетолог,
                    копирайтер, аналитик, менеджер проекта. Они и несут ответственность за работоспособность веб-сайта
                    клиента.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/12-support_4.jpg" className="mn-image"
                                                       alt="Комплексная поддержка сайта"
                                                       title="Всестороннее сопровождение сайтов - агентство D.Logic"/>
                </div>
                <p>&nbsp;</p>

                <h3>Администрирование сайта</h3>
                <p>В нашем понимании администрирование веб-сайта – это разработка и внедрение стратегии достижения
                    сайтом бизнес целей, как например:</p>
                <ul>
                    <li>рост конверсии</li>
                    <li>рост продаж</li>
                    <li>привлечение клиентов</li>
                </ul>
                <p>Сюда не входят работы по наполнению просто новостями. А входят вопросы по разработке стратегии
                    развития сайта, вопросы его бизнес отдачи. Администратором сайта заказчика становится с нашей
                    стороны менеджер проекта, который работает с программистами, маркетологами, аналитиками и
                    техническими специалистами.</p>
                <p>&nbsp;</p>

                <h3>Поддержка сайта стоимость </h3>
                <p>Стоимость поддержки веб-сайта зависит от объема работ и их сложности. Поэтому, цена за работу
                    основывается на почасовых ставках наших специалистов.</p>
                <p>&nbsp;</p>

                <h3>Поддержка сайта сколько стоит?</h3>
                <p>Отвечая на такой вопрос, можно для ориентира взять интернет-магазин. Если считать, что внутренним его
                    наполнением занимается сотрудник фирмы клиента, а мы будем выполнять только такие работы (все или
                    некоторые), как внутренняя оптимизация, улучшение юзабилити сайта, проработка нового функционала и
                    т.п. То в зависимости от того насколько большой такой магазин и какие цели ставит заказчик стоимость
                    поддержки сайта ориентировочно будет от 10 тыс. грн в месяц.</p>
            </div>
        </div>
    )
}

export default Service8;