import React from 'react';
import {Link} from "react-router-dom";

const TopMenuBarForMobileModal = (props) => {

    let closeBigMenu = () => {
        document.querySelector('#id01').style.display='none'
    }

    let Services = (props) => {
        return props.serviseList.map(item => {
            /*if(document.location.pathname === item.serviceUrl) {*/
            if(props.path === item.serviceUrl) {
                return <span className="bg-menu">{item.serviceName}</span>
            } else {
                return <Link to={item.serviceUrl} className="inner_right_menu_link">{item.serviceName}</Link>
            }
        })
    }

    let rightServiceMenu = props.ruServices.map(nameGroup => {
        return (
            <div>
                <div className="mn-container mn-center mn-padding-4 mn-blue-gray inner_right_menu_title">{nameGroup.blockName}</div>
                <div className="inner-r-menu-dop">
                    <ul>
                        <Services serviseList={nameGroup.blockServiceList} path={props.path} />
                    </ul>
                </div>
            </div>
        )
    })

    return (
        <div id="id01" className="mn-modal">
            <div className="mn-modal-content mn-animate-top mn-card-4">
                <header className="mn-container mn-teal">
                    <span className="mn-display-topright"
                             onClick={closeBigMenu}>
                        <i className="fas fa-times"
                           style={{position: 'relative', right: '20px', top: '12px'}}/>
                    </span>
                    <h4>Наши услуги</h4>
                </header>
                <div className="inner-r-menu">
                    {rightServiceMenu}
                </div>
            </div>
        </div>
    )
}

export default TopMenuBarForMobileModal;