import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import RuInnerPageHeader from "../Common/Inner/RuInnerPageHeader";
import TopMenuBarForMobile from "../Common/Inner/TopMenuBarForMobile";
import Main from "./Main";
import Footer from "../Common/Footer/Footer";
import CopyRightsContainer from "../Common/CopyRights/CopyRightsContainer";


class Site extends React.Component {

    render() {
        let siteName = this.props.match.params.id;
        let project = this.props.sites.mainInfo.siteList.find(project => project.toRuPage === siteName);
        /*debugger*/
        if (project !== undefined) {
            return (
                <div className="portfolio_1">
                    <RuInnerPageHeader {...this.props}
                                       languageLinks={{
                                           toUaPage: `/ua/site/${project.toUaPage}`,
                                           toRuPage: `/site/${siteName}`
                                       }}
                                       topPhonesLanguagesLogoMenu={this.props.topPhonesLanguagesLogoMenu}/>
                    <TopMenuBarForMobile {...this.props}
                                         path={this.props.match.path}
                                         languageLinks={{
                                             toUaPage: `/ua/site/${project.toUaPage}`,
                                             toRuPage: `/site/${siteName}`
                                         }}
                                         topMenuBarForMobile={this.props.topMenuBarForMobile}
                                         ruTopMobileMenuDataMix={this.props.ruTopMobileMenuDataMix}
                                         ruServicesListInfo={this.props.ruServicesListDataMix.ruServicesListInfo}/>
                    {/*<MainContainer {...this.props.sites.mainInfo} />*/}
                    <Main {...this.props}
                          project={project}
                          brcr={this.props.sites.mainInfo.brcr}/>
                    <Footer/>
                    <CopyRightsContainer/>
                </div>
            );
        } else {
            return (
                <div>
                    404 - Page not found!
                </div>
            )
        }
    }
}

let mapStateToProps = (state) => {
    return {
        sites: state.site,
        topPhonesLanguagesLogoMenu: state.innerPageTopPhonesLogoMenu,

        topMenuBarForMobile: state.landingTopMenuBarForMobile,
        ruTopMobileMenuDataMix: state.ruTopMobileMenuData,
        ruServicesListDataMix: state.ruServicesListData
    }
}

export default connect(mapStateToProps)(withRouter(Site));