//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    logoAndTopMenuBarInfo: {
        logoUrl: '/assets/pics/logo_top.png',
        logoAlt: 'Логотип агентства D.Logic',
        logoTitle: 'Логотип D.Logic',
        topMenuBar: [
            {id: 1, link: '', nameLink: 'Создание сайтов'},
            {id: 2, link: '/company', nameLink: 'О нас'},
            {id: 3, link: '/services', nameLink: 'Услуги'},
            {id: 4, link: '/portfolio', nameLink: 'Портфолио'},
            {id: 5, link: '/clients', nameLink: 'Клиенты'},
            {id: 6, link: '/contact', nameLink: 'Контакты'}
        ]
    }
}

const landingLogoAndTopMenuBarReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingLogoAndTopMenuBarReducer;