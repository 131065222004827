import React from 'react';

const SecondParallax = (props) => {

    /*<div className="parallax-window main-3" data-parallax="scroll" data-image-src={props.photoUrl}>&nbsp;</div>*/
    return (
        <div className="main-3">&nbsp;</div>

    );
}

export default SecondParallax;