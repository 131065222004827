import React from 'react';
import {NavLink} from "react-router-dom";

const TopPhonesAndLanguages = (props) => {
    return (
        <div className="mn-container">
            <div className="mn-container mn-left">
                <span className="main-top-line-1pr">{props.city}</span> {props.phones}
            </div>
            <div className="mn-container mn-right">
                <NavLink to={props.uaLink}>{props.nameUaLink}</NavLink> &nbsp;&nbsp;&nbsp;
                <span className="selected-lang">{props.nameRuLink}</span></div>
            <div className="mn-clear"/>
        </div>
    );
}

export default TopPhonesAndLanguages;