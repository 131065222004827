//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            brcrPageName: 'Сайты созданные в D.Logic'
        },
        buttonActive: {link: '/portfolio', buttonName: 'Разработка'},
        buttonUnActive: {link: '/logotypes', buttonName: 'Дизайн'},
        pageTitle: 'Портфолио',
        pageTitlePink: 'разработка',
        siteList: [
            {
                key: 1,
                url: 'boxerresorts',
                photo_preview: '25-boxerresorts.jpg',
                alt: 'Сайт базы отдыха и бани Боксер (Киев)',
                title: 'Сайт базы отдыха Боксер и бани на дровах (Гидропарк, Киев)'
            },
            {
                key: 2,
                url: 'institut-germanii',
                photo_preview: '24-institute.jpg',
                alt: 'Сайт Института Германии (Киев)',
                title: 'Сайт Института Германии (Киев)'
            },
            {
                key: 3,
                url: 'montolit',
                photo_preview: '23-montolit.jpg',
                alt: 'Сайт о продукции Montolit (Италия)',
                title: 'Сайт о продукции Montolit (Италия)'
            },
            {
                key: 4,
                url: 'fenix',
                photo_preview: '22-td-fenix.jpg',
                alt: 'Интернет магазин ТД Феникс (Харьков)',
                title: 'Интернет магазин ТД Феникс (Харьков)'
            },
            {
                key: 5,
                url: 'ga-music',
                photo_preview: '21-ga-music.jpg',
                alt: 'Интернет магазин GA Music (Киев)',
                title: 'Интернет магазин GA Music (Киев)'
            },
            {
                key: 6,
                url: 'scanex',
                photo_preview: '20-scanex.jpg',
                alt: 'Корпоративный сайт фирмы Scanex (Киев)',
                title: 'Корпоративный сайт фирмы Scanex (Киев)'
            },
            {
                key: 7,
                url: 'megaline2',
                photo_preview: '19-mega-line.jpg',
                alt: 'Корпоративный сайт фирмы Мега-Лайн (Киев)',
                title: 'Корпоративный сайт фирмы Мега-Лайн (Киев)'
            },
            {
                key: 8,
                url: 'kievokna',
                photo_preview: '18-kiev-okna.jpg',
                alt: 'Корпоративный сайт фирмы Киев-Окна',
                title: 'Корпоративный сайт фирмы Киев-Окна'
            },
            {
                key: 9,
                url: 'jashma',
                photo_preview: '17-jashma-centr.jpg',
                alt: 'Корпоративный сайт фирмы Яшма-Центр',
                title: 'Корпоративный сайт фирмы Яшма-Центр'
            },
            {
                key: 10,
                url: 'rosukrpallet',
                photo_preview: '16-rosukr-pallet.jpg',
                alt: 'Сайт визитка фирмы РосУкрПаллет',
                title: 'Сайт визитка фирмы РосУкрПаллет'
            },
            {
                key: 11,
                url: 'aluminiy',
                photo_preview: '15-magazin-aluminiy.jpg',
                alt: 'Интернет магазин Алюминий (КЗАСК)',
                title: 'Интернет магазин Алюминий (КЗАСК)'
            },
            {
                key: 12,
                url: 'seal',
                photo_preview: '14-seal.jpg',
                alt: 'Корпоративный сайт фирмы Сеал',
                title: 'Корпоративный сайт фирмы Сеал'
            },
            {
                key: 13,
                url: 'makstyle',
                photo_preview: '13-makstyle.jpg',
                alt: 'Корпоративный сайт фирмы МакСтайл',
                title: 'Корпоративный сайт фирмы МакСтайл'
            },
            {
                key: 14,
                url: 'budpartner2',
                photo_preview: '12-bud-partner-2.jpg',
                alt: 'Интернет магазин фирмы БудПартнер-2',
                title: 'Интернет магазин фирмы БудПартнер-2'
            },
            {
                key: 15,
                url: 'reatex',
                photo_preview: '11-reatex.jpg',
                alt: 'Корпоративный сайт фирмы Реатекс (Киев)',
                title: 'Корпоративный сайт фирмы Реатекс (Киев)'
            },
            {
                key: 16,
                url: 'megaline',
                photo_preview: '10-megaline-old.jpg',
                alt: 'Первый вариант корпоративного сайта фирмы Мега-Лайн',
                title: 'Предыдущий вариант сайта фирмы Мега-Лайн'
            },
            {
                key: 17,
                url: 'mirador',
                photo_preview: '9-mirador.jpg',
                alt: 'Корпоративный сайт фирмы Мирадор',
                title: 'Корпоративный сайт фирмы Мирадор'
            },
            {
                key: 18,
                url: 'reflight',
                photo_preview: '8-reflite.jpg',
                alt: 'Интернет магазин фирмы Рефлайт',
                title: 'Интернет магазин фирмы Рефлайт'
            }
        ],
        pageText: [
            <div>

            </div>
        ]
    }
}

const portfolioReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default portfolioReducer;