import React from 'react';
import {connect} from "react-redux";
import Slogan from "./Slogan";

class SloganContainer extends React.Component {
    render() {
        return (
            <div className="left-right-flex-pad">
                <div className="mn-container mn-center main-top-line-3">
                    <Slogan {...this.props.slogan.sloganInfo} />
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        slogan: state.landingSlogan
    }
}

export default connect(mapStateToProps)(SloganContainer);