//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    performedProjectsInfo: {
        fonImgUrl: '/assets/pics/main_fon.jpg',
        siteProjectsTitle: 'Созданные сайты',
        siteProjectsList: [
            {id: 13, url: '/site/boxerresorts', class: 'main-6-2-13', title: 'База отдыха Боксер | Банный комплекс Боксер'},
            {id: 12, url: '/site/institut-germanii', class: 'main-6-2-12', title: 'Новостной сайт Института Германии'},
            {id: 11, url: '/site/montolit', class: 'main-6-2-11', title: 'Сайт презентация продукции Монтолит (Италия)'},
            {id: 10, url: '/site/fenix', class: 'main-6-2-10', title: 'Интернет магазин Феникс'},
            {id: 9, url: '/site/ga-music', class: 'main-6-2-9', title: 'Интернет магазин GA Music'},
            {id: 8, url: '/site/scanex', class: 'main-6-2-8', title: 'Сайт каталог фирмы Scanex'},
            {id: 7, url: '/site/megaline2', class: 'main-6-2-7', title: 'Корпоративный сайт каталог фирмы Мега-Лайн'},
            {id: 6, url: '/site/kievokna', class: 'main-6-2-6', title: 'Корпоративный сайт фирмы Киев-Окна'},
            {id: 5, url: '/site/makstyle', class: 'main-6-2-5', title: 'Сайт визитка фирмы MakStyle'},
            {id: 4, url: '/site/jashma', class: 'main-6-2-4', title: 'Копоративный сайт фирмы Яшма Центр'},
            {id: 3, url: '/site/rosukrpallet', class: 'main-6-2-3', title: 'Сайт визитка фирмы РосУкрПаллет'},
            {id: 2, url: '/site/budpartner2', class: 'main-6-2-2', title: 'Интернет магазин электро- и бензо-инструментов БудПартнер-2'},
            {id: 1, url: '/site/aluminiy', class: 'main-6-2-1', title: 'Сайт каталог Магазина Алюминий'}
        ],
        siteButtonUrl: '/portfolio',
        siteButtonName: 'Больше работ',

        logoProjectsTitle: 'Созданные логотипы',
        logoProjectsList: [
            {id: 6, url: '/logo/meridian', class: 'main-6-logo-6', title: 'Логотип программы Меридиан для часто путешествующих пасажиров авиакомпании Аэросвит'},
            {id: 5, url: '/logo/fastfood', class: 'main-6-logo-5', title: 'Логотип выставки Fast Food - Индустрия быстрого питания'},
            {id: 4, url: '/logo/roxvision', class: 'main-6-logo-4', title: 'Логотип компании Rox Vision (USA)'},
            {id: 3, url: '/logo/medicom', class: 'main-6-logo-3', title: 'Логотип торговой компании МЕДИКОМ'},
            {id: 2, url: '/logo/agrolider', class: 'main-6-logo-2', title: 'Логотип фермерского комплекса АгроЛидерУкраина'},
            {id: 1, url: '/logo/vertex', class: 'main-6-logo-1', title: 'Логотип проектной компании Вертекс'}
        ],
        logoButtonUrl: '/logotypes',
        logoButtonName: 'Больше работ'
    }
}

const landingPerformedProjectsReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingPerformedProjectsReducer;