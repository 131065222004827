import React from 'react';
import {Link} from "react-router-dom";

const FooterServices = (props) => {

    let serviceLinks = props.serviceList.map(row => {
        return (
            <li><Link to={row.link}>{row.linkText}</Link></li>
        )
    })

    return (
        <div className="mn-container mn-col l3 m6 mn-padding-32">
            <div className="main-11-title">{props.title}</div>
            <div className="">
                <ul>
                    {serviceLinks}
                </ul>
            </div>
        </div>
    );
};

export default FooterServices;