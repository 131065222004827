//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    ruServicesListInfo: {
        ruServices: [
            {
                id: 1,
                blockName: 'Разработка',
                blockServiceList: [
                    {
                        id: 1,
                        serviceUrl: '/service/sozdanie-korporativnyh-saitov',
                        serviceName: 'Создание корпоративных сайтов'
                    },
                    {
                        id: 2,
                        serviceUrl: '/service/razrabotka-internet-magazinov',
                        serviceName: 'Разработка интернет-магазинов'
                    },
                    {
                        id: 3,
                        serviceUrl: '/service/razrabotka-saita-kataloga',
                        serviceName: 'Разработка сайта каталога'
                    },
                    {
                        id: 4,
                        serviceUrl: '/service/sozdanie-saita-vizitki',
                        serviceName: 'Создание сайта визитки'
                    },
                    {
                        id: 5,
                        serviceUrl: '/service/razrabotka-landing-page',
                        serviceName: 'Разработка Landing Page'
                    },
                    {
                        id: 6,
                        serviceUrl: '/service/sozdanie-mobilnoi-versii-saita',
                        serviceName: 'Создание мобильной версии сайта'
                    },
                    {
                        id: 7,
                        serviceUrl: '/service/razrabotka-onlain-servisov',
                        serviceName: 'Корпоративный портал'
                    },
                    {
                        id: 8,
                        serviceUrl: '/service/proektirovanie-interfeisov',
                        serviceName: 'Проектирование интерфейсов'
                    }
                ]
            },
            {
                id: 2,
                blockName: 'Дизайн',
                blockServiceList: [
                    {id: 1, serviceUrl: '/service/razrabotka-dizaina-saita', serviceName: 'Разработка дизайна сайта'},
                    {
                        id: 2,
                        serviceUrl: '/service/razrabotka-dizaina-internet-magazina',
                        serviceName: 'Разработка дизайна интернет магазина'
                    },
                    {id: 3, serviceUrl: '/service/redizain-saita', serviceName: 'Редизайн сайта'}
                ]
            },
            {
                id: 3,
                blockName: 'Поддержка',
                blockServiceList: [
                    {
                        id: 1,
                        serviceUrl: '/service/kompleksnaya-podderzshka-saitov',
                        serviceName: 'Комплексная поддержка сайта'
                    },
                    {id: 2, serviceUrl: '/service/podbor-domena-hosting', serviceName: 'Подбор домена, хостинг'}
                ]
            },
            {
                id: 4,
                blockName: 'Продвижение и реклама',
                blockServiceList: [
                    {
                        id: 1,
                        serviceUrl: '/service/kompleksnyi-audit-saita',
                        serviceName: 'Аудит сайта для поискового продвижения (SEO аудит)'
                    },
                    {id: 2, serviceUrl: '/service/poiskovoe-prodvizenie-saita', serviceName: 'Поисковое продвижения сайта'},
                    {
                        id: 3,
                        serviceUrl: '/service/internet-reklama-v-google-facebook',
                        serviceName: 'Контекстная реклама (Google, Facebook)'
                    }
                ]
            },
            {
                id: 5,
                blockName: 'Стиль',
                blockServiceList: [
                    {id: 1, serviceUrl: '/service/razrabotka-logotipa', serviceName: 'Разработка логотипов'},
                    {
                        id: 2,
                        serviceUrl: '/service/razrabotka-korporativnogo-stilya',
                        serviceName: 'Разработка корпоративного стиля'
                    },
                    {id: 3, serviceUrl: '/service/sozdanie-brendbuka', serviceName: 'Создание брендбука'}
                ]
            }
        ]
    }

}

const ruServicesListReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default ruServicesListReducer;