import React from 'react';

const Service11 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Удобный и привлекательный пользовательский интерфейс</b> – важный показатель качества сайта.
                    Элементы интерфейса должны быть логически структурированы и взаимосвязаны. Проектирование интерфейса
                    нужно основывать на законах визуального дизайна, иконографики и типографики. Применять современные
                    закономерности информационной архитектуры и основные постулаты поведенческой психологии людей.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/8-interface_2.jpg" className="mn-image"
                                                alt="Дизайн интерфейса сайта"
                                                title="Пользовательский интерфейс для сайта от D.Logic"/><img
                    src="/assets/photos/8-interface_2-1.jpg" className="mn-image" alt="Элементы интерфейса сайта"
                    title="Проектирование элементов пользовательского интерфейса - D.Logic"/></div>
                <p>&nbsp;</p>

                <p>А если говорить проще, то главная задача web интерфейсов – сделать максимально удобным процесс
                    серфинга по сайту и максимально упростить жизнь пользователю. При разработке пользовательских
                    интерфейсов желательно придерживаться нескольких правил:</p>
                <ul>
                    <li>в структуре не сильно оригинальничать</li>
                    <li>понимать цели и поведенческие привычки пользователей</li>
                    <li>не решать интерфейсом посторонних задач</li>
                    <li>не усложнять чрезмерно логику</li>
                    <li>интерфейс должен быть выполнен в едином стиле</li>
                </ul>

                <p>Грамотная структура и понятная навигация всегда упростит жизнь пользователю. Посетитель сайта
                    достигнет нужного для себя результата, затрачивая минимум усилий и не получая раздражения. Удобный
                    сайт привлекателен для пользователя. Чем быстрее человек находит для себя нужную информацию, тем
                    скорее он может стать потенциальным клиентом.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/8-interface_1.jpg"
                                                       className="mn-image mn-hide-small"
                                                       alt="Структура пользовательского интерфейса"
                                                       title="Структура интерфейса для веб-сайта"/><img
                    src="/assets/photos/8-interface_1min.jpg" className="mn-image mn-hide-medium mn-hide-large"
                    alt="Разработка структуры интерфейса сайта"
                    title="Проектирование структуры для пользовательского интерфейса - D.Logic"/></div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle sub-dop">Особенности разработки интерфейса сайта</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Информационная структура</span>
                </div>
                <div className="result-list-descr">Специалист по разработке интерфейсов определяет, как правильно
                    организовать, упорядочить и структурировать всю информацию на страницах так, чтобы пользователю было
                    легко ее воспринимать и ориентироваться в ней. Сюда относят выбор места расположения меню навигации,
                    места для ротаторов, фотогалерей, полей форм, выпадающих подсказок и т.п.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Планирование взаимодействия</span>
                </div>
                <div className="result-list-descr">Сюда относятся сервисы по взаимодействию пользователя с сайтом.
                    Механизмы, с помощью которых посетитель достигает своих целей с минимальными усилиями – подсказки,
                    формы регистрации и т.п.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Разумная привлекательность</span>
                </div>
                <div className="result-list-descr">Это означает, что главная задача интерфейса сайта с одной стороны,
                    быть стильным и привлекательным, а с другой, не мешать ориентироваться в нем человеку. Интерфейс
                    должен быть визуально привлекательным, удобным и выполнен в едином стиле.
                </div>
            </div>
        </div>
    )
}

export default Service11;