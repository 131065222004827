//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            link2: '/portfolio',
            linkName2: 'Сайты созданные в D.Logic ',
            brcrPageName: ''
        },
        pageTitle: '',
        pageText: [
            <div>

            </div>
        ],
        siteList: [
            {
                id: 1,
                toUaPage: 'site-boxerresorts',
                toRuPage: 'boxerresorts',
                siteDescr1: 'Сайт для базы отдыха и бани Боксер',
                siteDescr2: [2020 , 'highload' , 'cms' , 'privat', 'adaptive'],
                sitePhotos: ['108_boxerresorts_1.jpg' , '108_boxerresorts_2.jpg'],
                siteURL: 'http://boxerresorts.com.ua/'
            },
            {
                id: 2,
                toUaPage: 'site-instytutu-nimechyny',
                toRuPage: 'institut-germanii',
                siteDescr1: 'Сайт Института Германии',
                siteDescr2: [2019 , 'highload' , 'cms' , 'privat', 'adaptive'],
                sitePhotos: ['107_institute.jpg' , '107_institute_1.jpg' , '107_institute_2.jpg'],
                siteURL: 'http://www.inde.org.ua/'
            },
            {
                id: 3,
                toUaPage: 'sayt-pro-produktsiyu-montolit-italiya',
                toRuPage: 'montolit',
                siteDescr1: 'Сайт-презентация продукции Montolit (Италия)',
                siteDescr2: [2018 , 'highload' , 'cms' , 'privat', 'adaptive'],
                sitePhotos: ['106_montolit.png' , '106_montolit_1.png' , '106_montolit_2.png'],
                siteURL: 'http://www.montolit.in.ua/'
            },
            {
                id: 4,
                toUaPage: 'internet-magazin-dlya-td-fenix',
                toRuPage: 'fenix',
                siteDescr1: 'Интернет магазин для &quot;ТД Феникс&quot;',
                siteDescr2: [2018 , 'highload' , 'cms' , 'privat', 'adaptive'],
                sitePhotos: ['105_fenix.png' , '105_fenix_1.png' , '105_fenix_2.png'],
                siteURL: 'http://www.phenix.dlogic.com.ua/'
            },
            {
                id: 5,
                toUaPage: 'internet-magazin-dlya-ga-music',
                toRuPage: 'ga-music',
                siteDescr1: 'Интернет магазин для &quot;GA Music&quot;',
                siteDescr2: [2018 , 'highload' , 'cms' , 'privat', 'adaptive'],
                sitePhotos: ['104_gamusic.png' , '104_gamusic_1.png'],
                siteURL: 'http://www.stoyka.in.ua/'
            },
            {
                id: 6,
                toUaPage: 'korporativniy-sayt-dlya-firmi-scanex',
                toRuPage: 'scanex',
                siteDescr1: 'Корпоративный сайт для фирмы Scanex',
                siteDescr2: [2017 , 'highload' , 'cms' , 'privat', 'adaptive'],
                sitePhotos: ['103_scanex.png' , '103_scanex_1.png' , '103_scanex_2.png'],
                siteURL: 'http://www.scanex.com.ua'
            },
            {
                id: 7,
                toUaPage: 'onovlenniy-korporativniy-sayt-dlya-firmi-maga-line',
                toRuPage: 'megaline2',
                siteDescr1: 'Обновленный корпоративный сайт для фирмы Мега-Лайн',
                siteDescr2: [2017 , 'highload' , 'cms' , 'privat', 'adaptive'],
                sitePhotos: ['102_mega-line.png' , '102_mega-line_1.png' , '102_mega-line_2.png'],
                siteURL: 'http://www.mega-line.com.ua'
            },
            {
                id: 8,
                toUaPage: 'agregator-cin-allprice-ua',
                toRuPage: 'allprice',
                siteDescr1: 'Агрегатор цен интернет магазинов AllPrice.ua',
                siteDescr2: [2017 , 'highload' , 'cms' , 'privat'],
                sitePhotos: ['1_hotbiz.png' , '1_hotbiz_1.png' , '1_hotbiz_2.png'],
                siteURL: ''
            },
            {
                id: 9,
                toUaPage: 'korporativniy-sayt-dlya-firmi-kiiv-vikna',
                toRuPage: 'kievokna',
                siteDescr1: 'Корпоративный сайт для фирмы &quot;Киев-Окна&quot;',
                siteDescr2: [2009 , 'highload' , 'cms'],
                sitePhotos: ['101_kievokna.jpg' , '101_kievokna_1.jpg'],
                siteURL: 'http://www.kiev-vikna.com.ua/'
            },
            {
                id: 10,
                toUaPage: 'sayt-katalog-dlya-firmi-mega-layn',
                toRuPage: 'megaline',
                siteDescr1: 'Корпоративный сайт для фирмы &quot;Мега-Лайн&quot;',
                siteDescr2: [2009 , 'highload' , 'cms'],
                sitePhotos: ['3_megaline.png' , '3_megaline_1.png'],
                siteURL: 'http://www.mega-line.com.ua/'
            },
            {
                id: 11,
                toUaPage: 'sayt-vizitka-dlya-firmi-yashma-centr',
                toRuPage: 'jashma',
                siteDescr1: 'Корпоративный сайт для фирмы &quot;Яшма-Центр&quot;',
                siteDescr2: [2010 , 'highload' , 'cms'],
                sitePhotos: ['4_jashma.jpg' , '4_jashma_1.jpg'],
                siteURL: 'http://www.jashma-center.com.ua/'
            },
            {
                id: 12,
                toUaPage: 'internet-magazin-dlya-firmi-magazin-alyuminiy',
                toRuPage: 'aluminiy',
                siteDescr1: 'Интернет магазин Алюминий (КЗАСК)',
                siteDescr2: [2010 , 'highload' , 'cms'],
                sitePhotos: ['5_aluminie.jpg' , '5_aluminie_1.png' , '5_aluminie_2.jpg'],
                siteURL: 'http://www.ukrprofile.com.ua/'
            },
            {
                id: 13,
                toUaPage: 'korporativniy-sait-dlya-firmi-reateks',
                toRuPage: 'reatex',
                siteDescr1: 'Сайт для фирмы &quot;Реатекс&quot;',
                siteDescr2: [2010 , 'highload' , 'cms'],
                sitePhotos: ['6_reatex.jpg' , '6_reatex_1.png'],
                siteURL: ''
            },
            {
                id: 14,
                toUaPage: 'sayt-vizitka-dlya-firmi-rosukrpalet',
                toRuPage: 'rosukrpallet',
                siteDescr1: 'Сайт для фирмы &quot;РосУкрПаллет&quot;',
                siteDescr2: [2010 , 'highload' , 'cms'],
                sitePhotos: ['9_rosukrpallet.jpg' , '9_rosukrpallet_1.jpg'],
                siteURL: ''
            },
            {
                id: 15,
                toUaPage: 'internet-magazin-dlya-firmi-budpartner2',
                toRuPage: 'budpartner2',
                siteDescr1: 'Сайт для фирмы &quot;БудПартнер-2&quot;',
                siteDescr2: [2010 , 'highload' , 'cms'],
                sitePhotos: ['8_bp2.png' , '8_bp2_1.png' , '8_bp2_2.png' , '8_bp2_3.png'],
                siteURL: ''
            },
            {
                id: 16,
                toUaPage: 'korporativniy-sait-dlya-firmi-mirador',
                toRuPage: 'mirador',
                siteDescr1: 'Сайт для фирмы &quot;Мирадор&quot;',
                siteDescr2: [2006 , 'cms'],
                sitePhotos: ['7_mirador.png' , '7_mirador_1.png'],
                siteURL: ''
            },
            {
                id: 17,
                toUaPage: 'sayt-katalog-dlya-firmi-seal',
                toRuPage: 'seal',
                siteDescr1: 'Сайт для фирмы &quot;Сеал&quot;',
                siteDescr2: [2010 , 'highload' , 'cms'],
                sitePhotos: ['10_seal.jpg' , '10_seal_1.jpg' , '10_seal_2.jpg'],
                siteURL: ''
            },
            {
                id: 18,
                toUaPage: 'internet-magazin-dlya-firmi-reflayt',
                toRuPage: 'reflight',
                siteDescr1: 'Сайт для фирмы &quot;Рефлайт&quot;',
                siteDescr2: [2010 , 'highload' , 'cms'],
                sitePhotos: ['11_reflight.png' , '11_reflight_1.png'],
                siteURL: ''
            },
            {
                id: 19,
                toUaPage: 'sayt-vizitka-dlya-firmi-makstayl',
                toRuPage: 'makstyle',
                siteDescr1: 'Сайт для фирмы &quot;MakStyle&quot;',
                siteDescr2: [2008 , 'highload' , 'cms'],
                sitePhotos: ['12_makstyle.png' , '12_makstyle_1.png'],
                siteURL: ''
            },
        ]
    }
}

const siteReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default siteReducer;