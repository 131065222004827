import React from 'react';
import MainBrcr from "./MainBrcr";
import {Link} from "react-router-dom";

const Main = (props) => {

    let someInfo = props.project.siteDescr2.map(item => {
        return <span className="mn-round-medium portfolio_top_2">{item}</span>
    })

    let photos = props.project.sitePhotos.map(ph => {
        return (
            <>
            <div><img src={`/assets/pics/sites/${ph}`} className="mn-image" alt={props.siteDescr1} title={props.siteDescr1}/></div>
            <div className="mn-padding-48"/>
            </>
        )
    })

    let onSiteClick = (e, url) => {
        document.location.href = url;
    }

    return (
        <div className="left-right-flex-pad mn-padding-16">
            <div className="inner-gap"/>
            <div className="mn-container">
                <MainBrcr brcr={props.brcr} pageName={props.project.siteDescr1} />
            </div>
            <div className="mn-container mn-padding-16">
                <div className="mn-padding-24 mn-center">
                    <h2>{props.project.siteDescr1}</h2>
                    {someInfo}
                </div>
                <div className="mn-container mn-center">
                    <div className="mn-padding-12">&nbsp;</div>
                    {photos}
                </div>
                <div className="mn-container mn-center portfolio_1s">
                    <button className="mn-btn mn-small mn-round-medium mn-indigo mn-border mn-border-white"
                            onClick={(e) => onSiteClick(e, props.project.siteURL)}>Перейти на сайт</button>
                    <Link to={`/zakazat-uslugu`} className="mn-btn mn-small mn-round-medium mn-white mn-border mn-border-blue">Поработаем?</Link>
                </div>

            </div>
        </div>
    )
}

export default Main;