import React from 'react';
import {connect} from "react-redux";
import LogoAndTopMenuBar from "./LogoAndTopMenuBar";

class LogoAndTopMenuBarContainer extends React.Component {
    render() {
        return (
            <div className="mn-hide-medium mn-hide-small main-top-line-2">
                <div className="left-right-flex-pad">
                    <LogoAndTopMenuBar {...this.props.LogoAndTopMenuBar.logoAndTopMenuBarInfo} />
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        LogoAndTopMenuBar: state.landingLogoAndTopMenuBar
    }
}

export default connect(mapStateToProps)(LogoAndTopMenuBarContainer);