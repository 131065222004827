import React from 'react';
import {connect} from "react-redux";
import TopPhonesAndLanguages from "./TopPhonesAndLanguages";

class TopPhonesAndLanguagesContainer extends React.Component {
    render() {
        return (
            <div className="mn-hide-medium mn-hide-small main-top-line-1">
                <div className="left-right-flex-pad">
                    <TopPhonesAndLanguages {...this.props.TopPhonesAndLanguages.technologyStackInfo} />
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        TopPhonesAndLanguages: state.landingTopPhonesAndLanguages
    }
}

export default connect(mapStateToProps)(TopPhonesAndLanguagesContainer);