import React from 'react';

const Service15 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p>Предлагаем вашему вниманию весь спектр работ по поисковому продвижению сайтов и вывод их в топ
                    поисковой выдачи. Если ваш сайт не находится в топе или находится далеко внизу, это свидетельствует
                    о наличии ряда проблем. Их нужно просто найти и устранить. Здесь очень важно понимать, что в
                    интернете десятки тысяч веб ресурсов той же тематики, что и ваш сайт. Многие из них продвигаются
                    опытными специалистами оптимизаторами. Если вы не специалист в SEO продвижении, вам за ними не
                    угнаться. Вот почему лучше обратиться к нам, как специалистам в этом деле. Поверьте, любой сайт
                    можно вывести в топ, важно делать правильные шаги.</p>
                <p>Быстрый результат – это не про SEO раскрутку сайта. Первые существенные результаты появятся только
                    спустя 3-6 месяцев. Все работы проводятся скрупулезно, выверено и по четкому плану. Когда ваш сайт
                    выйдет в топ поисковой выдачи, все инвестиции в раскрутку окупятся сполна, так как ваш веб ресурс
                    получит просто огромный трафик. При этом, этот трафик будет целевым.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/15-seo_1.jpg" className="mn-image" alt="Продвижение сайта"
                                                title="Поисковое продвижение сайтов - D.logic"/></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Что включает в себя поисковое продвижение.</h2>
                <p>Комплексное поисковое продвижение сайта в D.Logic включает в себя ряд работ:</p>
                <ul>
                    <li>комплексный аудит сайта</li>
                    <li>аудит конкурентов</li>
                    <li>анализ юзабилити</li>
                    <li>составление семантического ядра</li>
                    <li>внутренняя оптимизация сайта</li>
                    <li>внешняя оптимизация сайта</li>
                </ul>
                <p>Работы по каждому из этих пунктов – сложная многосторонняя работа. Часть ее вообще не имеет шаблонных
                    решений. Каждый проект требует индивидуального подхода. Поэтому, неважно раскручиваете ли вы сайт
                    для малого бизнеса или интернет-магазин, вам нужны опытные специалисты, которые хорошо понимают, что
                    и когда надо делать.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/15-seo_3.jpg" className="mn-image"
                                                alt="Этапы поискового продвижения"
                                                title="Структура поискового продвижения от D.logic"/></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Этапы поискового продвижения.</h2>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Комплексный аудит сайта</span>
                    </div>
                    <div className="result-list-descr">Проводится полный, детальный анализ ресурса на предмет
                        соответствия требованиям поисковых систем. Выявление проблем и ошибок, которые приводят к
                        понижению рейтинга веб-ресурса в поисковой выдаче поисковой системы. Комплексный аудит включает
                        в себя технический аудит, контентный анализ, аудит юзабилити, аудит ссылочной массы и анализ
                        конкурентов.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Аудит конкурентов</span>
                    </div>
                    <div className="result-list-descr">Эта процедура дает сравнительный анализ вашего ресурса с сайтами
                        лидерами, находящиеся в топе поисковых запросов. Итогом данной работы будет список слабых и
                        сильных сторон конкурентного сайта, анализ структуры, ссылочная масса и ее динамика, структура
                        контента и предварительные выводы.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Анализ юзабилити сайта</span>
                    </div>
                    <div className="result-list-descr">Этот анализ в принципе есть составляющая комплексного аудита.
                        Предназначен для понимания того, насколько удобно пользователю находится на анализируемом сайте.
                        Сюда входит оценка текстового наполнения, навигации, логики функционала, дизайна ресурса и
                        другие критерии. Чем удобней сайт, тем лучше для продвижения.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Составление семантики или подбор запросов</span>
                    </div>
                    <div className="result-list-descr">На этом этапе выполняется составление списка групп ключевых слов,
                        их словосочетаний и морфологических форм для наиболее полной характеристики вида деятельности,
                        товара или услуги, который предлагает сайт.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Внутренняя оптимизация сайта</span>
                    </div>
                    <div className="result-list-descr">Производится оптимизация контента страниц сайта, тегов,
                        мета-тегов, внутренней перелинковки, удаления дублей страниц. Все это подготавливает сайт для
                        внешней оптимизации и помогает само по себе продвижению его в поисковых системах.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Внешняя оптимизация веб ресурса</span>
                    </div>
                    <div className="result-list-descr">Работа над увеличением качественной ссылочной массы. Другими
                        словами - наращивание ссылок на свой сайт из других ресурсов. Чем качественнее ссылки, тем лучше
                        для продвижения.
                    </div>
                </div>
                <div>&nbsp;</div>
                <div className="mn-center" style={{backgroundColor: '#F6F6F6'}}><img src="/assets/photos/15-seo_4.jpg"
                                                                                   className="mn-image"
                                                                                   alt="Структура SEO раскрутки сайта"
                                                                                   title="Этапы SEO продвижения от D.logic"/>
                </div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Преимущества поискового продвижения.</h2>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Рост числа потенциальных клиентов</span>
                    </div>
                    <div className="result-list-descr">Когда сайт находится не в ТОП – это значит, что он проигрывает в
                        настоящий момент в гонке за привлекательность у поисковой машины с другими сайтами такой же
                        тематики. Учитывая, что сайты, находящиеся в ТОП поисковой выдачи генерирует на себя львиную
                        доля трафика и потенциальных клиентов, то это значит, что вы теряете их. Клиенты о вас просто не
                        знают.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Увеличение продаж, прибыли</span>
                    </div>
                    <div className="result-list-descr">Сайт для вас – это инструмент продаж и должен быть всегда на виду
                        – быть в ТОП поисковой машины.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Кардинальное увеличение целевого трафика</span>
                    </div>
                    <div className="result-list-descr">Преимущества трудно переоценит. Если правильно составить список
                        запросов для поискового продвижения (семантическое ядро) и последовательно выполнить весь
                        комплекс работ по внутренней и внешней оптимизации, результатом будет не только попадание в ТОП.
                        Будет также рост целевого трафика и рост продаж. Причем кардинальный.
                    </div>
                </div>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/15-seo_2.jpg" className="mn-image"
                                                alt="Преимущества продвижения сайта"
                                                title="Увеличение продаж как результат успешного продвижения сайта"/>
                </div>
                <p>&nbsp;</p>
            </div>
        </div>
    )
}

export default Service15;