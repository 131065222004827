import React from 'react';
import Main from "./Main";
import {connect} from "react-redux";

class MainContainer extends React.Component {

    render() {
        return (
            <div className="left-right-flex-pad mn-padding-16">
                <Main {...this.props.main.mainInfo} />
            </div>
        )
    }
}

let mapStateToProps = (state) => {
    return {
        main: state.clients
    }
}

export default connect(mapStateToProps)(MainContainer);