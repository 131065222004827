import React from 'react';

const Service9 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p>Если вы столкнулись с ситуацией, когда нужно разработать новый индивидуальный (не шаблон) дизайн для
                    своего существующего или будущего сайта и не знаете как это сделать, мы готовы помочь вам решить эту
                    проблему.</p>
                <p>Скорее всего, вы интуитивно понимаете, что шаблонные решения, которые в большом количестве продаются
                    в интернете не выделят вас среди ваших конкурентов. Используя шаблонное решение для своего интернет
                    ресурса, вы рискует встретить сайт (и не один), который очень напоминает ваш сайт. А все потому, что
                    тот ресурс использовал точно такой же шаблон как вы. И таких примеров в истории сайтостроения
                    масса.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/9-site-design_1.jpg" className="mn-image"
                                                alt="Создание дизайна сайта"
                                                title="Разработка дизайна для сайта в студии D.Logic"/></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Что для нас "Хороший дизайн сайта"?</h2>
                <p>Это баланс таких элементов, как:</p>
                <ul>
                    <li>приятный и запоминающийся внешний вид</li>
                    <li>логичный, легкий и удобный функционал</li>
                    <li>"игра" цвета, формы и стиля</li>
                    <li>и все остальные, что вызывает только положительные эмоции</li>
                </ul>
                <p>Можно сказать, что <b>разработать хороший дизайн сайта</b> - это добиться гармонии формы и содержания
                    всех страниц интернет ресурса. Хорошо разработанный дизайн сайта всегда привлекает целевую
                    аудиторию, удерживает внимание и повышает доверие потенциальных клиентов.</p>

                <h2 className="service-subtitle sub-dop">Оригинальный дизайн сайта</h2>

                <p><b>Креативный дизайн сайта</b> — прямой путь к увеличению популярности интернет ресурса, его
                    запоминаемости. А значит, путь к увеличению продаж или, по крайней мере, путь к росту лояльности
                    пользователей.</p>
                <p>В уникальном дизайне воплощаются все пожелания и требования заказчика. Индивидуальный подход приводит
                    к тому, что дизайнерская работа приводит к очень интересному сочетанию оригинальных идей с
                    современными технологическими решениями, которые предоставляет IT-индустрия для
                    веб-разработчика. </p>
                <p>Поэтому, доверьте работу по созданию дизайна вашего сайта только профессионалам. Мы учтем все ваши
                    пожелания, вникнем в нюансы вашего бизнеса и создадим дизайн, за который вам не нужно будет
                    волноваться. </p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/9-site-design_2.jpg" className="mn-image"
                                                       alt="Основные моменты при разработке дизайна сайта"
                                                       title="Очень важна гармония цыета и элементов для создании креативного дизайна для сайта"/>
                </div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Мы можем предложить такие услуги веб-дизайна</h2>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Дизайн Landing page</span>
                    </div>
                    <div className="result-list-descr">Для улучшения конверсии и увеличения эффективности продаж.</div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Адаптивный дизайн сайта  </span>
                    </div>
                    <div className="result-list-descr">Такой дизайн создает возможность человеку комфортно просматривать
                        сайт не только на компьютере, но и на смартфоне или планшете.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Дизайн корпоративного сайта</span>
                    </div>
                    <div className="result-list-descr">Для повышения узнаваемости компании или бренда в интернете,
                        формирования имиджа и улучшения лояльности клиентов.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Редизайн сайта</span>
                    </div>
                    <div className="result-list-descr">Важен для поддержания современного имиджа и повышения
                        лояльности.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Индивидуальный дизайн сайта</span>
                    </div>
                    <div className="result-list-descr">Уникальное и стильное решение для любых компаний, бизнес целей и
                        технологий
                    </div>
                </div>

                <div className="mn-panel mn-blue mn-card-4 mn-center mn-padding-16 inner-panel-1">
                    <b>(099)370-35-37</b><br/> Звоните. Ответим на все ваши вопросы.</div>

                <h2 className="service-subtitle sub-dop">Разработать новый web–дизайн будет хорошим решением, если вам
                    необходимо:</h2>
                <ul>
                    <li>сделать сайт адаптивным под разные устройства</li>
                    <li>переделать устаревший дизайн сайта</li>
                    <li>исправить неудачный дизайн сайта</li>
                    <li>чтобы дизайн соответствовал новому направлению деятельности</li>
                    <li>повысить конверсию сайта</li>
                </ul>

                <h2 className="service-subtitle sub-dop">Дизайн сайта по готовому шаблону</h2>
                <p>Не редко бывают ситуации, как, например, когда нет времени или недостаточно ресурсов на разработку
                    уникального дизайна сайта или имеется готовый шаблон, который очень нравится заказчику, и на основе
                    которого нужно подготовить дизайн будущего интернет проекта. В этом случае наш дизайнер берет
                    выбранный шаблон или решение за основу и подгоняет его под функционал уже готового сайта.</p>
                <p>При работе над таким дизайном не забываем о таких нюансах, как:</p>
                <ul>
                    <li>удобство навигации</li>
                    <li>размещение информации</li>
                    <li>комфортность при восприятии информации</li>
                    <li>отсутствие отвлекающих или раздражающих моментов</li>
                    <li>правильное сочетание цвета и формы</li>
                </ul>
                <p>Разработка в Киеве такого дизайна для сайта не будет стоить дорого, как разработка уникального
                    дизайна. Но даже в этом случае, заказчик получает от нас решение, отличающееся от шаблона и имеющее
                    много оригинальных моментов. Что, по сути, тоже будет назвать оригинальным дизайном, хоть и
                    основанным на шаблоне. В любом случае, цена и время имеет значение.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/9-site-design_3.jpg" className="mn-image"
                                                       alt="Дизайн сайта по шаблону"
                                                       title="Один из способов дизайна сайта - это дизайн по шаблону"/>
                </div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Этапы разработки web дизайна</h2>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>1. Подготовка</span>
                    </div>
                    <div className="result-list-descr">Вместе с вами определяем цели и задачи проекта, для которого
                        собственно и будет разработан дизайн. Также, изучаем и анализируем сайты конкурентов.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>2. Планирование</span>
                    </div>
                    <div className="result-list-descr">Наши специалисты, исходя из поставленных целей и задач,
                        предлагают оригинальные решения по структуре, функционалу, юзабилити и цветовой гамме проекта.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>3. Создание макета</span>
                    </div>
                    <div className="result-list-descr">На данном этапе идет процесс создания дизайна. Разрабатывается
                        интуитивно понятная навигация и ее элементы: вид, динамика, форма и расположение. Выполняется
                        оформление страниц сайта.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>4. Принятие работ заказчиком</span>
                    </div>
                    <div className="result-list-descr">На завершающем этапе заказчик оценивает выполненную работу и
                        соотносит результат с целями и задачами, которые определялись на этапе #1.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service9;