import React from 'react';
import {connect} from "react-redux";
import SeoTextsBlock from "./SeoTextsBlock";

class SeoTextsBlockContainer extends React.Component {
    render() {
        return (
            <div className="main-8">
                <div className="left-right-flex-pad">
                    <div className="mn-container mn-padding-64">
                        <SeoTextsBlock {...this.props.seoTextsBlock.seoTexts} />
                    </div>
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        seoTextsBlock: state.landingSeoTextsBlock
    }
}

export default connect(mapStateToProps)(SeoTextsBlockContainer);