import React from 'react';
import {Link} from "react-router-dom";

const PerformedLogotypeProjectsButton = (props) => {
    return (
        <div className="mn-container">
            <Link to={props.logoButtonUrl} className="main-6-butt">{props.logoButtonName}</Link>
        </div>
    );
}

export default PerformedLogotypeProjectsButton;