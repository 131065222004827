import React from 'react';
import TextUnderTopPhotoContainer from "./TextUnderTopPhoto/TextUnderTopPhotoContainer";
import TopPhonesAndLanguagesContainer from "./TopPhonesAndLanguages/TopPhonesAndLanguagesContainer";
import LogoAndTopMenuBarContainer from "./LogoAndTopMenuBar/LogoAndTopMenuBarContainer";
import LogoAndTopMenuBarWhenScrollingContainer from "./LogoAndTopMenuBarWhenScrolling/LogoAndTopMenuBarWhenScrollingContainer";
import TopMenuBarForMobileContainer from "./TopMenuBarForMobile/TopMenuBarForMobileContainer";
import SloganContainer from "./Slogan/SloganContainer";
import SecondParallaxContainer from "./SecondParallax/SecondParallaxContainer";
import TextOnGreenBlockContainer from "./TextOnGreenBlock/TextOnGreenBlockContainer";
import MainServicesListContainer from "./MainServicesList/MainServicesListContainer";
import OurHistoryContainer from "./OurHistory/OurHistoryContainer";
import TextBeforeOurWorksContainer from "./TextBeforeOurWorks/TextBeforeOurWorksContainer";
import YellowLine from "./YellowLine/YellowLine";
import PerformedProjectsContainer from "./PerformedProjects/PerformedProjectsContainer";
import TechnologyStackContainer from "./TechnologyStack/TechnologyStackContainer";
import SeoTextsBlockContainer from "./SeoTextsBlock/SeoTextsBlockContainer";
import TimeLineContainer from "./TimeLine/TimeLineContainer";
import TimeLineButtonContainer from "./TimeLineButton/TimeLineButtonContainer";
import GoogleMap from "./GoogleMap/GoogleMap";
import Footer from "../Common/Footer/Footer";
import CopyRightsContainer from "../Common/CopyRights/CopyRightsContainer";

const Landing = () => {
    return (
        <div>
            <header className="main-fon">
            {/*<header className="parallax-window main-fon" data-parallax="scroll" data-image-src={mainPhoto}>*/}
                <TopPhonesAndLanguagesContainer/>
                <LogoAndTopMenuBarContainer/>
                <LogoAndTopMenuBarWhenScrollingContainer/>
                <TopMenuBarForMobileContainer/>
                <SloganContainer/>
            </header>

            <TextUnderTopPhotoContainer/>
            <SecondParallaxContainer/>
            <TextOnGreenBlockContainer/>
            <MainServicesListContainer/>
            <OurHistoryContainer/>
            <TextBeforeOurWorksContainer/>
            <YellowLine/>
            <PerformedProjectsContainer/>
            <TechnologyStackContainer/>
            <SeoTextsBlockContainer/>
            <TimeLineContainer/>
            <TimeLineButtonContainer/>

            <GoogleMap/>
            <Footer/>
            <CopyRightsContainer/>
        </div>
    );
}

export default Landing;