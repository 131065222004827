import React from 'react';
import {Link} from "react-router-dom";

const PerformedLogotypeProjectsList = (props) => {

    let logoList = props.logoProjectsList.map(item => {
        return (
            <Link to={item.url}>
                <div className="main-6-1 wow slideInUp" data-wow-duration="2s">
                    <div className={item.class + " mn-opacity mn-hover-opacity-off"} title={item.title}/>
                </div>
            </Link>

        )
    })

    return (
        <div className="main-6-1s">
            {logoList}
        </div>
    );
}

export default PerformedLogotypeProjectsList;