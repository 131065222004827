import React from 'react';
import {Link} from "react-router-dom";

const FooterDlogicAndSocials = (props) => {

    let socialIcons = props.socialsIcons.map(icon => {
        return (
            <li><Link to={icon.link} className="main-media" title={icon.iconTitle}><i
                className={icon.iconStyle} style={{fontSize: '18px'}}/></Link></li>
        )
    })

    return (
        <div className="mn-container mn-col l3 m6 mn-padding-32">
            <div className="main-11-title"><span className="main-11-title-d">{props.title}</span></div>
            <div className="main-11-text">{props.description}</div>
            <div className="mn-padding-24 main-11-media">
                <ul>
                    {socialIcons}
                </ul>
            </div>
        </div>
    );
}

export default FooterDlogicAndSocials;