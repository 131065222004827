import React from 'react';

const Service10 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Веб дизайн интернет магазина</b> – очень важный элемент, которому нужно уделить не меньше
                    внимания, чем функционалу. Тенденция в разработке дизайна современных интернет магазинов сместилась
                    в область минимализма графики и минимума изысков. По сути, на таком сайте все подчинено одной цели –
                    проинформировать пользователя о товаре, вариантах доставки, способах оплаты, о гарантиях и нюансах
                    возврата товара. Все остальное подчинено этому. Это касается и дизайна электронного магазина.</p>
                <p>Ведь зашедшего на сайт пользователя нельзя отвлекать от единственной цели – купить товар или заказать
                    услугу. Все подчинено этому. Украшательство не приветствуется. С другой стороны, каждый владелец
                    интернет магазина хочет сделать свой ресурс популярным и чтобы он выгодно отличался от
                    конкурентов.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/10-eshop-design_1.jpg" className="mn-image"
                                                       alt="Создание дизайна интернет магазина"
                                                       title="Разработка дизайна интернет магазинов в студии D.Logic"/>
                </div>
                <p>&nbsp;</p>

                <p>Вот здесь и важен опыт разработчика. Наши специалисты, имея многолетний опыт разработки дизайна
                    онлайн магазинов, создали не один десяток дизайнов и готовы реализовать все пожелания клиента.
                    Создать удобный, грамотно разработанный, визуально привлекательный дизайн пользовательского
                    интерфейса интернет магазина, не перегруженного излишествами и учитывающий фирменный стиль.
                    Реализуем все моменты, вплоть до мельчайших нюансов.</p>
                <p>Также, наши дизайнеры помогут разработать эксклюзивный дизайн с нуля, который будет не только
                    эстетичным, современным, но и эффективным.</p>

                <p><b>Время разработки дизайна интернет магазина:</b> от 3 недель.</p>

                <h2 className="service-subtitle sub-dop">При разработке дизайна электронного магазина учитываем такие
                    моменты </h2>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Информационная структура проекта</span>
                    </div>
                    <div className="result-list-descr">Наш специалист по разработке интерфейсов онлайн магазинов
                        определяет, как правильно организовать, упорядочить и структурировать всю информацию на
                        страницах так, чтобы пользователю было легко ее как воспринимать, так и ориентироваться в ней.
                        Сюда относят выбор размеров участка для каждого товара в витрине товаров. Удобное расположение
                        различных описаний, чтобы их не искать, а сразу находить на странице. Расположение цены и кнопки
                        «Купить». Расположение фотогалерей, разных меню навигации, полей форм, выпадающих подсказок и
                        т.д.
                    </div>
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/10-eshop-design_2.jpg" className="mn-image"
                                                       alt="Основные моменты при разработке дизайна электронного магазина"
                                                       title="Есть много важных моментов при создании премиум дизайна для интернет магазина"/>
                </div>
                <p>&nbsp;</p>

                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Планирование взаимодействия</span>
                    </div>
                    <div className="result-list-descr">Сюда относятся сервисы по взаимодействию пользователя с сайтом,
                        например корзина для товаров, сравнение, предпочтения. Механизмы, с помощью которых посетитель
                        достигает своих целей с минимальными усилиями – подсказки, формы регистрации, пояснения и т.п.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Разумная привлекательность</span>
                    </div>
                    <div className="result-list-descr">В это понятие мы вкладываем такую мысль, что дизайн интернет
                        магазина должен быть как стильным, современным, гармоничным, так и не мешать человеку
                        пользоваться сайтом. Таким образом, дружелюбность интерфейса сайта к пользователю должна быть и
                        всецело поддерживаться. Что мы с успехом и делаем в своей работе.
                    </div>
                </div>
                <div className="mn-panel mn-blue mn-card-4 mn-center mn-padding-16 inner-panel-1">
                    <b>(099)370-35-37</b><br/> Звоните. Ответим на все ваши вопросы.</div>

                <h2 className="service-subtitle sub-dop">Этапы разработки дизайна</h2>
                <p>Если вам нужно увеличить результативность вашего интернет ресурса, мы предлагаем <b>заказать дизайн
                    интернет-магазина в Киеве (Украина)</b> по выгодной цене. Конечная стоимость зависит от объема работ
                    и их сложности. В любом случае, связавшись с нами по телефону (см. выше), вы получите исчерпывающую
                    консультацию и ответы на все интересующие вас вопросы.</p>
                <p><b>Этапы выполнения работ:</b></p>
                <ul>
                    <li>написание технического задания (ТЗ)</li>
                    <li>создание дизайн проекта</li>
                    <li>согласование его с заказчиком</li>
                    <li>этап внесение правок, если таковые имеются</li>
                    <li>верстка макета</li>
                    <li>интеграция с проектом</li>
                </ul>
                <p>Результатом будет индивидуальное решение, которое соответствует пожеланиям заказчика и целям интернет
                    магазина.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/10-eshop-design_3.jpg" className="mn-image"
                                                alt="Разработка дизайна интернет магазина"
                                                title="Важно создание креативного дизайна для интернет магазина"/></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Можно заказать уникальный дизайн магазина</h2>
                <p><b>Эксклюзивный дизайн интернет магазина</b> гарантированно повысит запоминаемость онлайн ресурса,
                    стимулирует массовое привлечение целевой аудитории, придает элемент солидности и надежности.
                    Разработка внешнего вида и структуры производится нами в соответствии с основными целями электронной
                    коммерции. А именно:</p>
                <ul>
                    <li>быстрая загрузка витрины и товарных страниц</li>
                    <li>удобное редактирование содержания корзины</li>
                    <li>легкое и комфортное восприятие всей текстовой информации (названия, описания и характеристик
                        товара)
                    </li>
                    <li>цена на видном месте, кнопка &quot;Купить&quot;/&quot;В корзину&quot; также</li>
                    <li>вся сервисная информация подается на самых видных местах</li>
                    <li>ненавязчиво информировать пользователя об акциях, специальных предложениях, распродажах, и
                        т.п.
                    </li>
                    <li>прочие моменты…</li>
                </ul>

                <p>Важно понимать, что человек за 1-2 минуты должен сориентироваться в магазине, найти нужный товар и
                    понять – какая на него цена, какие условия доставки, какие типы оплаты и гарантии. Если покупателя
                    что-то будет не устраивать или раздражать – он покинет ресурс и станет покупателем для вашего
                    конкурента.</p>
                <p>Поэтому, доверьте работу по созданию дизайна вашего интернет-магазина только профессионалам. Мы учтем
                    все ваши пожелания, вникнем в нюансы вашего бизнеса и создадим дизайн, за который вам не нужно будет
                    волноваться.</p>
                <p className="inner-panel-2"><b>Наши телефоны: (099)370-35-37, (044)383-20-18.</b><br/>Звоните. Ответим
                    на все вопросы.</p>
            </div>
        </div>
    )
}

export default Service10;