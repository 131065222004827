import React from 'react';
import TopMenuBarForMobileLogo from "./TopMenuBarForMobileLogo";
import TopMenuBarForMobileIcons from "./TopMenuBarForMobileIcons";
import TopMenuBarForMobileOverlayMyNav from "./TopMenuBarForMobileOverlayMyNav";
import TopMenuBarForMobileOverlayMyNav2 from "./TopMenuBarForMobileOverlayMyNav2";
import TopMenuBarForMobileModal from "./TopMenuBarForMobileModal";

const TopMenuBarForMobile = (props) => {

    return (
        <div className="mn-container mn-hide-large inner-top-mobile">
            <div className="mn-container">
                <TopMenuBarForMobileLogo topMobileMenuInfo={props.ruTopMobileMenuDataMix.topMobileMenuInfo} />
                <TopMenuBarForMobileIcons topMobileMenuInfo={props.ruTopMobileMenuDataMix.topMobileMenuInfo} />
                <div className="mn-clear"/>
            </div>

            <TopMenuBarForMobileOverlayMyNav
                topMobileMenuInfo={props.ruTopMobileMenuDataMix.topMobileMenuInfo}
                languageLinks={props.languageLinks} />
                {/*uaLink={props.topMenuBarForMobileInfo.uaLink} />*/}
            <TopMenuBarForMobileOverlayMyNav2
                topMobileMenuInfo={props.ruTopMobileMenuDataMix.topMobileMenuInfo} />
            <TopMenuBarForMobileModal
                ruServicesListInfo={props.ruServicesListInfo}
                path={props.path} />
        </div>
    );
}

/*let mapStateToProps = (state) => {
    return {
        topMenuBarForMobile: state.landingTopMenuBarForMobile,
        ruTopMobileMenuDataMix: state.ruTopMobileMenuData,
        ruServicesListDataMix: state.ruServicesListData
    }
}*/

export default TopMenuBarForMobile;
/*export default connect(mapStateToProps)(withRouter(TopMenuBarForMobile));*/
