import React from 'react';
import {connect} from "react-redux";
import TopMenuBarForMobileLogo from "./TopMenuBarForMobileLogo";
import TopMenuBarForMobileIcons from "./TopMenuBarForMobileIcons";
import TopMenuBarForMobileOverlayMyNav from "./TopMenuBarForMobileOverlayMyNav";
import TopMenuBarForMobileOverlayMyNav2 from "./TopMenuBarForMobileOverlayMyNav2";
import TopMenuBarForMobileModal from "./TopMenuBarForMobileModal";
import {withRouter} from "react-router-dom";

class TopMenuBarForMobileContainer extends React.Component {
    render() {
        return (
            <div className="mn-container mn-hide-large inner-top-mobile">
                <div className="mn-container">
                    <TopMenuBarForMobileLogo {...this.props.ruTopMobileMenuDataMix.topMobileMenuInfo} />
                    <TopMenuBarForMobileIcons {...this.props.ruTopMobileMenuDataMix.topMobileMenuInfo} />
                    <div className="mn-clear"/>
                </div>

                <TopMenuBarForMobileOverlayMyNav {...this.props.ruTopMobileMenuDataMix.topMobileMenuInfo}
                    uaLink={this.props.topMenuBarForMobile.topMenuBarForMobileInfo.uaLink} />
                <TopMenuBarForMobileOverlayMyNav2 {...this.props.ruTopMobileMenuDataMix.topMobileMenuInfo} />
                <TopMenuBarForMobileModal {...this.props.ruServicesListDataMix.ruServicesListInfo}
                    path={this.props.match.path}/>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        topMenuBarForMobile: state.landingTopMenuBarForMobile,
        ruTopMobileMenuDataMix: state.ruTopMobileMenuData,
        ruServicesListDataMix: state.ruServicesListData
    }
}

export default connect(mapStateToProps)(withRouter(TopMenuBarForMobileContainer));