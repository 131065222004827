import React from 'react';

const SeoTextsBlock = (props) => {

    return (
        <div className="dop-1 main-txt">
            <div><h3>{props[0].textTitle}</h3></div>
            <div>{props[0].textBody}</div>
            <div className="mn-container mn-padding-16"/>
            <div><h3>{props[1].textTitle}</h3></div>
            <div>{props[1].textBody}</div>
        </div>
    );
}

export default SeoTextsBlock;