//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    sloganInfo: {
        firstRow: 'Создание сайтов для бизнес задач',
        secondRow: 'Помогаем реализовывать идеи'
    }
}

const landingSloganReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingSloganReducer;