//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    innerPageTopPhonesInfo: {
        ruAddress: 'г. Киев',
        uaAddress: 'м. Київ',
        phoneNumbers: '+38 (044)383-20-18, (099)370-35-37',
        language: ['UA', 'RU']
    },
    innerPageTopLogoAndMenuInfo: {
        logoUrl: '/assets/pics/logo_top.png',
        menuLinks: [
            {id: 1, link: '/', linkName: 'Создание сайтов'},
            {id: 2, link: '/company', linkName: 'О нас'},
            {id: 3, link: '/services', linkName: 'Услуги'},
            {id: 4, link: '/portfolio', linkName: 'Портфолио'},
            {id: 5, link: '/clients', linkName: 'Клиенты'},
            {id: 6, link: '/contact', linkName: 'Контакты'}
        ]
    }
}

const innerPageTopPhonesLogoMenuReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default innerPageTopPhonesLogoMenuReducer;