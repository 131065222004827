import React from 'react';
import {connect} from "react-redux";
import CopyRights from "./CopyRights";


class CopyRightsContainer extends React.Component {
    render() {
        return (
            <div>
                <CopyRights {...this.props.landingCopyRights.copyRights} />
            </div>
        );
    }
}


let mapStateToProps = (state) => {
    return {
        landingCopyRights: state.landingCopyRightsR
    }
}

export default connect(mapStateToProps)(CopyRightsContainer);