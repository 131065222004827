import React from 'react';
import MainBrcr from "./MainBrcr";

const Main = (props) => {

    let clients = props.clientsList.map(item => {
        return (
            <div className="inner-cl-logo">
                <div className="cl-logo-outer">
                    <div className="cl-logo-inner"><img
                        src={`/assets/pics/clients/list/${item.photo}`} className="mn-image"
                        alt={item.alt}
                        title={item.title}/></div>
                </div>
            </div>
        )
    })

    return (
        <div>
            <div className="inner-gap"/>
            <div className="mn-container">
                <MainBrcr brcr={props.brcr} />
            </div>
            <div className="mn-container mn-padding-16">
                <div className="inner-info"><h1 className="mn-center">{props.pageTitle}</h1></div>

                <div className="mn-center inner_site_dev_text">
                    {clients}
                </div>
            </div>
        </div>
    )
}

export default Main;