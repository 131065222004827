import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import RuInnerPageHeader from "../Common/Inner/RuInnerPageHeader";
import TopMenuBarForMobile from "../Common/Inner/TopMenuBarForMobile";
import Main from "./Main";
import Footer from "../Common/Footer/Footer";
import CopyRightsContainer from "../Common/CopyRights/CopyRightsContainer";


class Service extends React.Component {

    render() {
        let serviceName = this.props.match.params.name;
        let project = this.props.services.mainInfo.serviceList.find(project => project.toRuPage === serviceName);
        /*debugger*/
        if (project !== undefined) {
            return (
                <div>
                    <RuInnerPageHeader {...this.props}
                                       languageLinks={{
                                           toUaPage: `/ua/service/${project.toUaPage}`,
                                           toRuPage: `/service/${serviceName}`
                                       }}
                                       topPhonesLanguagesLogoMenu={this.props.topPhonesLanguagesLogoMenu}/>
                    <TopMenuBarForMobile {...this.props}
                                         path={this.props.match.path}
                                         languageLinks={{
                                             toUaPage: `/ua/service/${project.toUaPage}`,
                                             toRuPage: `/service/${serviceName}`
                                         }}
                                         topMenuBarForMobile={this.props.topMenuBarForMobile}
                                         ruTopMobileMenuDataMix={this.props.ruTopMobileMenuDataMix}
                                         ruServicesListInfo={this.props.ruServicesListDataMix.ruServicesListInfo}/>
                    {/*<MainContainer {...this.props.services.mainInfo} />*/}
                    <Main {...this.props}
                          project={project}
                          brcr={this.props.services.mainInfo.brcr}
                              ruServicesListInfo={this.props.ruServicesListDataMix.ruServicesListInfo}
                              path={this.props.match.path}/>
                    <Footer/>
                    <CopyRightsContainer/>
                </div>
            );
        } else {
            return (
                <div>
                    404 - Page not found!
                </div>
            )
        }
    }
}

let mapStateToProps = (state) => {
    return {
        services: state.service,
        topPhonesLanguagesLogoMenu: state.innerPageTopPhonesLogoMenu,

        topMenuBarForMobile: state.landingTopMenuBarForMobile,
        ruTopMobileMenuDataMix: state.ruTopMobileMenuData,
        ruServicesListDataMix: state.ruServicesListData
    }
}

export default connect(mapStateToProps)(withRouter(Service));