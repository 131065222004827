//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    technologyStackInfo: {
        title: 'Стек технологий, которые мы используем',
        stack: [
            {id: 1, imgUrl: '/assets/img/carusel/main_stack_1.png', alt: 'Github', title: 'Github'},
            {id: 2, imgUrl: '/assets/img/carusel/main_stack_2.png', alt: 'CSS3', title: 'CSS3'},
            {id: 3, imgUrl: '/assets/img/carusel/main_stack_3.png', alt: 'JavaScript', title: 'JavaScript'},
            {id: 4, imgUrl: '/assets/img/carusel/main_stack_4.png', alt: 'PHP7+', title: 'PHP7+'},
            {id: 5, imgUrl: '/assets/img/carusel/main_stack_5.png', alt: 'Less', title: 'Less'},
            {id: 6, imgUrl: '/assets/img/carusel/main_stack_6.png', alt: 'Webpack', title: 'Webpack'},
            {id: 7, imgUrl: '/assets/img/carusel/main_stack_7.png', alt: 'Gulp', title: 'Gulp'},
            {id: 8, imgUrl: '/assets/img/carusel/main_stack_8.png', alt: 'AngularJS', title: 'AngularJS'},
            {id: 9, imgUrl: '/assets/img/carusel/main_stack_9.png', alt: 'HTML5', title: 'HTML5'},
            {id: 10, imgUrl: '/assets/img/carusel/main_stack_10.png', alt: 'MySQL5+', title: 'MySQL5+'},
            {id: 11, imgUrl: '/assets/img/carusel/main_stack_11.png', alt: 'React', title: 'React'},
            {id: 12, imgUrl: '/assets/img/carusel/main_stack_12.png', alt: 'Sass', title: 'Sass'}
        ]
    }
}

const landingTechnologyStackReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingTechnologyStackReducer;