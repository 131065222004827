import React from 'react';
import {Link} from "react-router-dom";
import MainServicesListDop from "./MainServicesListDop";

const MainServicesList = (props) => {

    let onServiceClick = (e,url) => {
        document.location.href = url;
    }

    let servicesList = props.services.map((item, index) => {

        let dop = (index === 3 || index === 7) && <MainServicesListDop/>;
        return (
            <>
                <div className="mn-container mn-col l3 m6 mn-center">
                    <div className="mn-padding-8 mn-white main-4 wow slideInUp" data-wow-duration="2s"
                         onClick={(e) => onServiceClick(e,item.serviceUrl)}>
                        <div className="mn-padding-32">
                            <img src={item.serviceIconUrl} className="mn-image"
                                 alt={item.serviceIconAlt} title={item.serviceIconTitle}/>
                        </div>
                        <div className="main-4-1"><Link to={item.serviceUrl}>{item.serviceTitle}</Link></div>
                        <div className="main-4-2">{item.serviceDescription}</div>
                    </div>
                </div>
                {dop}
            </>
        )
    });

    return (
        <div className="left-right-flex-pad mn-padding-48">
            <div className="mn-container mn-center main-4-0">Наши самые популярные услуги</div>
            {servicesList}
            <div className="mn-clear"/>
        </div>
    );
}

export default MainServicesList;