import React from 'react';
import {NavLink} from "react-router-dom";

const RuInnerPageTopPhones = (props) => {

    return (
        <div className="inner-top-line-1">
            <div className="left-right-flex-pad">
                <div className="mn-container">
                    <div className="mn-container mn-left"><span className="main-top-line-1pr">{props.ruAddress}</span>
                        {props.phoneNumbers}
                    </div>
                    <div className="mn-container mn-right">
                        <NavLink to={props.languageLinks.toUaPage} activeClassName="selected-lang">{props.language[0]}</NavLink> &nbsp;&nbsp;&nbsp;
                        <NavLink to={props.languageLinks.toRuPage} activeClassName="selected-lang">{props.language[1]}</NavLink>
                    </div>
                    <div className="mn-clear"/>
                </div>
            </div>
        </div>
    )
}

export default RuInnerPageTopPhones;