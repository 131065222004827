import React from 'react';

const Service3 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Создание сайта визитки</b> – это разработка небольшого полноценного сайта состоящего, как правило,
                    из 10–20 страниц. </p>

                <p>Такой сайт может быть достаточно информативным, не смотря на простую одноуровневую структуру, без
                    вложенных разделов. Для него мы обязательно создаем индивидуальный дизайн, не шаблон. Сайт такого
                    плана вполне подойдет как для тех, кто только начинает бизнес, так и для тех, у кого просто
                    небольшой объем товаров или услуг. Или же, если не нужно отображать на основном сайте какой-то вид
                    деятельности фирмы.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/4-vizitka_1.jpg" className="mn-image"
                                                       alt="Разработка сайта визитки"
                                                       title="Создание сайта визитки под ключ от студии D.Logic"/></div>
                <p>&nbsp;</p>

                <p>Обычно, на сайте визитка размещаются общие сведения о компании, прайс-лист товаров и услуг,
                    контактная информация с формой обратной связи и картой проезда на основе сервисов Google Map. Также,
                    может присутствовать фото галерея и новостная лента.</p>

                <p>Сайт визитка обязательно снабжается системой управления контентом или CMS (Content Management System)
                    для того, чтобы менеджеры компании заказчика могли самостоятельно менять на нем всю текстовую
                    информацию, а также фото- и видеоматериалы.</p>
                <p>&nbsp;</p>

                <h2 className="service-subtitle">Как мы подходим к разработке визитки.</h2>
                <p>Разработка визитка сайта начинается с нашего знакомства с бизнесом заказчика, анализа его бизнес
                    целей, выяснения всех вопросов стоящих перед проектом. Важно понять все до мельчайших деталей, чтобы
                    правильно отобразить всю информацию в создаваемом проекте.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/4-vizitka_2.jpg" className="mn-image"
                                                       alt="Создание сайта визитки"
                                                       title="Наш подход к разработке сайта визитки"/></div>
                <p>&nbsp;</p>

                <p>Следующий шаг – составление технического задания, согласно которому будет проходить весь процесс
                    разработки сайта визитки. После его утверждения обеими сторонами начинается собственно сам этап
                    разработки.</p>

                <p><b>Время разработки сайта визитки:</b> 6 - 8 недель.</p>
            </div>

            <div className="service-title-2">Что получает заказчик в результате?</div>
            <h2 className="service-subtitle">1. Основные моменты</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Уникальный дизайн</span>
                </div>
                <div className="result-list-descr">Каждый созданный в D.Logic визитка сайт получает Авторский дизайн от
                    нашего творческого коллектива в соответствии с пожеланиями заказчика. Для каждого проекта мы готовим
                    до 2 (двух) вариантов дизайна для главной и внутренней страниц и предоставляем их клиенту для
                    выбора.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Адаптивность и кроссбраузерность</span>
                </div>
                <div className="result-list-descr">Все наши сайты имеют адаптивный дизайн, т.е. корректно отображаются
                    на экранах любых размеров – от маленьких на смартфонах и планшетах, до больших у ноутбуков и
                    мониторов настольных систем. Более того, код сайта позволяет ему корректно отображаться во всех
                    популярных браузерах настольных и мобильных систем - Chrome, Firefox, Opera, Internet Explorer,
                    Safari и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Система управления контентом (CMS)</span>
                </div>
                <div className="result-list-descr">Каждый сайт визитка, созданный нами, снабжается удобной Системой
                    управления, чтобы пользователь мог легко самостоятельно обновлять любую информацию: цены, тексты,
                    фото- и видеоматериалы.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Начальное наполнение сайта</span>
                </div>
                <div className="result-list-descr">Осуществляем первоначальное наполнение сайта всей необходимой
                    информацией, объем которой согласовывается с заказчиком.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Размещение проекта на веб-сервере</span>
                </div>
                <div className="result-list-descr">После выполнения всех работ, наши специалисты размещают все материалы
                    сайта на веб-сервере и настраивают его работу.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Обучение работе с админкой сайта</span>
                </div>
                <div className="result-list-descr">После размещения проекта на веб-сервере, мы проводим обучение
                    заказчика работе с сайтом. Процесс обучение обычно длится 1-2 часа и хорошо воспринимается
                    неподготовленным пользователем.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/4-vizitka_3.jpg" className="mn-image"
                                                       alt="Разнообразие функционала сайта визитки"
                                                       title="Созданный сайт визитка может содержать в себе разнообразный функционал"/>
                </div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">2. Функционал сайта визитки</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Новостная лента</span>
                </div>
                <div className="result-list-descr">Данный функционал позволяет отображать новости компании на страницах
                    сайта. Краткое содержание новостей может отображаться на других страница ресурса.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Статистика сайта</span>
                </div>
                <div className="result-list-descr">По желанию заказчика на сайт устанавливаем систему сбора статистики
                    посещений Google Analytics и рассказываем об основных моментах работы с ней.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Форма обратной связи</span>
                </div>
                <div className="result-list-descr">Позволяет пользователям отправлять сообщения или запросы по поводу
                    той или иной интересующей их информации.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Фотогалерея</span>
                </div>
                <div className="result-list-descr">Позволяет на сайте отображать пользователям набор фотографий в
                    наилучшем виде.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Карта проезда</span>
                </div>
                <div className="result-list-descr">На основе сервиса Google Maps, на сайте можно будет разместить
                    интерактивную масштабируемую карту подъезда к офису.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/4-vizitka_4.jpg" className="mn-image"
                                                       alt="Карта проезда на сайте"
                                                       title="Пример интерактивной карты проезда на сайте визитка"/>
                </div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">3. Дополнительно</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Иконки переходов в группы соц. сетей</span>
                </div>
                <div className="result-list-descr">Есть возможность размещать на сайте иконки и виджеты групп социальных
                    сетей (Facebook, Twitter).
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Формирование Sitemap</span>
                </div>
                <div className="result-list-descr">Формирование XML-карты сайта для создания условий более полной
                    индексации страниц сайта поисковыми системами Google, Yandex и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Понятный URL (ЧПУ)</span>
                </div>
                <div className="result-list-descr">Сайт визитка имеет короткие и понятные внутренние ссылки. Это
                    позволяет людям и поисковым машинам распознавать содержимое страниц по URL.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита админки сайта</span>
                </div>
                <div className="result-list-descr">Доступ к админке сайта не имеет прямых ссылок со страниц сайта. Что
                    не позволяет анализаторам кода автоматически обнаруживать ее URL. Также, используются и другие
                    приемы препятствующие определению типа CMS сайта. Более того, сама система аутентификации защищена
                    от систем скоростного подбора паролей.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита и безопасность</span>
                </div>
                <div className="result-list-descr">Все наши сайты используют защиту базы данных от инъекций, защиту от
                    удаленного запуска, защиту от запуска скриптов и другие виды защит.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Кросспостинг</span>
                </div>
                <div className="result-list-descr">Есть возможность публиковать записи из новостной ленты сайта в
                    аккаунтах Facebook, Twitter и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Оптимизация сайта</span>
                </div>
                <div className="result-list-descr">Внесение на сайт визитку мета-информации, подбор ключевых слов,
                    оптимизация по ключевым словам.
                </div>

            </div>
        </div>
    )
}

export default Service3;