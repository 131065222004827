import React from 'react';
import {connect} from "react-redux";
import TextBeforeOurWorks from "./TextBeforeOurWorks";

class TextBeforeOurWorksContainer extends React.Component {
    render() {
        return (
            <div className="mn-padding-64 main-6">
                <div className="left-right-flex-pad">
                    <TextBeforeOurWorks {...this.props.textBeforeOurWorks.textBeforeOurWorksInfo} />
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        textBeforeOurWorks: state.landingTextBeforeOurWorks
    }
}

export default connect(mapStateToProps)(TextBeforeOurWorksContainer);