import React from 'react';
import {connect} from "react-redux";
import TextUnderTopPhoto from "./TextUnderTopPhoto";

class TextUnderTopPhotoContainer extends React.Component {
    render() {
        return (
            <div className="left-right-flex-pad mn-padding-64">
                <TextUnderTopPhoto {...this.props.textUnderTopPhoto.textUnderTopPhotoInfo} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        textUnderTopPhoto: state.landingTextUnderTopPhoto
    }
}

export default connect(mapStateToProps)(TextUnderTopPhotoContainer);