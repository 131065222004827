import React from 'react';
import {NavLink} from "react-router-dom";

const RuInnerPageTopLogoMenu = (props) => {

    let menuLinks = props.menuLinks.map(item => {
        return <li><NavLink to={item.link}>{item.linkName}</NavLink></li>
    })

    return (
        <div className="main-top-line-2">
            <div className="left-right-flex-pad">
                <div className="mn-container">
                    <div className="mn-container mn-left"><img src={props.logoUrl} alt=""
                                                               className="mn-image main-top-line-2-img"/></div>
                    <div className="mn-container mn-right menu-top-inner">
                        <ul>
                            {menuLinks}
                        </ul>
                    </div>
                    <div className="mn-clear"/>
                </div>
            </div>
        </div>
    )
}

export default RuInnerPageTopLogoMenu;