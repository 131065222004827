import React from 'react';

const TextOnGreenBlock = (props) => {
    return (
        <div className="mn-container mn-center main-4-01">
            {props.firmName}
        </div>
    );
}

export default TextOnGreenBlock;