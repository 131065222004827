//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            brcrPageName: 'Обратная связь'
        },
        pageTitle: 'Наши контакты',
        pageText: [
            <div>
                <div className="mn-center inner-feedback-info">
                    <div>04212</div>
                    <div>Украина, г. Киев</div>
                    <div>пр. Оболонский, 26 (Оболонь)</div>
                    <div className="mn-padding-16"/>

                    <div><b>тел.: 38 (044) 383-2018, (099) 370-3537</b></div>
                    <div>mail@dlogic.com.ua</div>
                    <div>www.dlogic.com.ua</div>
                </div>
                <div className="mn-center mn-light-grey mn-padding-24">
                    <div><b style={{borderBottom: '1px solid black'}}>Режим работы</b></div>
                    <div className="mn-padding-16"/>

                    <div className="mn-left-align" style={{width: '200px', margin: 'auto'}}>
                        <div>Пн-Пт: с 10:00 до 18:00</div>
                        <div>Сб-Вс: выходной</div>
                    </div>
                </div>
                <div className="mn-center mn-padding-24 mn-text-purple inner-feedback-map-tit">
                    <b>Карта подъезда к офису</b></div>
                <div className="mn-card-4">
                    <div id="map" style={{width:'100%', height:'500px'}}/>
                </div>
            </div>
        ]
    }
}

const contactsReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default contactsReducer;