import React from 'react';

const Service7 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Создание корпоративного портала, приватного кабинета</b> – это следующий этап использования
                    компанией возможностей современных IT-технологий для своего развития.</p>
                <p>Когда компания в процессе своей деятельности столкнулась с необходимостью, например, вести обработку
                    и учет заказов, клиентов, иметь платформу для взаимодействия с клиентами для обмена конфиденциальной
                    и коммерческой информацией о новинках, ценах, персональных скидкам, условиях закупок и т.п. Для
                    таких целей мы предлагаем разработать удобный сервис "Персональный или личный кабинет". Удобство и
                    возможности таких сервисов трудно переоценить.</p>
                <p>По сути, это элемент из корпоративного портала. В персональном кабинете после процесса
                    аутентификации, пользователь сразу видит, например, все корпоративные новости, персональные задачи
                    на сегодня, списки новых заказов и статусы (в реальном времени) старых заказов, неоплаченные счета,
                    состояние документации по договорам и многое другое. Понятно, что весь функционал перечислить
                    невозможно. </p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/7-cabinet_1.jpg" className="mn-image" alt="Личный кабинет"
                                                title="Разработка личного кабинета на вашем сайте"/></div>
                <p>&nbsp;</p>

                <p>Корпоративный портал обязательно снабжается нами системой управления контентом или CMS (Content
                    Management System) для того, чтобы администраторы ресурса могли определять виды допуска
                    пользователей к различным разделам кабинета.</p>

                <p><b>Время разработки корпоративного портала:</b> от 10 недель.</p>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">Что заказчик получает в результате</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Лента новостей</span>
                </div>
                <div className="result-list-descr">Этот функционал позволяет отображать новости компании, персональные
                    новости для каждого клиента соответственно, прочие сообщения. Причем, каждый клиент компании видит
                    только свою информацию.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Акции, персональные скидки (например, для дилеров)</span>
                </div>
                <div className="result-list-descr">Предоставляется возможность отображать информацию по акциям,
                    персональным скидкам и другую коммерческую информацию для каждого клиента соответственно.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Документооборот, хранилище</span>
                </div>
                <div className="result-list-descr">Учитываются и отображаются все документы связанные с текущей
                    деятельностью заказчика и ее подрядчиков, клиентов. Также, сохранены все документы за всю историю
                    сотрудничества с компанией.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Списки задач</span>
                </div>
                <div className="result-list-descr">Отображаются списки задач, условия текущих поставок, прочее. По
                    каждому пункту можно проставлять дедлайны выполнения.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/7-cabinet_2.jpg" className="mn-image"
                                                       alt="Структура личного кабинета"
                                                       title="Разработка структуры и вида отображения информации в личном кабинете"/>
                </div>
                <p>&nbsp;</p>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Списки заказов</span>
                </div>
                <div className="result-list-descr">Этот функционал предосталяет всю историю сотрудничества: сделанные
                    заказы, выполненные работы, доставленные товары и т.д.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Статусы заказов</span>
                </div>
                <div className="result-list-descr">Отображаются статусы заказов в списке заказов: текущий,
                    обрабатывается, выполненный и т.д.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Статусы задач</span>
                </div>
                <div className="result-list-descr">Отображаются статусы задач в списке задач: новая, срочная, в работе,
                    выполненная и т.д.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Статусы документов</span>
                </div>
                <div className="result-list-descr">Отображаются статусы документов по заказам или работам: отсутствует,
                    выписан, отправлен, получен, проведен и прочее.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Печать документов</span>
                </div>
                <div className="result-list-descr">Предоставляется возможность распечатать документ на принтере.</div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Выборки и сводки по заказам</span>
                </div>
                <div className="result-list-descr">Предоставляется возможность осуществлять выборки, итоговые сводки по
                    заказам за выбранный период, статусам и другим критериям
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Финансовые сводки, отчеты</span>
                </div>
                <div className="result-list-descr">Предоставляется возможность осуществлять выборки, итоговые сводки по
                    доходам, затратам, неоплатам с привязкой к заказам за период, их статусам, прочее.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Учет материалов, склад</span>
                </div>
                <div className="result-list-descr">Этот функционал предоставляет вести полный учет складских запасов,
                    товаров, расход материальных ценностей, материалов для выполнения заказа и т.п.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/7-cabinet_3.jpg" className="mn-image"
                                                       alt="Много разной информации отображается в кабинете"
                                                       title="Разнообразие сводной и статистической информации будет предоставлено для пользователя в его личном кабинете"/>
                </div>
                <p>&nbsp;</p>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Логистика</span>
                </div>
                <div className="result-list-descr">Функционал позволяет отображать логистику по каждому заказу, текущее
                    состояние: подготовлен, отправлен, получен заказчиком, выполнен, исполнитель, ответственный и т.п.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Учет рабочего времени</span>
                </div>
                <div className="result-list-descr">Предоставляется возможность вести табель учета рабочего времени
                    персонала, подрядчиков: болен, на выезде, присутствует, отпуск, отсутствует, задействован и прочее
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Прочее...</span>
                </div>
                <div className="result-list-descr">Прочий функционал кабинета, который разрабатывается по отдельным
                    критериям интересующие заказчика.
                </div>
            </div>
        </div>
    )
}

export default Service7;