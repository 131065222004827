import React from 'react';
import RuInnerPageHeaderContainer from "../Common/RuInnerPageHeaderContainer";
import TopMenuBarForMobileContainer from "../Landing/TopMenuBarForMobile/TopMenuBarForMobileContainer";
import MainContainer from "../Services/MainContainer";
import Footer from "../Common/Footer/Footer";
import CopyRightsContainer from "../Common/CopyRights/CopyRightsContainer";

const Services = () => {
    return (
        <div>
            <RuInnerPageHeaderContainer languageLinks={{toUaPage: '/ua/services', toRuPage: '/services'}}/>
            <TopMenuBarForMobileContainer/>
            <MainContainer/>
            <Footer/>
            <CopyRightsContainer/>
        </div>
    );
}

export default Services;