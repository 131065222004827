import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import RuInnerPageHeader from "../Common/Inner/RuInnerPageHeader";
import TopMenuBarForMobile from "../Common/Inner/TopMenuBarForMobile";
import Main from "./Main";
import Footer from "../Common/Footer/Footer";
import CopyRightsContainer from "../Common/CopyRights/CopyRightsContainer";


class Logo extends React.Component {

    render() {
        let logoName = this.props.match.params.id;
        let project = this.props.logos.mainInfo.logoList.find(project => project.toRuPage === logoName);

        if (project !== undefined) {
            return (
                <div className="portfolio_1">
                    <RuInnerPageHeader {...this.props}
                                       languageLinks={{
                                           toUaPage: `/ua/logo/${project.toUaPage}`,
                                           toRuPage: `/logo/${logoName}`
                                       }}
                                       topPhonesLanguagesLogoMenu={this.props.topPhonesLanguagesLogoMenu}/>
                    <TopMenuBarForMobile {...this.props}
                                         path={this.props.match.path}
                                         languageLinks={{
                                             toUaPage: `/ua/logo/${project.toUaPage}`,
                                             toRuPage: `/logo/${logoName}`
                                         }}
                                         topMenuBarForMobile={this.props.topMenuBarForMobile}
                                         ruTopMobileMenuDataMix={this.props.ruTopMobileMenuDataMix}
                                         ruServicesListInfo={this.props.ruServicesListDataMix.ruServicesListInfo}/>
                    {/*<MainContainer {...this.props.logos.mainInfo} />*/}
                    <Main {...this.props}
                          project={project}
                          brcr={this.props.logos.mainInfo.brcr}/>
                    <Footer/>
                    <CopyRightsContainer/>
                </div>
            );
        } else {
            return (
                <div>
                    404 - Page not found!
                </div>
            )
        }
    }
}

let mapStateToProps = (state) => {
    return {
        logos: state.logo,
        topPhonesLanguagesLogoMenu: state.innerPageTopPhonesLogoMenu,

        topMenuBarForMobile: state.landingTopMenuBarForMobile,
        ruTopMobileMenuDataMix: state.ruTopMobileMenuData,
        ruServicesListDataMix: state.ruServicesListData
    }
}

export default connect(mapStateToProps)(withRouter(Logo));