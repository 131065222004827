import React from 'react';

const Service13 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <h2 className="service-subtitle sub-dop">Контекстная реклама в Google</h2>
                <p>Не секрет, что большинство пользователей компьютеров и смартфонов используют интернет для поиска и
                    выбора нужного для себя товара. Согласитесь, это быстро, удобно и комфортно, чем бегать по
                    магазинам. Это экономит время, нервы и деньги. Вот почему, все больше и больше пользователей отдают
                    предпочтение онлайн шопингу.</p>

                <p>Поэтому, если вам нужно очень быстро донести информацию о себе, своих товарах или услугах до своей
                    потенциальной аудитории, контекстная реклама в системе Google Ads – это именно тот инструмент, на
                    который нужно обратить внимание.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/16-ads_1.jpg" className="mn-image"
                                                alt="Контекстная реклама" title="Настройка рекламной компании в Google"/>
                </div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Суть контекстной рекламы</h2>
                <p>Благодаря настройкам, которыми вы управляете, можно донести свое рекламное сообщение до целевой
                    аудитории. Например, вы владелец кафетерия в Киеве и хотите, чтобы рекламу о вас видели только
                    пользователи из Киева. Нет ничего особо сложного. Настраиваете соответствующим образом систему
                    таргетирования и… вуаля. Ваши рекламные объявления видят все, кто находится в данный момент в
                    Киеве.</p>
                <p>Это с одной стороны. С другой стороны, система Google Реклама учитывает интересы и предпочтения
                    каждого пользователя. Такую информацию поисковик получает на основе собранных аналитических данных
                    по каждому пользователю, основываясь на посещенных им сайтах, просмотренных видео и т.п. И потом
                    показывает человеку именно те объявления, которые являются наиболее релевантными, по мнению системы
                    естественно, и могут его заинтересовать. </p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/16-ads_2.jpg" className="mn-image"
                                                alt="Смысл PPC стратегии" title="Суть PPC стратегии"/></div>
                <p>&nbsp;</p>

                <p>Таким образом, главной особенностью контекстной рекламы является то, что она показывается только
                    целевым пользователям. Именно в этом ее огромная популярность среди рекламодателей. С таким
                    подходом, рекламный бюджет любой компании будет расходоваться достаточно эффективно.</p>

                <h2 className="service-subtitle sub-dop">Как мы работаем с контекстной рекламой</h2>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Задачи рекламной кампании</span>
                    </div>
                    <div className="result-list-descr">Совместно с заказчиком определяем цели и задачи рекламной
                        кампании
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Планирование бюджета</span>
                    </div>
                    <div className="result-list-descr">Формируем список ключевых слов и фраз для оценки и планирования
                        бюджета рекламной компании.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Подготовка рекламной кампании</span>
                    </div>
                    <div className="result-list-descr">Подбор и согласования вариантов рекламных объявлений, их привязка
                        к группам ключевых слов. Согласование настроек для показа объявлений.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Запуск рекламной кампании</span>
                    </div>
                    <div className="result-list-descr">Осуществляем запуск и мониторинг рекламной компании.</div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Отчеты</span>
                    </div>
                    <div className="result-list-descr">Предоставляем отчеты о результатах контекстной рекламы и
                        рекомендации по ее дальнейшему улучшению.
                    </div>
                </div>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/16-ads_3.jpg" className="mn-image"
                                                alt="Планирование контекстной рекламы"
                                                title="Цели и планирование рекламной компании в Google"/></div>
                <p>&nbsp;</p>
            </div>
        </div>
    )
}

export default Service13;