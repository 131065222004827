import React from 'react';

const GoogleMap = () => {
    return (
        <div className="main-10">
            <div id="map" style={{width: '100%', height: '550px'}}/>
        </div>
    );
}

export default GoogleMap;