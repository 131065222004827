import React from 'react';
import {Link} from "react-router-dom";

const TimeLineButton = (props) => {
    return (
        <div className="main-9">
            <div className="left-right-flex-pad">
                <div className="mn-container mn-padding-32"/>
                <div className="mn-container mn-center">
                    <Link to={props.link} className="">{props.buttonText}</Link>
                </div>
                <div className="mn-container mn-padding-48"/>
            </div>
        </div>
    );
}

export default TimeLineButton;