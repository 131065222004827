import React from 'react';
import {connect} from "react-redux";
import MainServicesList from "./MainServicesList";

class MainServicesListContainer extends React.Component {
    render() {
        return (
            <div className="main-4-bg">
                <MainServicesList {...this.props.mainServicesList.mainServicesListInfo} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        mainServicesList: state.landingMainServicesList
    }
}

export default connect(mapStateToProps)(MainServicesListContainer);