//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    topMobileMenuInfo: {
        topLogoUrl: '/assets/pics/logo_top.png',
        topMobileLogoUrl: '/assets/pics/logo_top_mobile.png',
        topPhoneUrl: '/assets/pics/top_phone.png',
        topMenu2Url: '/assets/pics/top_menu2.png',
        topMenuUrl: '/assets/pics/top_menu.png',
        phones: [
            {id: 1, telLink: '+380443832018', tel: '(044) 383-2018'},
            {id: 2, telLink: '+380993703537', tel: '(099) 370-3537'}
        ],
        links: [
            {id: 1, url: '/', linkName: 'Создание сайтов'},
            {id: 2, url: '/company', linkName: 'О нас'},
            {id: 3, url: '/services', linkName: 'Услуги'},
            {id: 4, url: '/portfolio', linkName: 'Портфолио'},
            {id: 5, url: '/clients', linkName: 'Клиенты'},
            {id: 6, url: '/contact', linkName: 'Контакты'}
        ]
    }
}

const ruTopMobileMenuReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default ruTopMobileMenuReducer;