import React from 'react';
import MainBrcr from "./MainBrcr";
import {Link} from "react-router-dom";

const Main = (props) => {

    let someInfo = props.project.logoDescr2.map(item => {
        return <span className="mn-round-medium portfolio_top_2">{item}</span>
    })

    let photos = props.project.logoPhotos.map(ph => {
        return (
            <>
            <div><img src={`/assets/pics/logo/${ph}`} className="mn-image" alt={props.logoDescr1} title={props.logoDescr1}/></div>
            <div className="mn-padding-32"/>
            </>
        )
    })

    let logoDescr1s = () => {
        if(props.project.logoDescr1s) {
            return (
                props.project.logoDescr1s
            );
        }
        return '';
    }

    return (
        <div className="mn-white">
            <div className="left-right-flex-pad mn-padding-16">
                <div className="inner-gap"/>
                <div className="mn-container">
                    <MainBrcr brcr={props.brcr} pageName={props.project.logoDescr1} />
                </div>
                <div className="mn-container mn-padding-16">
                    <div className="mn-padding-24 mn-center">
                        <h2>{props.project.logoDescr1}</h2>
                        <h4>{logoDescr1s()}</h4>
                        {someInfo}
                    </div>
                </div>
            </div>
            <div className="portfolio_1">
                <div className="left-right-flex-pad mn-padding-16">
                    <div className="mn-container mn-center">
                        <div className="mn-padding-12">&nbsp;</div>
                        {photos}
                    </div>
                    <div className="mn-container mn-center portfolio_1s">
                        <Link to={`/zakazat-uslugu`} className="mn-btn mn-small mn-round-medium mn-white mn-border mn-border-blue">Поработаем?</Link>
                    </div>
                    <div className="mn-padding-16"/>
                </div>
            </div>
        </div>
    )
}

export default Main;