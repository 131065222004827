import React from 'react';

const Service5 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <h2 className="service-subtitle">Мобильный сайт</h2>
                <p>В настоящее время более 70% пользователей заходят в интернет с помощью мобильного устройства. Тут
                    надо сразу оговорить, что под термином «мобильное устройство» следует считать смартфоны, планшеты и
                    любые другие гаджеты с размером экрана менее чем 1000 пикселей. Продолжим, такие пользователи
                    смотрят видео, играют в игры, читаю новости, книги, совершают покупки в интернет магазинах.
                    Очевидно, что создание для такой аудитории специальной мобильной версии сайта, в котором
                    оптимизирован дизайн, интерфейс, функционал и контент приведет к росту лояльности такой аудитории к
                    сайту.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/6-mobile_1.jpg" className="mn-image"
                                                       alt="Мобильная версия сайта"
                                                       title="Создание мобильной версии сайта - D.Logic"/></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle">Варианты мобильной версии сайта</h2>

                <p>Тут есть два подхода и каждый сам для себя решает, какой выбрать.</p>
                <ul>
                    <li><b>Сайт с адаптивным дизайном.</b> Такой мобильный ресурс, с точки зрения кода, полностью
                        идентичен коду сайта для настольных компьютеров. Идентично все – дизайн, структура и наполнение.
                        По сути, это один и тот же сайт, внешний вид которого будет просто подстраиваться
                        (адаптироваться) под соответствующий размер экрана. Надо отметить, что поддерживать такой сайт
                        намного проще, чем несколько версий сайта, каждая и которых создана специально под каждый размер
                        экрана соответственно (см. вариант 2 ниже).
                    </li>
                    <li><b>Отдельный мобильный сайт.</b> Для такого сайта создается уникальный более упрощенный дизайн.
                        Код, как правило, также не идентичен коду сайта для настольных систем. Версия мобильного сайта
                        часто имеет другую структуру навигации. Такие сайты можно просматривать даже на экранах с
                        шириной менее 250 пикселей.
                    </li>
                </ul>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/6-mobile_2.jpg" className="mn-image"
                                                       alt="Варианты мобильной версии"
                                                       title="Варианты создания мобильной версии сайта"/></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle">Мобильный сайт и его преимущества</h2>
                <ul>
                    <li><b>Высокая лояльность.</b> Три четверти мобильных пользователей, начав просматривать сайт,
                        покинут его сразу же, в случае если интернет ресурс не адаптирован под мобильные устройства.
                    </li>
                    <li><b>Более высокая конверсия.</b> Так как мобильный пользователь не покинет ресурс, если он
                        адаптирован под мобильные девайсы и пользователь нашел на таком сайте все, что искал. Более
                        того, исследования показывают, что пользователи мобильного устройства более платежеспособны.
                    </li>
                    <li><b>Более высокая позиция в поисковой выдаче.</b> Google давно уже не скрывает, что сайты,
                        которые не адаптированные под мобильные устройства, имеют гораздо худший рейтинг в поисковой
                        выдачи, нежели те сайты, которые наоборот, адаптированы под мобильные девайсы. Как говорится,
                        тут добавить нечего.
                    </li>
                </ul>

                <p>Цена создания мобильной версии сайта в Киеве рассчитывается индивидуально под каждый проект и зависит
                    от объема работ. Заказать можно прямо сейчас по телефону (044)383-2018. Готовы ответить на все ваши
                    вопросы.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/6-mobile_3.jpg" className="mn-image"
                                                       alt="Достоинства мобильных сайтов"
                                                       title="Очевидные преимущества мобильного сайта"/></div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">Что включает в себя стоимость разработки мобильной версии сайта в
                Украине</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Разработка концепции интернет ресурса</span>
                </div>
                <div className="result-list-descr">На этом этапе продумывается будущий проект, уточняется аудитория
                    сайта, ставятся цели и задачи.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Создание прототипов</span>
                </div>
                <div className="result-list-descr">Разрабатывается дизайн проект страниц и интерфейсов мобильного
                    сайта.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Согласование с заказчиком прототипов, внесение правок</span>
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Сборка мобильной версии</span>
                </div>
                <div className="result-list-descr">При разработке мобильного сайта осуществляем полную оптимизацию сайта
                    под мобильные устройства.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Подключение Системы управления контентом (CMS)</span>
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Размещение проекта на веб-сервере</span>
                </div>
                <div className="result-list-descr">После выполнения всех работ по созданию сайта, наши специалисты
                    размещают все материалы сайта на веб-сервере и настраивают его работу.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Обучение работе с админкой мобильного сайта</span>
                </div>
                <div className="result-list-descr">После размещения проекта на веб-сервере, мы проводим обучение
                    заказчика работе с сайтом. Процесс обучение обычно длится 1-2 часа и хорошо воспринимается
                    неподготовленным пользователем.
                </div>
            </div>
        </div>
    )
}

export default Service5;