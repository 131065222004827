import React from 'react';
import MainBrcr from "./MainBrcr";

const Main = (props) => {

    return (
        <div>
            <div className="inner-gap"/>
            <div className="mn-container">
                <MainBrcr brcr={props.brcr} />
            </div>
            <div className="mn-container mn-padding-16">
                <div className="inner-info"><h1 className="mn-center">{props.pageTitle}</h1></div>

                {props.pageText}
                <div className="mn-padding-32">&nbsp;</div>
            </div>
        </div>
    )
}

export default Main;