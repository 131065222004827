//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    ourHistoryInfo: {
        bgPhotoUrl: '/assets/pics/main_3_photo.jpg',
        title: 'Наша история',
        ourMarks: [
            {id: 1, numberMark: 19, markDescription: 'лет опыта в разработке'},
            {id: 2, numberMark: 300, markDescription: 'и более реализованных проектов'},
            {id: 3, numberMark: 260, markDescription: 'довольных клиентов'}
        ]
    }
}

const landingOurHistoryReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingOurHistoryReducer;