//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

import React from "react";

let initialState = {
    textUnderTopPhotoInfo: {
        photoUrl: '/assets/pics/webdesign_1.jpg',
        photoAlt: 'Веб дизайн сайтов',
        photoTitle: 'Создание сайтов - студия D.Logic',
        title: 'Создаем сайты для ваших проектов',
        textBody: [
            'Желание современного человека всю нужную информацию получать быстро и в удобном для него виде невозможно игнорировать. Поэтому, для фирм достигнуть успеха сейчас невозможно без такого очень мощного инструмента как продвигающий сайт, продающий интернет-магазин или электронный каталог товаров с удобной навигацией.',
            <p>Если вы задумали интересный проект и хотите сделать для него сайт или веб-сервис, мы поможем вам с этим. Главное понимать для чего он будет нужен и какие задачи на него будут возложены. А тут уже вариантов много. Это может быть продажа товара или услуги, привлечение потенциальных клиентов, повышение лояльности уже существующих клиентов, работа с контрагентами и подрядчиками, взаимодействие с клиентами, реализация маркетинговых стратегий и инструментов, автоматизация бизнес процессов компании, прочее. Сейчас сайты и онлайн сервисы могут выполнять сотни задач.</p>
        ]
    }
}

const landingTextUnderTopPhotoReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingTextUnderTopPhotoReducer;