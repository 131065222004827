import {combineReducers, createStore} from "redux";
import ruFooterReducer from "./Common/ruFooterReducer";
import ruCopyRightsReducer from "./Common/ruCopyRightsReducer";
import landingTimeLineButtonReducer from "./Landing/landingTimeLineButtonReducer";
import landingTimeLineReducer from "./Landing/landingTimeLineReducer";
import landingSeoTextsBlockReducer from "./Landing/landingSeoTextsBlockReducer";
import landingTechnologyStackReducer from "./Landing/landingTechnologyStackReducer";
import landingTopPhonesAndLanguagesReducer from "./Landing/landingTopPhonesAndLanguagesReducer";
import landingLogoAndTopMenuBarReducer from "./Landing/landingLogoAndTopMenuBarReducer";
import landingSloganReducer from "./Landing/landingSloganReducer";
import landingTextUnderTopPhotoReducer from "./Landing/landingTextUnderTopPhotoReducer";
import landingSecondParallaxReducer from "./Landing/landingSecondParallaxReducer";
import landingTextOnGreenBlockReducer from "./Landing/landingTextOnGreenBlockReducer";
import landingMainServicesListReducer from "./Landing/landingMainServicesListReducer";
import landingOurHistoryReducer from "./Landing/landingOurHistoryReducer";
import landingTextBeforeOurWorksReducer from "./Landing/landingTextBeforeOurWorksReducer";
import landingPerformedProjectsReducer from "./Landing/landingPerformedProjectsReducer";
import landingTopMenuBarForMobileReducer from "./Landing/landingTopMenuBarForMobileReducer";

import ruTopMobileMenuReducer from "./Common/ruTopMobileMenuReducer";
import ruServicesListReducer from "./Common/ruServicesListReducer";
import innerPageTopPhonesLogoMenuReducer from "./Common/innerPageTopPhonesLogoMenuReducer";

import companyReducer from "./Company/companyReducer";
import servicesReducer from "./Services/servicesReducer";
import portfolioReducer from "./Portfolio/portfolioReducer";
import logotypesReducer from "./Logotypes/logotypesReducer";
import clientsReducer from "./Clients/clientsReducer";
import contactsReducer from "./Contacts/сontactsReducer";
import siteReducer from "./Site/siteReducer";
import logoReducer from "./Logo/logoReducer";
import serviceReducer from "./Service/serviceReducer";


let reducers = combineReducers({
    landingFooter: ruFooterReducer,
    landingCopyRightsR: ruCopyRightsReducer,
    landingTimeLineButtonR: landingTimeLineButtonReducer,
    landingTimeLine: landingTimeLineReducer,
    landingSeoTextsBlock: landingSeoTextsBlockReducer,
    landingTechnologyStack: landingTechnologyStackReducer,
    landingTopPhonesAndLanguages: landingTopPhonesAndLanguagesReducer,
    landingLogoAndTopMenuBar: landingLogoAndTopMenuBarReducer,
    landingLogoAndTopMenuBarWhenScrolling: landingLogoAndTopMenuBarReducer,
    landingSlogan: landingSloganReducer,
    landingTextUnderTopPhoto: landingTextUnderTopPhotoReducer,
    landingSecondParallax: landingSecondParallaxReducer,
    landingTextOnGreenBlock: landingTextOnGreenBlockReducer,
    landingMainServicesList: landingMainServicesListReducer,
    landingOurHistory: landingOurHistoryReducer,
    landingTextBeforeOurWorks: landingTextBeforeOurWorksReducer,
    landingPerformedProjects: landingPerformedProjectsReducer,
    landingTopMenuBarForMobile: landingTopMenuBarForMobileReducer,

    company: companyReducer,
    services: servicesReducer,
    portfolio: portfolioReducer,
    logotypes: logotypesReducer,
    clients: clientsReducer,
    contacts: contactsReducer,
    site: siteReducer,
    logo: logoReducer,
    service: serviceReducer,

    ruTopMobileMenuData: ruTopMobileMenuReducer,
    ruServicesListData: ruServicesListReducer,
    innerPageTopPhonesLogoMenu: innerPageTopPhonesLogoMenuReducer

});

let store = createStore(reducers);
window.store = store;

export default store;