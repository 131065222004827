import React from 'react';
import {connect} from "react-redux";
import TextOnGreenBlock from "./TextOnGreenBlock";

class TextOnGreenBlockContainer extends React.Component {
    render() {
        return (
            <div className="mn-green">
                <div className="left-right-flex-pad main-4-tit">
                    <TextOnGreenBlock {...this.props.textOnGreenBlock.textOnGreenBlockInfo} />
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        textOnGreenBlock: state.landingTextOnGreenBlock
    }
}

export default connect(mapStateToProps)(TextOnGreenBlockContainer);