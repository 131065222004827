//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

import {NavLink} from "react-router-dom";
import React from "react";

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            brcrPageName: 'Об агентстве'
        },
        pageTitle: 'О компании',
        pageText: [
            <div>
                <div className="mn-container mn-col l6 mn-justify main-txt">
                    <p><b>D.Logic</b> агентство c большим опытом по разработке решений в области веб-дизайна,
                        электронной коммерции, интернет баз данных, программирования, SEO-продвижения. Правильное
                        сочетание креативности, технологий и стратегии дает нам возможность помочь нашими клиентам
                        достигать нужных целей. Наша команда следует ценностям профессионализма, сотрудничества и
                        постоянного развития. Мы всегда с энтузиазмом принимаем новые вызовы, независимо от их
                        сложности.</p>
                    <p className="mn-justify">За свою <b>19 летнюю историю</b>, мы выполнили <b>более 300
                        проектов</b> различной степени сложности: от сайтов визиток на несколько страниц, до
                        корпоративных сайтов и интернет магазинов. А некоторым компаниям помогли внедрить современные
                        решения для управления бизнес процессами, настроить систему анализа коммерческой информации и
                        финансовых потоков, построить площадку для комфортной коммуникации со своими партнерами.</p>
                </div>
                <div className="mn-container mn-col l6 mn-justify main-txt inner-info-right">
                    <p>Кроме того, агентство D.Logic является правопреемницей известной в Украине веб-студии AV.Com.
                        Ребрендинг прошел успешно. И теперь все обязательства перед клиентами веб-студии AV.Com
                        выполняет агентство D.Logic.</p>
                    <p>Для нас в приоритете - <b>качество</b>, <b>сервис</b>, <b>доверие</b>, <b>дедлайн</b>.</p>
                </div>

                <div className="mn-clear mn-padding-48"/>
                <div className="mn-card-2 inner-company-at">
                    <img src="/assets/pics/at.jpg" className="mn-image img-at"
                         alt="Андрей Тищенко - Lead programmer D.Logic Agency"
                         title="Андрей Тищенко - CEO D.Logic Agency"/>
                    <div className="mn-container mn-center">
                        <p><b className="mn-text-black">Андрей Тищенко</b><br/><span className="main-content-1">CEO & Lead programmer D.Logic Agency</span>
                        </p>
                        <p><NavLink to="https://www.linkedin.com/in/andrii-tyshchenko/" className="linkedin-btn"
                                    target="_blank"><i className="fab fa-linkedin-in"/></NavLink></p>
                    </div>
                </div>
            </div>
        ]
    }
}

const companyReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default companyReducer;