import React from 'react';

const Service16 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <h2 className="service-subtitle sub-dop">Как подобрать домен</h2>
                <p>1. При выборе имени домена, прежде всего надо помнить, что оно может состоять только из букв, цифр и
                    символа дефиса "-". Далее, рекомендуется выбирать доменное имя идентичное названию компании,
                    торговой марки, названию товара или услуги, направлению деятельности. Набирать его надо латиницей
                    либо транслитом.</p>
                <p>2. Очень важно, что если исходное название для использования в домене состоит из трех и более слов,
                    то не рекомендуется использовать все три. Тут лучше укоротить до двух слов и записать их в названии
                    домена через дефис.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/13-hosting_1.jpg" className="mn-image"
                                                alt="Подбор домена и хостинг" title="D.Logic - Подбор домена и хостинг"/>
                </div>
                <p>&nbsp;</p>

                <p>3. Некоторые поисковые системы в выводе результатов поиска анализируют доменное имя. И если оно
                    соответствует поисковому слову, сайт выдается с большим рейтингом, т.е. выдается выше в выводе
                    поиска. При этом, дефис в названии будет восприниматься как разделитель слов.</p>
                <p>4. Раньше считалось, что надо правильно выбрать доменную зону. Если компания собирается работать,
                    например, только по Киеву, то выбирать рекомендуется доменную зону <b>kiev.ua</b> . Если же по
                    Украине, то <b>com.ua</b> или <b>.ua</b> . Если же организация будет работать по всей Европе,
                    то <b>.eu</b> . Если же по всему миру, то <b>.com</b> . Сейчас никто не придерживается такого
                    жесткого подхода. Т.к., появились десятки и, даже, сотни новых доменных зон с интересными
                    названиями, которые хорошо подходят для различных организаций и бизнесов.
                    Например: <b>.academy</b>, <b>.bar</b>, <b>.cafe</b>, <b>.center</b> и другие.</p>
                <div className="mn-padding-4"/>
                <div className="mn-panel mn-light-gray mn-card-2 domen-panel"><p><i>В выборе домена нет ничего сложного,
                    главное чтобы оно вам нравилось, а доменная зона соответствовала сфере вашей деятельности. В любом
                    случае, мы всегда готовы помочь вам с подбором названия.</i></p></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Хостинг для вашего сайта</h2>
                <p>При необходимости, для наших клиентов мы предоставляем услугу хостинга. <b>Хостинг</b> – это
                    предоставление для сайта места на сервере, который подключен к сети интернет в режиме 24/7. Для
                    управления хостингом предоставляется панель управления cPanel. </p>
                <p>Любой сайт, интернет-магазин или корпоративный портал, который попадет к нам на сервера, получает
                    самые лучшие условия для размещения и нашу техническую поддержку.</p>

                <div className="mn-padding-4"/>
                <div className="inner_site_dev_text">
                    <div className="hosting__explain">
                        <div className="mn-hover-shadow explain__block_1">
                            <div className="inner-hosting-block-title">Супербыстрые SSD диски</div>
                            <div className="">Все данные вашего сайта будут размещены на RAID10 массиве собранный из
                                высокопроизводительных SSD (твердотельных) дисков. Что снижает среднюю скорость загрузки
                                страниц до 0,4 секунды.
                            </div>
                        </div>
                        <div className="mn-hover-shadow explain__block_2">
                            <div className="inner-hosting-block-title">PHP: Возможность выбора</div>
                            <div className="">Для каждого сайта можно выбирать версию PHP в один клик: от 4.4 до 7.3+.
                                Можно легко разрабатывать сайты на самой последней версии PHP. А выбор только
                                необходимых модулей PHP еще и повысит их безопасность.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mn-padding-4"/>

                <h2 className="service-subtitle sub-dop">Функционал и возможности хостинга</h2>
                <div className="mn-container mn-padding-16 mn-hover-shadow dop-host">
                    <ul>
                        <li>Панель управления cPanel</li>
                        <li>Бесплатный неограниченный трафик</li>
                        <li>Сервер Nginx</li>
                        <li>PHP 4.4 / 5.1-5.6 / 7.0-7.4</li>
                        <li>Сервер баз данных MySQL, MariaDB 10, phpMyAdmin</li>
                        <li>Сервер баз данных PostgreSQL 9.2, phpPgAdmin</li>
                        <li>Perl 5, Python Selector, Ruby Selector, NodeJS Selector</li>
                        <li>Неограниченное количество почтовых ящиков с заданием дисковой квоты для каждого</li>
                        <li>Настройка рассылок, переадресаций, автоответчиков</li>
                        <li>POP3, IMAP, SMTP сервисы для работы с почтой (включая SSL шифрование)</li>
                        <li>Защищённый Web-интерфейс для работы с почтой</li>
                    </ul>
                    <br/>
                        <ul>
                            <li>Файловый менеджер в панели управления</li>
                            <li>SSH по запросу, sFTP/FTP, SCP доступ (безопасное копирование)</li>
                            <li>Управление поддоменами, сайтами, FTP-аккаунтами</li>
                            <li>Поддержку неограниченного числа поддоменов</li>
                            <li>Защита директорий паролями</li>
                            <li>Управление задачами по расписанию - Cron</li>
                            <li>Управление .htaccess, собственные страницы ошибок</li>
                            <li>Access и Error журналы (логи сервера), статистика сервера</li>
                            <li>Ежедневное резервное копирование</li>
                        </ul>
                </div>

                <div className="mn-padding-32"/>
            </div>
        </div>
    )
}

export default Service16;