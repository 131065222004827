import React from 'react';

const Service19 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <h2 className="service-subtitle sub-dop">Разработка брендбука, как систематизация фирменного стиля.</h2>
                <p><b>Брендбук</b> (brandbook) – это, по сути, самое полное руководство, содержащее в себе подробное
                    описание всех элементов фирменного стиля, особенности их использования во всех сферах деятельности
                    организации. Данный документ является фундаментом для обеспечения узнаваемости компании. Он помогает
                    избежать проблем связанных с искажением фирменного стиля при трансляции его посредством различных
                    информационных носителей. Довольно часто брендбук называют руководством по управлению брендом. И
                    предназначен он для внутрикорпоративного использования.</p>

                <p>Объем брендбука может варьироваться в самых широких пределах – от 20-30 страниц до 100 и более.
                    Важно, чтобы документ полностью раскрывал все основные показатели фирменного стиля. Как правило,
                    также в нем прописываются: главные ценности компании, ее миссия и философия, позиционирование,
                    стратегия развития, принципы продвижения. Для крупных компаний в нем может содержаться подробное
                    описание корпоративного стиля. Как, например, дизайн и оформление интерьера офиса, офисного здания,
                    внешний вид сотрудников.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/19-brandbook_1.jpg" className="mn-image"
                                                       alt="Создание брендбука"
                                                       title="Создание брендбука в Киеве - D.Logic"/></div>

                <h2 className="service-subtitle sub-dop">Содержание и структура брендбука</h2>
                <p>Традиционными разделами руководства по фирменному стилю являются:</p>
                <ul>
                    <li>информационная часть о цели компании, ее миссии и ценностях;</li>
                    <li>детальное описание логотипа и всех аспектов его использования (logobuk);</li>
                    <li>подробное описание графических элементов фирменного стиля, шрифтовых гарнитур, фирменной
                        цветовой палитры;
                    </li>
                    <li>подробные инструкции по применению всех элементов фирменного стиля на различных визуальных и
                        информационных носителях, плоскостях. Примеры рекламных макетов различных форматов;
                    </li>
                    <li>раздел посвященный описанию деловой продукции: визитки, фирменные папки, фирменные конверты,
                        пакеты, брелоки, ручки, элементы верхней одежды своих сотрудников;
                    </li>
                    <li>может присутствовать раздел, который посвящен юридическим вопросам, и в котором раскрывается
                        информация обо всех авторских и смежных правах.
                    </li>
                </ul>
                <div>&nbsp;</div>
                <div className="mn-center dp-img mn-hide-small"><img src="/assets/photos/19-brandbook_2.jpg"
                                                                     className="mn-image" alt="Структура и содержание"
                                                                     title="Структура и содержание брендбука"/></div>
                <div className="mn-center dp-img mn-hide-large mn-hide-medium"><img src="/assets/photos/19-brandbook_2_mob.jpg"
                                                                                    className="mn-image"
                                                                                    alt="Разработка брендбука"
                                                                                    title="Содержание и структура при разработке брендбука"/>
                </div>

                <h2 className="service-subtitle sub-dop">Створюємо брендбук. Що важливо знати.</h2>
                <p>При створенні брендбуку треба пам'ятати кілька моментів, а саме:</p>
                <ul>
                    <li>Не завжди чим більше сторінок, тим краще. Зайва деталізація може заплутати кого завгодно. Тому,
                        тут важливий баланс між деталізацією і легкістю її сприйняття.
                    </li>
                    <li>Всі розуміють, що стандартних фірмових стилів не буває. Тому, при створенні такого документа не
                        може бути ніяких стандартних рішень.
                    </li>
                    <li>Брендбук - не панацея. Це всього лише документ покликаний служити злагодженій роботі різних груп
                        фахівців: маркетологам, рекламістам, дизайнерам, менеджерам, тощо.
                    </li>
                    <li>Кожен співробітник, в процесі своєї роботи, повинен вміло і правильно дотримуватись основних
                        стандартів фірмового стилю компанії, щоб організація з точки зору зовнішнього спостерігача
                        виглядала як єдине ціле. А ось як правильно дотримуватися їх, для цього і призначений брендбук.
                    </li>
                </ul>
                <p>&nbsp;</p>
                <div className="mn-panel mn-blue mn-card-4 mn-center mn-padding-16 inner-panel-1">
                    <b>(099)370-35-37</b><br/> Телефонуйте. Відповімо на всі ваші запитання.</div>
                <p>&nbsp;</p>

                <p>В даний час, <b>ціна розробки брендбуку</b> не надто висока. Тому, замовити таку послугу може
                    дозволити собі будь-яка організація. Поява брендбуку у компанії буде сприяти підвищенню її
                    впізнаваності серед цільової аудиторії та зростанню популяризації. Кожен працівник компанії буде
                    чітко розуміти, яка корпоративна ідеологія і куди спрямований вектор розвитку фірми в цілому.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/19-brandbook_3.jpg" className="mn-image"
                                                       alt="Разнообразие вариантов брендбука"
                                                       title="Варианты и разнообразие при разработке брендбука"/></div>

                <div className="mn-clear mn-padding-24"/>
                <div className="mn-center">
                    <a href="https://www.facebook.com/sharer.php?u=https://dlogic.com.ua/service/sozdanie-brendbuka/"
                       title="Share on Facebook" target="_blank" className="fb btn"><i
                        className="fab fa-facebook-f"/> &nbsp;&nbsp;Facebook</a>
                    <a href="https://twitter.com/intent/tweet?url=https://dlogic.com.ua/service/sozdanie-brendbuka/&text=Создание брендбука компании в D.Logic Agency"
                       target="_blank" className="twitter btn"><i
                        className="fab fa-twitter"/> &nbsp;&nbsp;Twitter</a>
                </div>
            </div>
        </div>
    )
}

export default Service19;