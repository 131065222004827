import React from 'react';
import {NavLink} from "react-router-dom";

const TopMenuBarForMobileOverlayMyNav2 = (props) => {

    let closeNav2 = () => {
        document.querySelector('#myNav2').style.height = '0'
    }

    let phones = props.topMobileMenuInfo.phones.map(item => {
        return (
            <NavLink to={`tel:${item.telLink}`}>{item.tel}</NavLink>
        )
    })

    return (
        <div id="myNav2" className="overlay">
            <span className="closebtn" onClick={closeNav2}>
                <i className="fas fa-times"/>
            </span>
            <div className="overlay-content">
                {phones}
            </div>
        </div>
    )
}

export default TopMenuBarForMobileOverlayMyNav2;