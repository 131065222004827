import React from 'react';

const TopMenuBarForMobileLogo = (props) => {

    return (
        <div className="mn-left">
            <img src={props.topMobileMenuInfo.topLogoUrl} alt=""
                 className="mn-image mn-hide-small main-top-line-2-img"/>
            <img src={props.topMobileMenuInfo.topMobileLogoUrl} alt=""
                 className="mn-image mn-hide-medium mn-hide-large main-top-line-2-img2"/>
        </div>
    );
}

export default TopMenuBarForMobileLogo;