import React from 'react';
import {connect} from "react-redux";
import OurHistory from "./OurHistory";

class OurHistoryContainer extends React.Component {
    render() {
        return <OurHistory {...this.props.ourHistory.ourHistoryInfo} />
    }
}

let mapStateToProps = (state) => {
    return {
        ourHistory: state.landingOurHistory
    }
}

export default connect(mapStateToProps)(OurHistoryContainer);