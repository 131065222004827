import React from 'react';
import MainBrcr from "./MainBrcr";
import {NavLink} from "react-router-dom";

const Main = (props) => {

    let Services = (props) => {
        return props.serviseList.map(item => <NavLink to={item.link} className="services-link-list">{item.name}</NavLink>)
    }

    let servicesInfo = props.pageText.map(section => {
        return (
            <div className="mn-container mn-col l4">
                <div className="mn-hover-shadow inner-services-block mn-white">
                    <div className="mn-container mn-padding-8 mn-center mn-hover-dark-gray services-block-tit">{section.sectionName}</div>
                    <div className="mn-container mn-padding-16">
                        <Services serviseList={section.services} />
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div>
            <div className="inner-gap"/>
            <div className="mn-container">
                <MainBrcr brcr={props.brcr} />
            </div>
            <div className="mn-container mn-padding-16">
                <div className="inner-info"><h1 className="mn-center">{props.pageTitle}</h1></div>

                <div className="inner_site_dev_text">
                    {servicesInfo}
                    <div className="mn-clear"/>
                </div>
                <div className="mn-clear mn-padding-16"/>
            </div>
        </div>
    )
}

export default Main;