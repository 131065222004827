import React from 'react';

const TopMenuBarForMobileIcons = (props) => {

    let openNav2 = () => {
        document.querySelector('#myNav2').style.height = '100%'
    }
    let openBigMenu = () => {
        document.querySelector('#id01').style.display='block'
    }
    let openNav = () => {
        document.querySelector('#myNav').style.height = '100%'
    }

    return (
        <div className="mn-right">
            <img src={props.topMobileMenuInfo.topPhoneUrl} alt=""
                 className="main-top-line-2-img-mob-menu"
                 onClick={openNav2}
                 style={{marginRight: 16 + 'px'}}/>
            <img src={props.topMobileMenuInfo.topMenu2Url} alt=""
                 className="main-top-line-2-img-mob-menu"
                 onClick={openBigMenu}
                 style={{marginRight: 16 + 'px'}}/>
            <img src={props.topMobileMenuInfo.topMenuUrl} alt=""
                 className="main-top-line-2-img-mob-menu"
                 onClick={openNav}/>
        </div>
    );
}

export default TopMenuBarForMobileIcons;