import React from 'react';
import {connect} from "react-redux";
import TechnologyStack from "./TechnologyStack";

class TechnologyStackContainer extends React.Component {
    render() {
        return (
            <div className="left-right-flex-pad">
                <TechnologyStack {...this.props.technologyStack.technologyStackInfo} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        technologyStack: state.landingTechnologyStack
    }
}

export default connect(mapStateToProps)(TechnologyStackContainer);