import React from 'react';
import RuInnerPageTopPhones from "./RuInnerPageTopPhones";
import RuInnerPageTopLogoMenu from "./RuInnerPageTopLogoMenu";
import RuInnerPageScrollingTopLogoMenu from "./RuInnerPageScrollingTopLogoMenu";

const RuInnerPageHeaderContainer = (props) => {

        return (
            <header className="mn-hide-medium mn-hide-small inner-top-photo">
                {/*<header className="mn-hide-medium mn-hide-small parallax-window inner-top-photo"
                        data-parallax="scroll"
                        data-image-src={topPhoto}>*/}
                <RuInnerPageTopPhones
                    languageLinks={props.languageLinks}
                    innerPageTopPhonesInfo={props.topPhonesLanguagesLogoMenu.innerPageTopPhonesInfo} />
                <RuInnerPageTopLogoMenu
                    innerPageTopLogoAndMenuInfo={props.topPhonesLanguagesLogoMenu.innerPageTopLogoAndMenuInfo} />
                <RuInnerPageScrollingTopLogoMenu
                    innerPageTopLogoAndMenuInfo={props.topPhonesLanguagesLogoMenu.innerPageTopLogoAndMenuInfo} />
            </header>
        )
}

export default RuInnerPageHeaderContainer;


/*let mapStateToProps = (state) => {
    return {
        topPhonesLanguagesLogoMenu: state.innerPageTopPhonesLogoMenu
    }
}*/

/*
export default connect(mapStateToProps)(RuInnerPageHeaderContainer);
*/