//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    textBeforeOurWorksInfo: {
        title: 'Наши работы',
        textBody: 'К процессу создания сайта мы приступаем со всей ответственностью, понимая, насколько это важно для заказчика. Хорошо знаем как объединить бизнес-цели наших клиентов и ожидания пользователей. Ниже приведены некоторые из наших последних работ в области разработки сайтов и логотипов. Больше работ можно посмотреть перейдя по ссылке &quot;Портфолио&quot;.'
    }
}

const landingTextBeforeOurWorksReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingTextBeforeOurWorksReducer;