import React from 'react';
import {NavLink} from "react-router-dom";

const MainBrcr = (props) => {

    return (
        <div className="mn-container">
            <NavLink to={props.brcr.link1} className="brcr-link">{props.brcr.linkName1}</NavLink> <span className="brcr-line">/</span>
            <NavLink to={props.brcr.link2} className="brcr-link">{props.brcr.linkName2}</NavLink> <span className="brcr-line">/</span>
            <span className="brcr-nolink">{props.pageName}</span>
        </div>
    )
}

export default MainBrcr;