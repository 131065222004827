import React from 'react';
import {Link} from "react-router-dom";

const Service17 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <h2 className="service-subtitle sub-dop">Создание логотипа компании </h2>
                <p>Логотип отображает суть компании, идеи, товара или мероприятия. Своим цветовым и графическим решением
                    логотип способен сформировать образ организации в глазах партнеров и потребителей. Именно
                    отличительная функция логотипа позволяет потребителю сразу узнавать производителя товара, не читая
                    даже его описания. С другой стороны, логотип может выступать еще и элементом оформления товара. А
                    гарантийная и эстетическая функции качественно разработанного логотипа, повышает ценность
                    товара.</p>
                <p>Логотип создается с помощью комбинации шрифтовых, цветовых и графических элементов с целью раскрыть
                    суть и цель компании, товара или идеи. Но важно помнить, что логотип должен быть оригинальным,
                    запоминаемым и легко узнаваемым. Создание логотипа требует творческого подхода и особого умения в
                    таком ремесле.</p>
                <div>&nbsp;</div>
                <div className="mn-center mn-hide-small logodev-border" style={{backgroundColor: '#F7F7F7'}}><img
                    src="/assets/photos/17-logo_1.jpg" className="mn-image" alt="Варианты созданного логотипа"
                    title="Несколько вариантов созданого логотипа"/></div>
                <div className="mn-center mn-hide-medium mn-hide-large logodev-border"
                     style={{backgroundColor: '#F7F7F7'}}><img src="/assets/photos/17-logo_1_mob.jpg" className="mn-image"
                                                             alt="Несколько вариантов разработанного логотипа"
                                                             title="Варианты разработанного логотипа"/></div>
                <div>&nbsp;</div>

                <p>Логотип чаще всего состоит из некоторой графики и надписи. Надпись при этом обыгрывается шрифтами с
                    определенным искажением, модификацией, дроблением и другими приемами. Все подчинено одной цели -
                    выделиться или донести некоторую мысль пользователю. Неважно, занимаетесь вы строительством,
                    оказываете медицинские услуги или шьете одежду на заказ. Без логотипа вы не обойдетесь. Без
                    разработанного логотипа вас не будут узнавать.</p>

                <h2 className="service-subtitle sub-dop">Сроки создания логотипа в D.Logic</h2>
                <p>Создание логотипа зависит как от фантазии мастера, так и от фантазии заказчика и может занимать
                    достаточно много времени, прежде чем обе стороны придут к согласию. Это с одной стороны. С другой
                    же, важно разработать такой фирменный логотип, который не напоминал бы какой уже существующий
                    логотип. Очень важно, чтобы и вы как заказчик участвовали бы в разработке, подкидывая идеи того,
                    чтобы вам хотелось бы видеть. Поэтому разработка логотипа занимает немалое количество времени.</p>
                <p>&nbsp;</p>

                <p><b>1 - Вариант "Старт"</b><br/>Разработка шрифтового написания и графических элементов, подбор
                    фирменных цветов. Мы предоставляем <b>3 (три)</b> варианта дизайна логотипа. Выбранный заказчиком
                    вариант будет доработан с учетов всех пожеланий.</p>
                <p><b>Срок разработки логотипа:</b> 6-10 рабочих дней.</p>
                <p>&nbsp;</p>

                <div className="mn-padding-16 logodev-border">
                    <div className="mn-container mn-col m6 l6 mn-center">
                        <img src="/assets/pics/logo/logo_6_meridian_m.png" className="mn-image" alt="Пример логотипа #1"
                             title="Один из примеров созданного студией D.Logic логотипа"/>
                    </div>
                    <div className="mn-container mn-col m6 l6 mn-center">
                        <img src="/assets/pics/logo/logo_2_agroliderukraina_m.png" className="mn-image"
                             alt="Пример логотипа #2" title="Другой пример созданного стадией D.Logic логотипа"/>
                    </div>
                    <div className="mn-clear"/>
                </div>

                <p>&nbsp;</p>

                <p><b>2 - Вариант "Стандарт"</b><br/>Разработка шрифтового написания и графических элементов, подбор
                    фирменных цветов. Мы предоставляем <b>5 (пять)</b> вариантов дизайна логотипа. Выбранный заказчиком
                    вариант будет доработан с учетов всех пожеланий.</p>
                <p><b>Срок разработки логотипа:</b> 8-12 рабочих дней.</p>
                <p>&nbsp;</p>
            </div>

            <div className="mn-panel mn-blue mn-card-4 mn-center mn-padding-16 inner-panel-1">
                <b>(099)370-35-37</b><br/> Звоните. Ответим на все ваши вопросы.</div>

            <p>&nbsp;</p>
            <p><b>Созданные нами логотипы можно посмотреть <Link to="/logotypes/" className="logodev-link"
                                                              target="_blank">здесь >>></Link></b></p>

            <h2 className="service-subtitle sub-dop">Почему создать логотип очень важно.</h2>

            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Графика лучше, чем текст</span>
                </div>
                <div className="result-list-descr">Благодаря исследованиям выяснилось, что текст человек запоминает
                    хуже, нежели графику. Логотип – это как эмблема организации, и поэтому запоминается лучше. Красивый
                    логотип запоминается еще и на эмоциональном уровне. Более того, удачно созданный логотип несет в
                    себе информацию о роде деятельности организации. Все это повышает узнаваемость бренда.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Способ выделиться среди конкурентов</span>
                </div>
                <div className="result-list-descr">Логотип помогает потребителю быстрее находить нужный товар или услугу
                    нужного ему производителя. Логотип напомнит клиентам о вашем существовании.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Логотип – часть фирменного стиля</span>
                </div>
                <div className="result-list-descr">Без логотипа фирменный стиль, все равно, что человек без лица.
                    Мало-мальски маркетинговую компанию невозможно будет провести без логотипа. Не будет узнаваемости.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Логотип – часть бренда</span>
                </div>
                <div className="result-list-descr">Когда сформирована лояльность к вашей продукции, люди ищут ее по
                    логотипу и фирменному стилю. Нет логотипа, фирменный стиль не поможет. Поэтому формирование
                    фирменного стиля начинают, прежде всего, с разработки логотипа.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Повышается эффективность продаж</span>
                </div>
                <div className="result-list-descr">Когда лояльный вам потребитель встречает новый для себя товар, но
                    снабженный вашим логотипом, он быстрее согласится купить именно его, т.к. доверяет вам по предыдущим
                    своим покупкам. Хороший логотип – это актив, который повысит вашу надежность в глазах покупателя.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>логотип повышает узнаваемость</span>
                </div>
                <div className="result-list-descr">Во многих рекламных материалах вас будут узнавать именно по логотипу,
                    а потом уже по содержанию рекламного сообщения. Вот почему так важен логотип.
                </div>
            </div>

            <div className="mn-center mn-hide-small"><img src="/assets/photos/17-logo_2.jpg" className="mn-image"
                                                          alt="Разнообразие вариантов логотипа при разработке"
                                                          title="При создании логотипа предоставляем на выбор несколько вариантов как, например, здесь показано"/>
            </div>
            <div className="mn-center mn-hide-medium mn-hide-large logodev-border">
                <div><img src="/assets/photos/17-logo_2_mob-1.jpg" className="mn-image" alt="Вариации созданного логотипа"
                          title="Варианты разработанного логотипа"/></div>
                <div style={{backgroundColor: '#181411'}}><img src="/assets/photos/17-logo_2_mob-2.jpg" className="mn-image"
                                                             alt="Другой пример вариаций созданного логотипа"
                                                             title="Другой пример вариантов разработанного логотипа"/>
                </div>
            </div>
            <p>&nbsp;</p>

            <div className="l-info-block-txt">
                <p><b>Разработка логотипов Киев</b> – это часто встречаемый запрос в поиске. Он выдает много ссылок на
                    компаний и мастеров. Компаний больших и маленьких. Дизайнеров опытных и не очень. Главное, чтобы
                    опыт мастера, который вам понравился, соответствовал бы сумме, с которой вы готовы расстаться.</p>
                <p>Наша студия готова создать для вас логотип, который был бы привлекательным и раскрывал бы суть вашей
                    деятельности. Вы можете перезвонить нам по любому из номеров <b>(044)383-20-18 или
                        (099)370-35-37</b> и мы готовы обсудить с вами все вопросы касательно разработки логотипа. При
                    необходимости, мы готовы на личную встречу для обсуждения всех нюансов будущего проекта.</p>
            </div>
        </div>
    )
}

export default Service17;