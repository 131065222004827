import React from 'react';

const OurHistory = (props) => {

    let marks = props.ourMarks.map(item => {
        return (
            <div className="mn-container mn-col l4 main-5-sub-block">
                <div className="main-5-sub-block-inner">
                    <div className="mn-center main-5-num count">{item.numberMark}</div>
                    <div className="mn-center">{item.markDescription}</div>
                </div>
            </div>
        );
    })

    /*<div className="parallax-window main-5" data-parallax="scroll" data-image-src={props.bgPhotoUrl}>*/
    return (
        <div className="main-5">
            <div className="left-right-flex-pad">
                <div className="mn-container mn-text-white">
                    <div className="mn-container mn-center main-5-tit"><span>{props.title}</span></div>
                    <div id="counter">
                        {marks}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurHistory;