//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            brcrPageName: 'Логотипы разработанные в D.Logic'
        },
        buttonActive: {link: '/logotypes', buttonName: 'Дизайн'},
        buttonUnActive: {link: '/portfolio', buttonName: 'Разработка'},
        pageTitle: 'Портфолио',
        pageTitlePink: 'дизайн, логотипы',
        logoList: [
            {
                key: 1,
                url: 'meridian',
                photo_preview: 'logo_6_meridian_m.png',
                alt: 'Логотип для программы Меридиан (Аэросвит)',
                title: 'Разработан логотип для программы Меридиан (Аэросвит)'
            },
            {
                key: 2,
                url: 'fastfood',
                photo_preview: 'logo_5_fastfood_m.png',
                alt: 'Логотип для выставки Fastfood (Киев)',
                title: 'Разработан логотип для выставки Fastfood (Киев)'
            },
            {
                key: 3,
                url: 'medicom',
                photo_preview: 'logo_3_medicom_m.png',
                alt: 'Логотип для фирмы Медиком (Киев)',
                title: 'Разработан логотип для фирмы Медиком (Киев)'
            },
            {
                key: 4,
                url: 'agrolider',
                photo_preview: 'logo_2_agroliderukraina_m.png',
                alt: 'Логотип для фермерского комплекса Агро-Лидер-Украина',
                title: 'Разработан логотип для фермерского комплекса Агро-Лидер-Украина'
            },
            {
                key: 5,
                url: 'roxvision',
                photo_preview: 'logo_4_roxvision_m.png',
                alt: 'Логотип для фирмы Roxvision (USA)',
                title: 'Разработан логотип для фирмы Roxvision (USA)'
            },
            {
                key: 6,
                url: 'vertex',
                photo_preview: 'logo_1_vertex_m.png',
                alt: 'Логотип для фирмы Вертекс (Киев)',
                title: 'Разработан логотип для фирмы Вертекс (Киев)'
            }
        ],
        pageText: [
            <div>

            </div>
        ]
    }
}

const logotypesReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default logotypesReducer;