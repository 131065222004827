import React from 'react';
import {Link} from "react-router-dom";

const RightMenu = (props) => {

    let Services = (props) => {
        return props.serviseList.map(item => {
            /*if(document.location.pathname === item.serviceUrl) {*/
            if(props.path === item.serviceUrl) {
                return <span className="bg-menu">{item.serviceName}</span>
            } else {
                return <Link to={item.serviceUrl} className="inner_right_menu_link">{item.serviceName}</Link>
            }
        })
    }

    let rightServiceMenu = props.ruServicesListInfo.ruServices.map(nameGroup => {
        return (
            <div>
                <div className="mn-container mn-center mn-padding-4 mn-blue-gray inner_right_menu_title">{nameGroup.blockName}</div>
                <div className="inner-r-menu-dop">
                    <ul>
                        <Services serviseList={nameGroup.blockServiceList} path={props.path} />
                    </ul>
                </div>
            </div>
        )
    })
    return (
        <div className="mn-cell mn-hide-medium mn-hide-small r-menu-width inner-r-menu">
            {rightServiceMenu}
        </div>
    )
}

export default RightMenu;