import React from 'react';

const Service4 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Создание landing page </b> (посадочной страницы) – это создание страницы, на которую попадает
                    пользователь после того, как кликнул на рекламное объявление.</p>

                <p>Такая страница предназначена для того, чтобы стимулировать определенное действие пользователя.
                    Например, купить что-нибудь, подписаться, участвовать в акции и другие действия. На таких страницах
                    идет определенный сюжет, следуя которому заинтересованный пользователь таки совершает то, к чему его
                    побуждают. Как минимум он оставляет свои контактные данные. Главная задача landing page -
                    конвертация такого посетителя в покупателя или клиента компании. </p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/5-landing_1.jpg" className="mn-image"
                                                       alt="Создание Landing Page"
                                                       title="Процесс создания Landing Page"/></div>
                <p>&nbsp;</p>

                <p>Такая страница оформляется в фирменном стиле, для нее формулируются уникальные торговые предложения,
                    что позволит совершать через нее больше продаж.</p>

                <p><b>Время разработки landing page:</b> 2 - 3 недели.</p>
            </div>

            <div className="service-title-2">Что получает заказчик в результате?</div>
            <h2 className="service-subtitle">1. Основные моменты</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Уникальный дизайн</span>
                </div>
                <div className="result-list-descr">Каждый созданный в D.Logic лендинг получает Авторский дизайн от
                    нашего творческого коллектива в соответствии с пожеланиями заказчика. Для Landing page
                    прорисовывается одна страница. По желанию заказчика, к ней могут быть добавлены еще несколько
                    страниц.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Адаптивность и кроссбраузерность</span>
                </div>
                <div className="result-list-descr">Все наши лендинги имеют адаптивный дизайн, т.е. корректно
                    отображаются на экранах любых размеров – от маленьких на смартфонах и планшетах, до больших у
                    ноутбуков и мониторов настольных систем. Более того, код сайта позволяет ему корректно отображаться
                    во всех популярных браузерах настольных и мобильных систем - Chrome, Firefox, Opera, Internet
                    Explorer, Safari и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Система управления контентом (CMS)</span>
                </div>
                <div className="result-list-descr">Каждая созданная нами посадочная страница снабжается удобной Системой
                    управления, чтобы пользователь мог легко самостоятельно обновлять любую информацию: цены, тексты,
                    фото- и видеоматериалы.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Начальное наполнение страницы</span>
                </div>
                <div className="result-list-descr">Осуществляем первоначальное наполнение страницы всей необходимой
                    информацией.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Размещение проекта на веб-сервере</span>
                </div>
                <div className="result-list-descr">После выполнения всех работ по созданию Landin Page, наши специалисты
                    размещают все материалы проекта на веб-сервере и настраивают его работу.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Обучение работе с админкой сайта</span>
                </div>
                <div className="result-list-descr">После размещения проекта на веб-сервере, мы проводим обучение
                    заказчика работе с сайтом. Процесс обучение обычно длится 1-2 часа и хорошо воспринимается
                    неподготовленным пользователем.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/5-landing_2.jpg" className="mn-image"
                                                       alt="Итог работ по созданию лендинга"
                                                       title="Результат разработки Landing Page"/></div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">2. Функционал landing page</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Статистика страницы</span>
                </div>
                <div className="result-list-descr">По желанию заказчика на посадочную страницу ставим систему сбора
                    статистики посещений Google Analytics и рассказываем об основных моментах работы с ней.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Форма обратной связи</span>
                </div>
                <div className="result-list-descr">Позволяет пользователям отправлять сообщения или запросы по поводу
                    той или иной интересующей их информации.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Подписка на рассылку</span>
                </div>
                <div className="result-list-descr">Позволяет организовать на странице подписку на e-mail рассылку.</div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Фотогалерея</span>
                </div>
                <div className="result-list-descr">Позволяет отображать на сайте пользователям набор фотографий в
                    наилучшем виде.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Заказать обратный звонок</span>
                </div>
                <div className="result-list-descr">На странице размещается форма для посетителей, которые хотят, чтобы к
                    ним перезвонили.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Карта проезда</span>
                </div>
                <div className="result-list-descr">На основе сервиса Google Maps, на Landing page можно будет разместить
                    интерактивную масштабируемую карту подъезда к офису.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/5-landing_3.jpg" className="mn-image"
                                                       alt="Дизайн функционала лендинга"
                                                       title="Создание функционала Landing Page"/></div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">3. Дополнительно</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Иконки переходов в группы соц. сетей</span>
                </div>
                <div className="result-list-descr">Есть возможность размещать на сайте иконки и виджеты групп социальных
                    сетей (Facebook, Twitter).
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Формирование Sitemap</span>
                </div>
                <div className="result-list-descr">Формирование XML-карты сайта для создания условий более полной
                    индексации страниц сайта поисковыми системами Google, Yandex и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Понятный URL (ЧПУ)</span>
                </div>
                <div className="result-list-descr">Созданные нами Landing page имеют короткие и понятные внутренние
                    ссылки. Это позволяет людям и поисковым машинам распознавать содержимое страниц по URL.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита админки сайта</span>
                </div>
                <div className="result-list-descr">Доступ к админке сайта не имеет прямых ссылок со страниц сайта. Это
                    не позволяет автоматически обнаруживать ее URL анализаторами кода. Также, используются и другие
                    приемы препятствующие определению типа CMS сайта. Более того, сама система аутентификации защищена
                    от систем скоростного подбора паролей.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита и безопасность</span>
                </div>
                <div className="result-list-descr">Все наши сайты используют защиту базы данных от инъекций, защиту от
                    удаленного запуска, защиту от запуска скриптов и другие виды защит.
                </div>
            </div>
        </div>
    )
}

export default Service4;