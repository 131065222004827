import React from 'react';

const TimeLine = (props) => {

    let stageComponent = props.stages.map(st => {
        return (
            <div className={Math.trunc(st.id / 2) === st.id / 2
                ? "container con-right" : "container con-left" }>
                <div className="content">
                    <h3>{st.stageTitle}</h3>
                    <p><b className="main-content-0">{st.stageName}</b><br/><span
                        className="main-content">{st.stageDescription}</span>
                    </p>
                </div>
            </div>
        );
    });

    return (
        <div className="main-8-1">
            <div className="left-right-flex-pad">
                <div className="mn-container mn-padding-32"/>
                <h3 className="main-8-1-1">{props.title}</h3>
                <div className="mn-container mn-padding-24"/>
                <div className="timeline">
                    {stageComponent}
                </div>
            </div>
        </div>
    );
}

export default TimeLine;