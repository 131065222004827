import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import './assets/css/styles_2.css';
import './assets/css/styles.css';
import Landing from "./components/Landing/Landing";
import Company from "./components/Company/Company";
import Services from "./components/Services/Services";
import Portfolio from "./components/Portfolio/Portfolio";
import Logotypes from "./components/Logotypes/Logotypes";
import Clients from "./components/Clients/Clients";
import Contacts from "./components/Contacts/Contacts";
import Site from "./components/Site/Site";
import Logo from "./components/Logo/Logo";
import Service from "./components/Service/Service";


const App = () => {
    return (
        <Router>
            <div>
                <Route exact path='/' render={() => <Landing />} />
                <Route exact path='/company' render={() => <Company />} />
                <Route exact path='/services' render={() => <Services />} />
                <Route exact path='/portfolio' render={() => <Portfolio />} />
                <Route exact path='/logotypes' render={() => <Logotypes />} />
                <Route exact path='/clients' render={() => <Clients />} />
                <Route exact path='/contact' render={() => <Contacts />} />
                <Route exact path='/site/:id' render={() => <Site />} />
                <Route exact path='/logo/:id' render={() => <Logo />} />
                <Route exact path='/service/:name' render={() => <Service />} />
            </div>
        </Router>
    );
}

export default App;
