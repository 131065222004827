import React from 'react';

const Service2 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Качественно разработанный интернет магазин</b> – наилучшее решение для любой компании, которая
                    решила заняться интернет торговлей. С другой стороны, он дает возможность компании работать на самом
                    быстрорастущем рынке. По последним данным, рынок интернет торговли в Украине растет в среднем на 60
                    – 90 % в год. По разным оценкам, количество интернет магазинов в Украине давно уже «перевалило» за
                    отметку в 10 000. И это без учета продавцов на маркетплейсах.</p>

                <p>Интернет магазин не только информирует посетителей обо всех товарах компании, но и повышает доверие
                    посетителей к владельцам такого ресурса. Продуманный, удобный и информативный контент интернет
                    магазина позволяет потенциальному покупателю быстро принимать решение – покупать или нет. Ведь,
                    кроме цены надо донести клиенту компании типы оплат, возможности доставки и возврата товара, виды
                    послепродажного сервисного обслуживания, и т.д.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/2-eshop_1.jpg" className="mn-image"
                                                alt="Качество интернет магазина"
                                                title="Подача информации как пример качества созданного интернет магазина"/>
                </div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle">Как мы подходим к созданию интернет магазина.</h2>
                <p>Разработка интернет магазина начинается с нашего знакомства с бизнесом заказчика, анализа его
                    деятельности для выявления ключевых особенностей и бизнес целей, стоящих перед проектом. Важно
                    понять все до мельчайших подробностей в способе ведения продаж заказчиком, чтобы правильно
                    отобразить их в создаваемом проекте. Ведь структура интернет магазина полностью подчинена одной цели
                    – обеспечить быстрое, эффективное обслуживание клиентов и помочь человеку сделать свой выбор.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/2-eshop_2.jpg" className="mn-image"
                                                       alt="Подход к разработке интернет магазина"
                                                       title="Знакомство с бизнесом заказчика как основа создания успешного интернет магазина"/>
                </div>
                <p>&nbsp;</p>

                <p>Следующий шаг – составление технического задания, согласно которому будет проходить весь процесс
                    разработки интернет магазина. После его утверждения обеими сторонами начинается собственно сам этап
                    разработки. Сначала готовится дизайн-макет главной и внутренних страниц, витрины с товарами, самой
                    товарной страницы с описаниями и техническими характеристиками, корзины. После внесение всех правок
                    в него и принятия заказчиком, начинается сборка проекта. Потом проводится тестирование
                    интернет-магазина на предмет наличия ошибок. После их устранения готовый проект разворачивается на
                    веб-сервере.</p>

                <p>Интернет магазин обязательно снабжается системой управления контентом или CMS (Content Management
                    System) для того, чтобы менеджеры компании заказчика могли самостоятельно менять на нем всю
                    текстовую информацию, цены, контактные данные, а также фото- и видеоматериалы.</p>

                <p><b>Время разработки интернет магазина:</b> 7 - 12 недель.</p>
            </div>

            <div className="service-title-2">Что получает заказчик в результате?</div>
            <h2 className="service-subtitle">1. Основные моменты</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Уникальный дизайн</span>
                </div>
                <div className="result-list-descr">Каждый созданный в D.Logic интернет магазин получает Авторский дизайн
                    от нашего творческого коллектива в соответствии с пожеланиями заказчика. Для каждого проекта мы
                    готовим до 2 (двух) вариантов дизайна для главной страницы магазина, внутренней страницы, витрины с
                    выкладкой товаров, товарной страницы, корзины и предоставляем их клиенту для выбора.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Адаптивность и кроссбраузерность</span>
                </div>
                <div className="result-list-descr">Каждый интернет-магазин созданный в D.Logic имеет адаптивный дизайн,
                    т.е. корректно отображается на экранах любого размера – от маленьких на смартфонах и планшетах, до
                    больших у ноутбуков и мониторов настольных систем. Более того, код магазина позволяем ему корректно
                    отображаться во всех популярных браузерах настольных и мобильных системах - Chrome, Firefox, Opera,
                    Internet Explorer, Safari и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Система управления контентом (CMS)</span>
                </div>
                <div className="result-list-descr">Сайт снабжается удобной Системой управления контентом, чтобы
                    пользователь мог легко самостоятельно обновлять любую информацию: цены, тексты, фото- и
                    видеоматериалы.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Начальное наполнение сайта</span>
                </div>
                <div className="result-list-descr">Осуществляем первоначальное наполнение интернет магазина всей
                    необходимой информацией, объем которой согласовывается с заказчиком.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Размещение проекта на веб-сервере</span>
                </div>
                <div className="result-list-descr">После выполнения всех работ по созданию сайта, наши специалисты
                    размещают все материалы магазина на веб-сервере и настраивают его работу.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Обучение работе с админкой сайта</span>
                </div>
                <div className="result-list-descr">После размещения проекта на веб-сервере, мы проводим обучение
                    заказчика работе с сайтом. Процесс обучение обычно длится 1-2 часа и хорошо воспринимается
                    неподготовленным пользователем.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/2-eshop_3.jpg" className="mn-image"
                                                       alt="Наполнение интернет магазина"
                                                       title="Производится первоначальное наполнение интеренет магазина всей необходимой информацией"/>
                </div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">2. Функционал интернет магазина</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Новостная лента</span>
                </div>
                <div className="result-list-descr">Данный функционал позволяет отображать новости компании на страницах
                    магазина. Краткое содержание новостей может отображаться на других страница ресурса.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Каталог товаров или витрина</span>
                </div>
                <div className="result-list-descr">Группировка товаров по категориям и отображение их в соответствующем
                    порядке.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Выборки и фильтры подбора товара</span>
                </div>
                <div className="result-list-descr">Данный функционал позволяет делать поиск товара или группы товаров,
                    осуществлять выборку или фильтрацию товаров по определенному критерию, выполнять ранжир при
                    отображении товаров на витрине по тому или иному критерию, структурировать результаты выборки.
                    Менять тип отображения списка товаров.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Параметры и вкладки товара</span>
                </div>
                <div className="result-list-descr">Позволяет при заказе товара делать выбор его параметров: цвет,
                    размер, фасон, модель и т.п.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Ярлык на товарах</span>
                </div>
                <div className="result-list-descr">Назначение товарам ярлыка: новинка, акция, хит и т.п.</div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Корзина и оформление заказа</span>
                </div>
                <div className="result-list-descr">Позволяет сделать онлайн заказ товаров, определяя их количество,
                    время и способы доставки, форму оплаты.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Отзывник на товар</span>
                </div>
                <div className="result-list-descr">Позволяет посетителям писать отзывы на товары. Все отзывы проходят
                    модерацию, прежде чем будут опубликованы на сайте.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Личный кабинет для покупателя</span>
                </div>
                <div className="result-list-descr">Функционал личного кабинета клиента в интернет магазине. Можно
                    просматривать список товаров текущей покупки, историю покупок, постоянные скидки, редактировать
                    контактные данные.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Поиск по магазину</span>
                </div>
                <div className="result-list-descr">Дает возможность пользователю производить поиск информации по
                    товарам, заголовкам и содержанию страниц.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Фотогалерея</span>
                </div>
                <div className="result-list-descr">Позволяет на сайте отображать пользователям набор фотографий в
                    наилучшем виде.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Ротатор слайдов</span>
                </div>
                <div className="result-list-descr">Позволяет визуализировать интерактивный слайдер изображений. Очень
                    удобно для подачи в графическом виде информации об услугах, скидках, акциях, новинках и т.п.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Карта проезда</span>
                </div>
                <div className="result-list-descr">На основе сервиса Google Maps, на сайте будет размещена интерактивная
                    масштабируемая карта подъезда к интернет магазину, пунктам выдачи.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Форма обратной связи</span>
                </div>
                <div className="result-list-descr">Позволяет пользователям отправлять сообщения или запросы по поводу
                    той или иной интересующей их информации связанной с деятельностью интернет-магазина.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Статистика посещений сайта</span>
                </div>
                <div className="result-list-descr">По желанию заказчика на сайт устанавливаем систему статистики и
                    аналитики Google Analytics и рассказываем об основных моментах работы с ней.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/2-eshop_5.jpg" className="mn-image"
                                                       alt="Статистика посещений интернет магазина"
                                                       title="Подключается модуль сбора статистики посещений"/></div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">3. Дополнительно</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Иконки переходов в группы соц. сетей</span>
                </div>
                <div className="result-list-descr">Есть возможность размещать на сайте иконки и виджеты групп социальных
                    сетей (Facebook, Twitter).
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Формирование Sitemap</span>
                </div>
                <div className="result-list-descr">Формирование XML-карты сайта для создания условий более полной
                    индексации страниц сайта поисковыми системами Google, Yandex и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Понятный URL (ЧПУ)</span>
                </div>
                <div className="result-list-descr">Каждый, созданный нами, интернет магазин имеет короткие и понятные
                    внутренние ссылки. Это позволяет людям и поисковым машинам распознавать содержимое страниц по URL.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита админки интернет магазина</span>
                </div>
                <div className="result-list-descr">Доступ к админке интернет-магазина не имеет прямых ссылок со страниц
                    ресурса. Это не позволяет анализаторам кода автоматически обнаруживать ее URL. Также, используются и
                    другие приемы препятствующие определению типа CMS сайта. Более того, сама система аутентификации
                    защищена от систем скоростного подбора паролей.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита и безопасность</span>
                </div>
                <div className="result-list-descr">Все наши сайты используют защиту базы данных от инъекций, защиту от
                    удаленного запуска, защиту от запуска скриптов и другие виды защит.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Кросспостинг</span>
                </div>
                <div className="result-list-descr">Есть возможность публиковать записи из новостной ленты сайта,
                    товарных страниц в аккаунтах Facebook, Twitter и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Оптимизация интернет-магазина</span>
                </div>
                <div className="result-list-descr">Внесение на страницы сайта мета-информации, подбор ключевых слов,
                    оптимизация по ключевым словам.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/2-eshop_4.jpg" className="mn-image"
                                                       alt="Адаптивный дизайн созданного интернет магазина"
                                                       title="Созданный интернет магазин имеет адаптивный дизайн и структуру"/>
                </div>
                <p>&nbsp;</p>
            </div>
        </div>
    )
}

export default Service2;