//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            link2: '/logotypes',
            linkName2: 'Логотипы разработанные в D.Logic',
            brcrPageName: ''
        },
        pageTitle: '',
        pageText: [
            <div>

            </div>
        ],
        logoList: [
            {
                id: 1,
                toUaPage: 'logotip-dlya-programmi-meridian',
                toRuPage: 'meridian',
                logoDescr1: 'Разработка логотипа для программы "Меридиан"',
                logoDescr1s: '(программа для часто путешествующих пасажиров авиакомпании Аэросвит)',
                logoDescr2: ['идея' , 'разработка'],
                logoPhotos: ['logo_6_meridian_m.png']
            },
            {
                id: 2,
                toUaPage: 'logotip-dlya-vistavki-fastfood',
                toRuPage: 'fastfood',
                logoDescr1: 'Разработка логотипа для "Fast Food"',
                logoDescr1s: '(международная выставка быстрого питания)',
                logoDescr2: ['идея' , 'разработка'],
                logoPhotos: ['logo_5_fastfood_m.png']
            },
            {
                id: 3,
                toUaPage: 'logotip-dlya-firmi-madicom',
                toRuPage: 'medicom',
                logoDescr1: 'Разработка логотипа для фирмы "Medicom"',
                logoDescr1s: '',
                logoDescr2: ['идея' , 'разработка'],
                logoPhotos: ['logo_3_medicom_m.png']
            },
            {
                id: 4,
                toUaPage: 'logotip-dlya-fermerskogo-kompleksu-agro-lider-ukraina',
                toRuPage: 'agrolider',
                logoDescr1: 'Разработка логотипа для "Агро-Лидер-Украина"',
                logoDescr1s: '(фермерский животноводческий комплекс)',
                logoDescr2: ['идея' , 'разработка'],
                logoPhotos: ['logo_2_agroliderukraina_m.png']
            },
            {
                id: 5,
                toUaPage: 'logotip-dlya-firmi-roxvision',
                toRuPage: 'roxvision',
                logoDescr1: 'Разработка логотипа для фирмы "Roxvision" (США)',
                logoDescr1s: '',
                logoDescr2: ['идея' , 'разработка'],
                logoPhotos: ['logo_4_roxvision_m.png']
            },
            {
                id: 6,
                toUaPage: 'logotip-dlya-firmi-verteks',
                toRuPage: 'vertex',
                logoDescr1: 'Разработка логотипа для фирмы "Vertex"',
                logoDescr1s: '',
                logoDescr2: ['идея' , 'разработка'],
                logoPhotos: ['logo_1_vertex_m.png']
            },
        ]
    }
}

const logoReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default logoReducer;