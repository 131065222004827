//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            brcrPageName: 'Клиенты агентства D.Logic'
        },
        pageTitle: 'Наши клиенты',
        clientsList: [
            {
                key: 1,
                photo: '1-bayer.png',
                alt: 'Компания Bayer',
                title: 'Фирма Bayer'
            },
            {
                key: 2,
                photo: '2-polirem.png',
                alt: 'Компания Полирем',
                title: 'Фирма Polirem'
            },
            {
                key: 3,
                photo: '3-primus.png',
                alt: 'Компания Примус Украина',
                title: 'Фирма Primus Ukraine'
            },
            {
                key: 4,
                photo: '4-zeppelin-ukraine.png',
                alt: 'Компания Цеппелин Украина',
                title: 'Фирма Zeppelin Ukraine'
            },
            {
                key: 5,
                photo: '5-kvkbud.png',
                alt: 'Компания КВК-Буд',
                title: 'Фирма KVK-Bud'
            },
            {
                key: 6,
                photo: '6-real-estate.png',
                alt: 'Компания Риел Истейт',
                title: 'Фирма Real Estate'
            },
            {
                key: 7,
                photo: '7-mega-line.png',
                alt: 'Компания Мега-Лайн',
                title: 'Фирма Mega-Line'
            },
            {
                key: 8,
                photo: '8-arsenal-centr.png',
                alt: 'Компания Арсенал-Центр',
                title: 'Фирма Arsenal-Center'
            },
            {
                key: 9,
                photo: '9-aerosvit.png',
                alt: 'Компания Аэросвит',
                title: 'Фирма Aerosvit'
            },
            {
                key: 10,
                photo: '10-kiev-okna.png',
                alt: 'Компания Киев-Окна',
                title: 'Фирма Kiev-Okna'
            },
            {
                key: 11,
                photo: '11-hyundai.png',
                alt: 'Компания Хюндай',
                title: 'Фирма Hyundai'
            },
            {
                key: 12,
                photo: '12-datalux.png',
                alt: 'Компания ДатаЛюкс',
                title: 'Фирма DataLux'
            },
            {
                key: 13,
                photo: '13-mebelterminal.png',
                alt: 'Компания Мебельный терминал',
                title: 'Фирма Мебельный терминал'
            },
            {
                key: 14,
                photo: '14-vijar.png',
                alt: 'Компания ВиЯр',
                title: 'Фирма ВиЯр'
            },
            {
                key: 15,
                photo: '15-jashma.png',
                alt: 'Компания Яшма',
                title: 'Фирма Яшма'
            },
            {
                key: 16,
                photo: '16-agroinkom.png',
                alt: 'Компания Агроинком',
                title: 'Фирма Agroinkom'
            },
            {
                key: 17,
                photo: '17-tehnonikol.png',
                alt: 'Компания Технониколь',
                title: 'Фирма Tehnonikol'
            },
            {
                key: 18,
                photo: '18-eximed.png',
                alt: 'Компания Эксимед',
                title: 'Фирма Eximed'
            },
            {
                key: 19,
                photo: '19-santehkomplect.png',
                alt: 'Компания Сантехкомплект',
                title: 'Фирма Сантехкомплект'
            },
            {
                key: 20,
                photo: '20-demi-lune.png',
                alt: 'Компания Деми-Луне',
                title: 'Фирма Demi-Lune'
            },
            {
                key: 21,
                photo: '21-pride.png',
                alt: 'Компания Прайд ЗС',
                title: 'Фирма Pride'
            },
            {
                key: 22,
                photo: '22-reatex.png',
                alt: 'Компания Реатекс',
                title: 'Фирма Reatex'
            },
            {
                key: 23,
                photo: '23-steelfort.png',
                alt: 'Компания Стилфорт',
                title: 'Фирма Steelfort'
            },
            {
                key: 24,
                photo: '24-amd-group.png',
                alt: 'Компания АМД Груп',
                title: 'Фирма AMD-Group'
            },
            {
                key: 25,
                photo: '25-skif.png',
                alt: 'Компания Скиф',
                title: 'Фирма Skif'
            },
            {
                key: 26,
                photo: '26-roxvision.png',
                alt: 'Компания Рокс Вижн',
                title: 'Фирма Rox Vision'
            },
            {
                key: 27,
                photo: '27-m-info.png',
                alt: 'Компания М-Инфо',
                title: 'Фирма M-Info'
            },
            {
                key: 28,
                photo: '28-rudison.png',
                alt: 'Компания Рудисон',
                title: 'Фирма Rudison'
            },
            {
                key: 29,
                photo: '29-eurostyle.png',
                alt: 'Компания ЕвроСтиль',
                title: 'Фирма Eurostyle'
            },
            {
                key: 30,
                photo: '30-dinakor.png',
                alt: 'Компания Динакор',
                title: 'Фирма Dinakor'
            },
            {
                key: 31,
                photo: '31-mirador.png',
                alt: 'Компания Мирадор',
                title: 'Фирма Mirador'
            },
            {
                key: 32,
                photo: '32-seal.png',
                alt: 'Компания Сеал',
                title: 'Фирма Seal'
            },
            {
                key: 33,
                photo: '33-medicom.png',
                alt: 'Компания Медиком ТД',
                title: 'Фирма Medicom'
            },
            {
                key: 34,
                photo: '34-infoservice.png',
                alt: 'Компания Инфосервис',
                title: 'Фирма Infoservice'
            },
            {
                key: 35,
                photo: '35-kmkja.png',
                alt: 'Компания КМКЯ',
                title: 'Фирма КМКЯ'
            },
            {
                key: 36,
                photo: '36-agro-lider-ukraine.png',
                alt: 'Компания Агро-Лидер-Украина',
                title: 'Фирма Агро-Лидер-Украина'
            },
            {
                key: 37,
                photo: '37-vertex.png',
                alt: 'Компания Вертекс',
                title: 'Фирма Vertex'
            },
            {
                key: 38,
                photo: '38-uvs.png',
                alt: 'Компания ЮВС',
                title: 'Фирма UVS'
            },
            {
                key: 39,
                photo: '39-muson.png',
                alt: 'Компания Мусон',
                title: 'Фирма Muson'
            },
            {
                key: 40,
                photo: '40-amaranta.png',
                alt: 'Компания Амаранта',
                title: 'Фирма Amaranta'
            },
            {
                key: 41,
                photo: '41-ga-music.png',
                alt: 'Компания GA-Music',
                title: 'Фирма GA-Music'
            }
        ],
        pageText: [
            <div>

            </div>
        ]
    }
}

const clientsReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default clientsReducer;