import React from 'react';

const Slogan = (props) => {
    return (
        <div className="mn-container main-top-line-3-inner">
            <h1>{props.firstRow}</h1>
            <span>{props.secondRow}</span>
        </div>
    );
}

export default Slogan;