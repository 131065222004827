import React from 'react';

const Service12 = (props) => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Разработка нового дизайна сайта</b> нужно, как правило, вследствие переосмысления целей работы
                    сайта, его функционала, смена имиджа и демонстрация того, что компания старается идти «в ногу со
                    временем». Бывает, что смена парадигмы деятельности фирмы также может приводить к смене дизайна
                    работающего сайта и пересмотру его функционала.</p>
                <p>Это с одной стороны. А с другой стороны - никто не отменял появление у пользователей новых
                    предпочтений касательно удобства пользования сайтами и их функционала. На дворе уже давно XXI век.
                    Как ни крути, а тренды постоянно меняются и скорость этих изменений растет. То, что еще вчера было
                    модным и красивым, сегодня может быть уже не интересным.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/11-redesign_1.jpg" className="mn-image"
                                                alt="Редизайн сайта" title="Редизайн сайта от студии D.Logic"/></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle sub-dop">Причины, которые часто приводят к решению поменять старый
                    дизайн на новый.</h2>
                <p>Причин, которые приводят к необходимости сделать новый дизайн интернет ресурса много, и все назвать
                    их не представляется возможным. Но основные и часто встречающиеся причины можно перечислить, сведя
                    их к небольшому списку. А именно:</p>
                <div className="mn-padding-16"/>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Внешний вид сайта морально устарел, либо банально надоел</span>
                    </div>
                    <div className="result-list-descr">Сейчас любой интернет ресурс не может игнорировать современные
                        тенденции в визуальном оформлении. Чтобы продвинуть себя в интернете и быть узнаваемым, надо
                        радовать своих посетителей новым, красивым и удобным дизайном. Сайт должен быть удобным и иметь
                        хорошее визуальное оформление, давать возможность своему посетителю быстро находить то, что он
                        ищет.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Функционал сайта устарел или не соответствует современным трендам</span>
                    </div>
                    <div className="result-list-descr">Посетитель сайта, в идеале, должен иметь возможность находить
                        нужную ему информацию (если, конечно же, она имеется на ресурсе) в один-два клика. Не более. И
                        каким бы огромным и разветвленным не был бы ресурс, этого неписаного правила желательно
                        придерживаться.
                    </div>
                    <div>&nbsp;</div>
                    <div className="mn-center"><img src="/assets/photos/11-redesign_2.jpg" className="mn-image"
                                                    alt="Плохой дизайн нужно исправлять"
                                                    title="Например, для такого сайта лучше делать редизайн, так как он явно устарел"/>
                    </div>
                    <p>&nbsp;</p>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Изменилась сфера деятельности фирмы, организации</span>
                    </div>
                    <div className="result-list-descr">Очевидно, что как только меняется ваш бизнес или появляются новые
                        направления деятельности, это сразу же должно отразиться на структуре вашего сайта, его
                        функционале. Тут работает такое правило, если у вас есть товар или услуга, но на сайте о нем
                        ничего нет – это означает только одно, что у вас нет такого товара или услуги.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Сайт сдал позиции у поисковых систем</span>
                    </div>
                    <div className="result-list-descr">Чтобы ваш сайт не сдавал свои позиции в поисковом рейтинге, надо
                        периодически обновлять его структуру, менять дизайн, использовать новые тенденции в визуальном
                        оформлении. Без редизайна сайт будет устаревать, не будет выполнять свои основные цели и задачи,
                        т.е. приносить пользу. Меняются технологии, выходят новые версии языков программирования,
                        появляются новые тренды в веб-дизайне. Все это приводит к тому, что сайтам требуется доработка.
                    </div>
                    <div>&nbsp;</div>
                    <div className="mn-center"><img src="/assets/photos/11-redesign_3.jpg" className="mn-image"
                                                    alt="Сайт сдал позиции - значит, пора делать редизайн"
                                                    title="Технологии меняются и сайты устаревают. Если сайт сдал позиции в поисковой выдаче - его пора менять"/>
                    </div>
                    <p>&nbsp;</p>
                </div>

                <p>Чтобы побеждать в этой гонке, компаниям нужно учитывать тренды и, обновлять свои сайты чаще
                    конкурентов, быть на шаг впереди. Мы как профессионалы своего дела занимаемся редизайном сайтов с
                    2001 года. И накопили в этом деле огромный опыт. Свой опыт мы готовы использовать для ваших
                    задач. <b>Звоните к нам на (099)370-35-37</b>, г. Киев. Ответим на все ваши вопросы.</p>
            </div>
        </div>
    )
}

export default Service12;