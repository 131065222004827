//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainServicesListInfo: {
        title: 'Наши самые популярные услуги',
        services: [
            {
                id: 1,
                serviceUrl: '/service/sozdanie-korporativnyh-saitov',
                serviceIconUrl: '/assets/pics/main_icon_8.png',
                serviceIconAlt: 'Корпоративный сайт',
                serviceIconTitle: 'Корпоративный сайт - D.Logic',
                serviceTitle: 'Создание корпоративного сайта',
                serviceDescription: 'Реализация сложных, структурированных проектов, отвечающих духу и цели крупных организаций'
            },
            {
                id: 2,
                serviceUrl: '/service/razrabotka-internet-magazinov',
                serviceIconUrl: '/assets/pics/main_icon_4.png',
                serviceIconAlt: 'eCommerce решения',
                serviceIconTitle: 'Интернет магазин - D.Logic',
                serviceTitle: 'Разработка интернет-магазина',
                serviceDescription: 'Это всегда сложный многоуровневый процесс, который учитывает многие факторы современных требований к e-commerce'
            },
            {
                id: 3,
                serviceUrl: '/service/sozdanie-saita-vizitki',
                serviceIconUrl: '/assets/pics/main_icon_15.png',
                serviceIconAlt: 'Сайт визитка',
                serviceIconTitle: 'Сайт визитка - D.Logic',
                serviceTitle: 'Создание сайта визитки',
                serviceDescription: 'Уникальная возможность начать свой бизнес уже сейчас'
            },
            {
                id: 4,
                serviceUrl: '/service/razrabotka-landing-page',
                serviceIconUrl: '/assets/pics/main_icon_13.png',
                serviceIconAlt: 'Landing Page',
                serviceIconTitle: 'Landing Page от D.Logic',
                serviceTitle: 'Разработка Landing Page',
                serviceDescription: 'Самый быстрый способ создать возможность для увеличения конвертации посетителя сайта в покупателя'
            },
            {
                id: 5,
                serviceUrl: '/service/sozdanie-mobilnoi-versii-saita',
                serviceIconUrl: '/assets/pics/main_icon_6.png',
                serviceIconAlt: 'Мобильная версия сайта',
                serviceIconTitle: 'Мобильная версия сайта от D.Logic',
                serviceTitle: 'Создание мобильной версии сайта',
                serviceDescription: 'Учет того факта, что большинство посетителей сайтов используют мобильные гаджеты для просмотра веб-страниц'
            },
            {
                id: 6,
                serviceUrl: '/service/razrabotka-onlain-servisov',
                serviceIconUrl: '/assets/pics/main_icon_3.png',
                serviceIconAlt: 'Онлайн сарвис',
                serviceIconTitle: 'Онлайн сарвис - Ди Лоджик',
                serviceTitle: 'Разработка онлайн сервисов',
                serviceDescription: 'Когда нужна площадка для удобного взаимодействия с сотрудниками, клиентами и партнерами'
            },
            {
                id: 7,
                serviceUrl: '/service/kompleksnaya-podderzshka-saitov',
                serviceIconUrl: '/assets/pics/main_icon_9.png',
                serviceIconAlt: 'Поддержка ресурса',
                serviceIconTitle: 'Поддержка ресурса - Ди Лоджик',
                serviceTitle: 'Поддержка сайта',
                serviceDescription: 'Окажем всю необходимую помощь в наполнении сайта и его бесперебойной работы'
            },
            {
                id: 8,
                serviceUrl: '/service/poiskovoe-prodvizenie-saita',
                serviceIconUrl: '/assets/pics/main_icon_14.png',
                serviceIconAlt: 'Поисковое продвижение',
                serviceIconTitle: 'Поисковое SEO продвижение сайта',
                serviceTitle: 'Поисковое продвижение сайтов (SEO)',
                serviceDescription: 'Самая эффективная долгосрочная реклама в интернете'
            },
            {
                id: 9,
                serviceUrl: '/service/internet-reklama-v-google-facebook',
                serviceIconUrl: '/assets/pics/main_icon_12.png',
                serviceIconAlt: 'Интернет реклама',
                serviceIconTitle: 'Интернет реклама с D.Logic',
                serviceTitle: 'Интернет реклама в Google, Facebook',
                serviceDescription: 'Самая эффективная целевая реклама в интернете в данный момент'
            },
            {
                id: 10,
                serviceUrl: '/service/podbor-domena-hosting',
                serviceIconUrl: '/assets/pics/main_icon_11.png',
                serviceIconAlt: 'Домен, хостинг',
                serviceIconTitle: 'Домен, хостинг от D.Logic',
                serviceTitle: 'Подбор домена, хостинг',
                serviceDescription: 'Только правильное доменное имя всегда поможет удачно вести дела в интернете'
            }
        ]
    }
}

const landingMainServicesListReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingMainServicesListReducer;