//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            brcrPageName: 'Список наших услуг'
        },
        pageTitle: 'Наши услуги',
        pageText: [
            {id: 1, sectionName: 'Разработка', services: [
                    {id: 1, link: '/service/sozdanie-korporativnyh-saitov', name: 'Создание корпоративного сайта'},
                    {id: 2, link: '/service/razrabotka-internet-magazinov', name: 'Разработка интернет-магазина'},
                    {id: 3, link: '/service/razrabotka-onlain-servisov', name: 'Разработка онлайн сервисов'},
                    {id: 4, link: '/service/sozdanie-saita-vizitki', name: 'Создание сайта визитки'},
                    {id: 5, link: '/service/razrabotka-landing-page', name: 'Разработка Landing Page'},
                    {id: 6, link: '/service/razrabotka-saita-kataloga', name: 'Разработка сайта каталога'},
                    {id: 7, link: '/service/sozdanie-mobilnoi-versii-saita', name: 'Создание мобильной версии сайта'},
                    {id: 8, link: '/service/proektirovanie-interfeisov', name: 'Проектирование интерфейсов'}
                ]
            },
            {id: 2, sectionName: 'Поддержка и продвижение', services: [
                    {id: 1, link: '/service/kompleksnaya-podderzshka-saitov', name: 'Поддержка сайта'},
                    {id: 2, link: '/service/podbor-domena-hosting', name: 'Подбор домена, хостинг'},
                    {id: 3, link: '/service/internet-reklama-v-google-facebook', name: 'Интернет реклама (Google, Facebook)'},
                    {id: 4, link: '/service/kompleksnyi-audit-saita', name: 'Комплексный аудит сайта'},
                    {id: 5, link: '/service/poiskovoe-prodvizenie-saita', name: 'Поисковое продвижения сайта'}
                ]
            },
            {id: 3, sectionName: 'Дизайн, стиль', services: [
                    {id: 1, link: '/service/razrabotka-dizaina-saita', name: 'Разработка дизайна сайта'},
                    {id: 2, link: '/service/razrabotka-dizaina-internet-magazina', name: 'Разработка дизайна интернет магазина'},
                    {id: 3, link: '/service/redizain-saita', name: 'Редизайн сайта'},
                    {id: 4, link: '/service/razrabotka-logotipa', name: 'Разработка логотипа'},
                    {id: 5, link: '/service/razrabotka-korporativnogo-stilya', name: 'Разработка корпоративного стиля'},
                    {id: 6, link: '/service/sozdanie-brendbuka', name: 'Создание брендбука'}
                ]
            }
        ]
    }
}

const servicesReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default servicesReducer;