import React from 'react';
import {NavLink} from "react-router-dom";

const LogoAndTopMenuBar = (props) => {

    let topMenuBar = props.topMenuBar.map(item => {
        return (
            <li><NavLink to={item.link}>{item.nameLink}</NavLink></li>
        )
    })

    return (
        <div className="mn-container">
            <div className="mn-container mn-left">
                <img src={props.logoUrl}
                     className="mn-image main-top-line-2-img"
                     alt={props.logoAlt}
                     title={props.logoTitle}/>
            </div>
            <div className="mn-container mn-right menu-top">
                <ul>
                    {topMenuBar}
                </ul>
            </div>
            <div className="mn-clear"/>
        </div>
    );
}

export default LogoAndTopMenuBar;