import React from 'react';

const getYear = () => {
    let today = new Date();
    return today.getFullYear();
}


const CopyRights = (props) => {

    return (
        <div className="main-12">
            <div className="left-right-flex-pad">
                <div className="mn-container mn-padding-24">
                    <b>{props.firmName}</b><br/>
                    © {props.startYear}-{getYear()}. {props.copyRightText}
                </div>
            </div>
        </div>
    );
}

export default CopyRights;