import React from 'react';
import {connect} from "react-redux";
import FooterDlogicAndSocials from "./FooterDlogicAndSocials";
import FooterServices from "./FooterServices";
import FooterStudios from "./FooterStudios";
import FooterContacts from "./FooterContacts";

class Footer extends React.Component {

    render() {
        return (
            <footer className="main-11">
                <div className="left-right-flex-pad">
                    <div className="mn-container mn-padding-16">
                        <FooterDlogicAndSocials {...this.props.landingFooter.footerDlogicAndSocialsIcons} />
                        <FooterServices {...this.props.landingFooter.footerServices} />
                        <FooterStudios {...this.props.landingFooter.footerStudios} />
                        <FooterContacts {...this.props.landingFooter.footerContacts} />
                    </div>
                </div>
            </footer>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        landingFooter: state.landingFooter
    }
}

export default connect(mapStateToProps)(Footer);
