import React from 'react';

const Service1 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Создание корпоративного сайта</b> – это самый первый и необходимый шаг для любой организации,
                    чтобы динамически развиваться, задействуя хотя бы минимальные возможности современных IT технологий.
                </p>

                <p>Качественно сделанный корпоративный сайт не только информирует посетителей обо всех аспектах
                    деятельности компании, но и повышает доверие посетителей к владельцам такого ресурса. Информативный,
                    продуманный и удобный корпоративный сайт актуален всегда.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/1-corpsite_1.jpg" className="mn-image"
                                                alt="Создается адаптивный корпоративный сайт"
                                                title="Создание польностью адаптивного корпоративного сайта"/></div>
                <p>&nbsp;</p>

                <h2 className="service-subtitle">Как мы подходим к работе над корпоративным сайтом.</h2>
                <p>Разработка корпоративного сайта начинается с нашего знакомства с бизнесом заказчика, анализа его
                    деятельности для выявления ключевых особенностей, специфики рынка и понимания всех аспектов работы
                    компании. Не каждый имеет опыт такого подхода в работе. Именно поэтому в таком вопросе, как создание
                    корпоративного сайта, всегда лучше обращаться к профессионалам, имеющим многолетний (без
                    преувеличения!) опыт создания таких сайтов. И такая команда давно работает в компании D.logic.</p>
                <div className="mn-center yellow-ramka">
                    <h4>Более {/*<?php echo date("Y")-2001; ?>*/} лет на рынке разработки сайтов</h4>
                </div>
                <p>Далее - составление технического задания, согласно которому будет проходить весь процесс разработки
                    корпоративного веб сайта. После его утверждения обеими сторонами начинается собственно сам этап
                    разработки.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/1-corpsite_2.jpg" className="mn-image"
                                                       style={{borderRadius: '11px'}}
                                                       alt="Структура страниц корпоративного сайта"
                                                       title="Составление общей структуры страниц для будущего корпоративного сайта"/>
                </div>
                <p>&nbsp;</p>

                <p>Сайт обязательно снабжается системой управления контентом или CMS (Content Management System) для
                    того, чтобы менеджеры компании заказчика могли самостоятельно менять на нем всю текстовую
                    информацию, цены, контактные данные, а также фото- и видеоматериалы. </p>

                <p><b>Время разработки корпоративного сайта:</b> 5 - 10 недель.</p>
            </div>

            <div className="service-title-2">Что получает заказчик в результате?</div>
            <h2 className="service-subtitle">1. Основные моменты</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Уникальный дизайн</span>
                </div>
                <div className="result-list-descr">Каждый корпоративный сайт получает Авторский дизайн от нашего
                    творческого коллектива в соответствии с пожеланиями заказчика. Для каждого проекта мы готовим до 2
                    (двух) вариантов дизайна для главной и внутренней страниц и предоставляем их клиенту для выбора.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Начальное наполнение сайта</span>
                </div>
                <div className="result-list-descr">Осуществляем первоначальное наполнение сайта всей необходимой
                    информацией по согласованию с заказчиком.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Адаптивность и кроссбраузерность</span>
                </div>
                <div className="result-list-descr">Все наши сайты имеют адаптивный дизайн, т.е. корректно отображаются
                    на экранах любых размеров – от маленьких на смартфонах и планшетах, до больших у ноутбуков и
                    мониторов настольных систем. Более того, код сайта позволяет ему корректно отображаться во всех
                    популярных браузерах настольных и мобильных систем - Chrome, Firefox, Opera, Internet Explorer,
                    Safari и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Система управления контентом (CMS)</span>
                </div>
                <div className="result-list-descr">Корпоративный сайт снабжается удобной Системой управления сайтом,
                    чтобы пользователь мог легко самостоятельно обновлять любую информацию: цены, тексты, фото- и
                    видеоматериалы.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Размещение проекта на веб-сервере</span>
                </div>
                <div className="result-list-descr">После выполнения всех работ по созданию корпоративного сайта, наши
                    специалисты размещают все итоговые материалы на веб-сервере и настраивают его работу.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Обучение работе с админкой сайта</span>
                </div>
                <div className="result-list-descr">После размещения проекта на веб-сервере, мы проводим обучение
                    заказчика работе с сайтом. Процесс обучение обычно длится 1-2 часа и хорошо воспринимается
                    неподготовленным пользователем.
                </div>
            </div>
            <div>&nbsp;</div>
            <div className="mn-center"><img src="/assets/photos/1-corpsite_4.jpg" className="mn-image"
                                            alt="Подробная структура сайта"
                                            title="Разработка полной структуры создаваемого корпоративного сайта"/></div>
            <p>&nbsp;</p>

            <h2 className="service-subtitle">2. Функционал корпоративного сайта</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Лента новостей</span>
                </div>
                <div className="result-list-descr">Данный функционал позволяет отображать новости компании на страницах
                    сайта. Краткое содержание новостей может отображаться на других страница ресурса.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Статистика посещений сайта</span>
                </div>
                <div className="result-list-descr">По желанию заказчика на сайт устанавливаем систему сбора статистики и
                    аналитики Google Analytics и рассказываем об основных моментах работы с ней.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Форма обратной связи</span>
                </div>
                <div className="result-list-descr">Позволяет пользователям отправлять сообщения или запросы по поводу
                    той или иной интересующей их информации.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Поиск по сайту</span>
                </div>
                <div className="result-list-descr">Дает возможность пользователю производить поиск информации в
                    заголовках или содержанию страниц.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Фотогалерея</span>
                </div>
                <div className="result-list-descr">Позволяет на сайте отображать пользователям набор фотографий в
                    наилучшем виде.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Ротатор слайдов</span>
                </div>
                <div className="result-list-descr">Позволяет визуализировать интерактивный слайдер изображений. Очень
                    удобно для подачи в графическом виде информации об услугах, направлениях деятельности, товарах и
                    акциях.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Карта проезда</span>
                </div>
                <div className="result-list-descr">На основе сервиса Google Maps, на сайте будет размещена интерактивная
                    масштабируемая карта подъезда к офису.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Отзывник</span>
                </div>
                <div className="result-list-descr">Позволяет посетителям писать отзывы на сайте. Все отзывы проходят
                    модерацию, прежде чем будут опубликованы на сайте.
                </div>
            </div>
            <div>&nbsp;</div>
            <div className="mn-center"><img src="/assets/photos/1-corpsite_3.jpg" className="mn-image"
                                            alt="Программирование структуры и настройка будущего сайта"
                                            title="Программирование структуры и настройка созданного корпоративного сайта"/>
            </div>
            <p>&nbsp;</p>

            <h2 className="service-subtitle">3. Дополнительно</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Иконки переходов в группы соц. сетей</span>
                </div>
                <div className="result-list-descr">Есть возможность размещать на сайте иконки и виджеты групп социальных
                    сетей (Facebook, Twitter).
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Формирование Sitemap</span>
                </div>
                <div className="result-list-descr">Формирование XML-карты сайта для создания условий более полной
                    индексации страниц сайта поисковыми системами Google, Yandex и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Понятный URL (ЧПУ)</span>
                </div>
                <div className="result-list-descr">Сайт имеет короткие и понятные внутренние ссылки. Это позволяет людям
                    и поисковым машинам распознавать содержимое страниц по URL.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита админки сайта</span>
                </div>
                <div className="result-list-descr">Доступ к админке сайта не имеет прямых ссылок со страниц сайта. Это
                    не позволяет автоматически обнаруживать ее URL анализаторами кода. Также, используются и другие
                    приемы препятствующие определению типа CMS сайта. Более того, сама система аутентификации защищена
                    от систем скоростного подбора паролей.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита и безопасность</span>
                </div>
                <div className="result-list-descr">Все наши сайты используют защиту базы данных от инъекций, защиту от
                    удаленного запуска, защиту от запуска скриптов и другие виды защит.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Кросспостинг</span>
                </div>
                <div className="result-list-descr">Есть возможность публиковать записи из новостной ленты сайта в
                    аккаунтах Facebook, Twitter и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Оптимизация сайта</span>
                </div>
                <div className="result-list-descr">Внесение на сайт мета-информации, подбор ключевых слов для сайта,
                    оптимизация по ключевым словам.
                </div>

            </div>
        </div>
    )
}

export default Service1;