import React from 'react';

const FooterContacts = (props) => {

    let footerContacts = props.contactList.map(contact => {
        return (
            <div className="main-11-text-right">
                <div className="bottom-menu-icon"><img src={contact.icon} alt=""/></div>
                <div>
                    <div>{contact.contact1}</div>
                    <div>{contact.contact2}</div>
                </div>
                <div className="mn-clear"/>
            </div>
        )
    })

    return (
        <div className="mn-container mn-col l3 m6 mn-padding-32">
            <div className="main-11-title">{props.title}</div>
            {footerContacts}
        </div>
    );
}

export default FooterContacts;