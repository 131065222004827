//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    timeLineInfo: {
        title: 'Этапы разработки сайта у компании D.Logic',
        stages: [
            {id: 1, stageTitle: 'Этап 1', stageName: 'Составление технического задания.', stageDescription: 'На этом этапе мы всесторонне изучаем ту часть бизнеса заказчика, которая будет отображена на сайте. И вместе с ними составляем техническое задание.'},
            {id: 2, stageTitle: 'Этап 2', stageName: 'Создание прототипа проекта.', stageDescription: 'Выполняем прототипирование. Одним словом формируем матрицу сайта по функционалу. Т.е., что, где и как все будет работать.'},
            {id: 3, stageTitle: 'Этап 3', stageName: 'Разработка дизайна сайта.', stageDescription: 'После утверждения прототипа сайта заказчиком приступаем к разработке дизайна сайта. Тут учитывается всё: пожелания, логотип, корпоративный стиль, брэндбук (если есть) и т.д.'},
            {id: 4, stageTitle: 'Этап 4', stageName: 'Программирование и сборка сайта.', stageDescription: 'Как только заказчик утверждает разработанный дизайн, начинается собственно сборка проекта: пишутся backend, frontend, настраивается и подключается база данных, настраивается и подключается админка сайта. Все это выполняется на тестовом веб сервере. И с определенного момента, за ходом работ заказчик может следить онлайн.'},
            {id: 5, stageTitle: 'Этап 5', stageName: 'Тестирование сайта.', stageDescription: 'На данном этапе проект всесторонне тестируется на наличие ошибок, которые были пропущены на этапе сборки проекта. Также полностью проверяется на работоспособность весь функционал, который перечислен в ТЗ.'},
            {id: 6, stageTitle: 'Этап 6', stageName: 'Разворачивание на веб-сервере.', stageDescription: 'Всесторонне протестированный готовый сайт загружается на веб-сервер и выполняются все необходимые настройки для его бесперебойной работы. При необходимости проводится обучение представителя компании работе с сайтом.'}
        ]
    }
}

const landingTimeLineReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingTimeLineReducer;