import React from 'react';
import {NavLink} from "react-router-dom";

const TopMenuBarForMobileOverlayMyNav = (props) => {

    let closeNav = () => {
        document.querySelector('#myNav').style.height = '0'
    }

    let links = props.topMobileMenuInfo.links.map(item => {
        return (
            <NavLink to={item.url}>{item.linkName}</NavLink>
        )
    })

    return (
        <div id="myNav" className="overlay">
            <span className="closebtn" onClick={closeNav}>
                <i className="fas fa-times"/>
            </span>
            <div className="dd"><NavLink to={props.languageLinks.toUaPage}>UA</NavLink> | <NavLink to={props.languageLinks.toRuPage}
                                                                          className="mn-text-white">RU</NavLink>
            </div>
            <div className="overlay-content">
                {links}
            </div>
        </div>
    )
}

export default TopMenuBarForMobileOverlayMyNav;