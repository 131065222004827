import React from 'react';

const TextBeforeOurWorks = (props) => {
    return (
        <div className="mn-container">
            <div className="dop-1 main-6-title"><span>{props.title}</span></div>
            <div className="dop-1 main-txt">{props.textBody}</div>
        </div>
    );
}

export default TextBeforeOurWorks;