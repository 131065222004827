import React from 'react';

const Service18 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <h2 className="service-subtitle sub-dop">Создание корпоративного стиля компании</h2>
                <p><b>Создание корпоративного стиля</b> – очень важный шаг для любой компании на ее пути к успеху</p>
                <p>Любой основатель бизнеса или глава компании понимает, что когда стремишься занять лидирующие позиции
                    в своем секторе рынка, ты неизбежно сталкиваешься с жесточайшей конкуренцией. И чтобы устоять в
                    борьбе с конкурентами, помимо других действий, нужно разработать и внедрить корпоративный стиль.
                    Корпоративный стиль подчеркивает серьезность и основательность компании. А значит, повышает уровень
                    доверия к бренду, к компании в целом. Увеличивает лояльность клиентов. Что, в свою очередь, приводит
                    не столько к стабильным продажам, как к их увеличению.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/18-corpstyle_1.jpg" className="mn-image"
                                                       alt="Разработка фирменного стиля"
                                                       title="Создание фирменного стиля в D.Logic"/></div>
                <div className="mn-padding-8">&nbsp;</div>

                <p>Корпоративный, фирменный стиль, в идеале, должен быть повсюду. Все в компании должно
                    просто &nbsp;дышать&nbsp; корпоративным стилем. В идеале, он должен
                    буквально &nbsp;пронизывать&nbsp; как работников компании, так и потребителей ее товаров и
                    услуг.</p>
                <p>Многочисленные исследования причин и психологии потребления обнаружили такую закономерность –
                    современный спрос на товары и услуги основывается не на первичных потребностях человека, и это факт,
                    а на социальных и на само идентификационных. Именно поэтому, предельно дешевый товар, благодаря
                    бренду может увеличить свою стоимость до внушительных сумм.</p>
                <p>Из чего же состоит бренд, лейбл? А состоит он, как мы уже знаем, главным образом из визуальной и
                    информационной составляющих. Корпоративный стиль – это как бы код, обозначающий определенные
                    свойства, определенную ценность, определенный имидж и даже статус.</p>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/18-corpstyle_2.jpg" className="mn-image"
                                                alt="Многообразие элементов корпоративного стиля"
                                                title="Создание фирменного стиля объединяет в себе много разных элементов"/>
                </div>

                <h2 className="service-subtitle sub-dop">Функции фирменного стиля</h2>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>имиджевая</span>
                    </div>
                    <div className="result-list-descr">Эта функция помогает создать в сознании целевой аудитории
                        соответствующий положительный имидж компании, ее бренда.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>идентифицирующая</span>
                    </div>
                    <div className="result-list-descr">Потребитель продукции или услуг компании моментально узнает
                        бренд, если увидел хоть один элемент корпоративного или фирменного стиля этого бренда. Другими
                        словами, если человек запомнил бренд, он его узнает даже по одному элементу на любом носителе
                        или товаре.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>рекламная</span>
                    </div>
                    <div className="result-list-descr">Фирменный стиль, попадая поле зрения человека, напоминает о
                        существовании данного бренда, как бы рекламирует его, вызывая при этом положительные (или
                        негативные) воспоминания.
                    </div>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>дифференцирующая</span>
                    </div>
                    <div className="result-list-descr">По фирменному стилю мы отличаем один бренд от похожего на него
                        бренда. Эта функция позволяет бренду выделиться из множества других.
                    </div>
                </div>

                <div className="mn-panel mn-blue mn-card-4 mn-center mn-padding-16 inner-panel-1">
                    <b>(099)370-35-37</b><br/> Звоните. Ответим на все ваши вопросы.</div>

                <h2 className="service-subtitle sub-dop">Элементы и носители корпоративного стиля</h2>
                <div className="corpstyle-0">
                    <div className="mn-col l6 m6 corpstyle-l">
                        <div className="corpstyle-1">
                            <b>1. Основные элементы:</b>
                            <ul>
                                <li>логотип</li>
                                <li>слоган</li>
                                <li>палитра фирменных цветов</li>
                                <li>набор фирменных шрифтов</li>
                                <li>дизайн рекламного блока</li>
                            </ul>
                        </div>
                    </div>
                    <div className="mn-col l6 m6 corpstyle-r">
                        <div className="corpstyle-2">
                            <b>2. Деловая документация:</b>
                            <ul>
                                <li>фирменный бланк</li>
                                <li>визитная карточка</li>
                                <li>конверты (разных форматов)</li>
                                <li>папка деловая</li>
                                <li>блокнот</li>
                            </ul>
                        </div>
                    </div>
                    <div className="mn-clear"/>

                    <div className="mn-col l6 m6 corpstyle-l">
                        <div className="corpstyle-3">
                            <b>3. Информационная и рекламная продукция:</b>
                            <ul>
                                <li>флаер</li>
                                <li>листовка</li>
                                <li>буклет</li>
                                <li>проспект</li>
                                <li>брошюра</li>
                                <li>каталог</li>
                                <li>плакат</li>
                                <li>прочее</li>
                            </ul>
                        </div>
                    </div>
                    <div className="mn-col l6 m6 corpstyle-r">
                        <div className="corpstyle-4">
                            <b>4. Сувениры и упаковка</b>
                            <p><b>5. Выставочная продукция</b></p>
                            <p><b>6. А также:</b></p>
                            <ul>
                                <li>корпоративный сайт</li>
                                <li>фирменная одежда</li>
                                <li>фирменный автотранспорт</li>
                                <li>оформление офисного здания</li>
                            </ul>
                        </div>
                    </div>
                    <div className="mn-clear"/>
                </div>
                <div>&nbsp;</div>
                <div className="mn-center"><img src="/assets/photos/18-corpstyle_5.jpg" className="mn-image"
                                                alt="Некоторые элементы корпоративного стиля"
                                                title="Элементы фирменного стиля"/></div>

                <h2 className="service-subtitle sub-dop">Почему так важно разработать эффективный фирменный стиль.</h2>
                <p>Это важно потому, что фирменный (корпоративный) стиль:</p>
                <ul>
                    <li>повышает узнаваемость бренда и компании в целом</li>
                    <li>улучшает имидж</li>
                    <li>повышает доверие к выпускаемой продукции, услугам</li>
                    <li>заказать создание корпоративного стиля – сформировать &nbsp;дух и среду&nbsp;, в котором будет
                        находиться потребитель, партнеры
                    </li>
                    <li>формирует чувство доверия партнеров по бизнесу</li>
                    <li>формирует единую философию жизни компании во всех ее аспектах.</li>
                </ul>
            </div>
        </div>
    )
}

export default Service18;