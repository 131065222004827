import React from 'react';
import {connect} from "react-redux";
import RuInnerPageTopPhones from "./RuInnerPageTopPhones";
import RuInnerPageTopLogoMenu from "./RuInnerPageTopLogoMenu";
import RuInnerPageScrollingTopLogoMenu from "./RuInnerPageScrollingTopLogoMenu";

class RuInnerPageHeaderContainer extends React.Component {
    render() {
        return (
            <header className="mn-hide-medium mn-hide-small inner-top-photo">
                {/*<header className="mn-hide-medium mn-hide-small parallax-window inner-top-photo"
                        data-parallax="scroll"
                        data-image-src={topPhoto}>*/}
                <RuInnerPageTopPhones {...this.props.topPhonesLanguagesLogoMenu.innerPageTopPhonesInfo}
                                      languageLinks={this.props.languageLinks} />
                <RuInnerPageTopLogoMenu {...this.props.topPhonesLanguagesLogoMenu.innerPageTopLogoAndMenuInfo} />
                <RuInnerPageScrollingTopLogoMenu {...this.props.topPhonesLanguagesLogoMenu.innerPageTopLogoAndMenuInfo} />
            </header>
        )
    }
}

let mapStateToProps = (state) => {
    return {
        topPhonesLanguagesLogoMenu: state.innerPageTopPhonesLogoMenu
    }
}

export default connect(mapStateToProps)(RuInnerPageHeaderContainer);