import React from 'react';
import Footer from "../Common/Footer/Footer";
import CopyRightsContainer from "../Common/CopyRights/CopyRightsContainer";
import TopMenuBarForMobileContainer from "../Landing/TopMenuBarForMobile/TopMenuBarForMobileContainer";
import RuInnerPageHeaderContainer from "../Common/RuInnerPageHeaderContainer";
import MainContainer from "./MainContainer";

const Company = () => {

    return (
        <div>
            <RuInnerPageHeaderContainer languageLinks={{toUaPage: '/ua/company', toRuPage: '/company'}}/>
            <TopMenuBarForMobileContainer/>
            <MainContainer/>
            <Footer/>
            <CopyRightsContainer/>
        </div>
    );
}

export default Company;