import React from 'react';
import {connect} from "react-redux";
import SecondParallax from "./SecondParallax";

class SecondParallaxContainer extends React.Component {
    render() {
        return (
            <div>
                <SecondParallax {...this.props.secondParallax.secondParallaxInfo} />
            </div>
        );
    }

}

let mapStateToProps = (state) => {
    return {
        secondParallax: state.landingSecondParallax
    }
}

export default connect(mapStateToProps)(SecondParallaxContainer);