import React from 'react';
import MainBrcr from "./MainBrcr";
import {NavLink} from "react-router-dom";

const Main = (props) => {

    let sites = props.siteList.map(item => {
        return (
            <div className="mn-padding-32">{/*target="_blank"*/}
                <NavLink to={`/site/${item.url}`}><img
                    src={`/assets/pics/site_list/${item.photo_preview}`} className="mn-image"
                    alt={item.alt}
                    title={item.title}/></NavLink>
            </div>
        )
    })

    return (
        <div>
            <div className="inner-gap"/>
            <div className="mn-container">
                <MainBrcr brcr={props.brcr} />
            </div>
            <div className="mn-container mn-padding-16">
                <div className="mn-center portfolio-top-gap">
                    <div className="mn-bar">
                        <NavLink to={props.buttonActive.link}
                           className="mn-button mn-blue-grey mn-border-blue-grey mn-hover-blue-gray portfolio">{props.buttonActive.buttonName}</NavLink>&nbsp;
                        <NavLink to={props.buttonUnActive.link}
                           className="mn-button mn-light-grey mn-border mn-hover-border-blue-gray mn-hover-blue-gray portfolio">{props.buttonUnActive.buttonName}</NavLink>
                    </div>
                </div>
                <div className="inner-info"><h1 className="mn-center">{props.pageTitle} - <span className="mn-text-purple">{props.pageTitlePink}</span></h1></div>

                <div className="site-list-item">
                    <div className="mn-padding-32">
                        {sites}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Main;