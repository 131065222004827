import React from 'react';

const Service14 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Комплексный аудит сайтов</b> – комплекс мер по выявлению всех ошибок, которые мешают веб ресурсу
                    продвинуться на топовые позиции в результатах поиска. Комплексный аудит включает в себя технический
                    аудит сайта, поисковый аудит, контентный анализ, аудит юзабилити. Анализируется структура сайта,
                    метатеги, ответы сервера, дубликаты страниц, наличие карт сайта и изображений, наличие файла
                    robots.txt и многое чего еще.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/14-audit_1.jpg" className="mn-image"
                                                       style={{borderRadius: '10px'}} alt="SEO-аудит сайта"
                                                       title="Поисковый SEO аудит сайтов - агентство D.Logic"/></div>
                <p>&nbsp;</p>

                <p>Аудит сайта позволяет выявить и устранить все препятствия для дальнейшего продвижения интернет
                    ресурса. Более того, поможет разработать дальнейший план действий для увеличения посещаемости сайта,
                    его запоминаемости, эффективности и конверсии. Проведя аудит своего сайта, вы сможете использовать
                    его потенциал в более полном объеме. Что, естественно, отразится на росте его узнаваемости и
                    прибыльности.</p>

                <h2 className="service-subtitle sub-dop">Некоторые составляющие комплексного аудита сайта </h2>
                <div className="result-list">
                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Технический аудит</span>
                    </div>
                    <div className="result-list-descr">Технический аудит - это анализ программной части сайта. На этом
                        этапе проверяется:
                        <ul>
                            <li>адаптивность под экраны разных мобильных устройств</li>
                            <li>кроссбраузерность. Т.е., способность корректно отображаться на различных популярных
                                браузерах
                            </li>
                            <li>валидность html-кода</li>
                            <li>наличие битых ссылок</li>
                            <li>корректность работы навигации</li>
                            <li>корректность работы кнопок социальных сетей</li>
                        </ul>
                    </div>
                    <div>&nbsp;</div>
                    <div className="mn-center dp-img"><img src="/assets/photos/14-audit_3.jpg" className="mn-image"
                                                           alt="Всесторонний аудит сайта"
                                                           title="Всесторонний аудит сайта для SEO - студия D.Logic"/>
                    </div>
                    <p>&nbsp;</p>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Поисковый аудит</span>
                    </div>
                    <div className="result-list-descr">Анализ того, оптимизирован ли сайт под требования поисковых
                        систем. Сюда входят такие работы:
                        <ul>
                            <li>проверка наличия файлов robots.txt и sitemap.xml, и правильность их настройки</li>
                            <li>анализ структуры url-адресов страничек сайта. Т.е., "человекопонятны" ли они</li>
                            <li>анализ внешней ссылочной массы</li>
                            <li>структура исходящих ссылок</li>
                            <li>наличие дубликатов страниц сайта</li>
                            <li>рекомендации по микроразметке</li>
                        </ul>
                    </div>
                    <div>&nbsp;</div>
                    <div className="mn-center"><img src="/assets/photos/14-audit_2.jpg" className="mn-image"
                                                    style={{borderRadius: '10px'}} alt="Поисковый аудит"
                                                    title="Анализ и всестороннний аудит сайтов от D.Logic"/></div>
                    <p>&nbsp;</p>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Анализ юзабилити сайта</span>
                    </div>
                    <div className="result-list-descr">Интеллект поисковых машин постоянно улучшается. И они, как и
                        люди, начинают присматриваться к поведению пользователей на страницах сайта. Учитывают их время
                        нахождения, количество просмотренных за сеанс страниц. Поэтому современный сайт должен обладать:
                        <ul>
                            <li>дружелюбным дизайном</li>
                            <li>понятным и логичным интерфейсом</li>
                            <li>адаптивностью и кроссбраузерностью</li>
                            <li>не перегруженным повторами</li>
                            <li>легким в загрузке (пользователь не должен ждать, когда загрузится страница)</li>
                            <li>шрифты должны читаться легко</li>
                            <li>в формах заказа должно быть минимально необходимое число полей</li>
                            <li>прочие требования</li>
                        </ul>
                    </div>
                    <div>&nbsp;</div>
                    <div className="mn-center"><img src="/assets/photos/14-audit_5.jpg" className="mn-image"
                                                    alt="Поисковый аудит в деталях"
                                                    title="Подробный аудит сайта от D.Logic"/></div>
                    <p>&nbsp;</p>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Контентный анализ</span>
                    </div>
                    <div className="result-list-descr">Страницы сайта проверяются на соответствие таким, например,
                        условиям:
                        <ul>
                            <li>title, description, h1 и h2 должны соответствовать или быть релевантными содержанию
                                страниц
                            </li>
                            <li>оптимизация страниц под нужные ключевые запросы</li>
                            <li>&quot;разбавлен&quot; ли контент страницы таблицами, фото- и видеоматериалами</li>
                            <li>уникальность, экспертность и актуальность содержания страницы</li>
                            <li>пригодность контента страниц для чтения человеком</li>
                        </ul>
                    </div>
                    <div>&nbsp;</div>
                    <div className="mn-center"><img src="/assets/photos/14-audit_4.jpg" className="mn-image"
                                                    alt="Уникальность контента для SEO"
                                                    title="Аудит контента сайта на уникальность"/></div>
                    <p>&nbsp;</p>

                    <div className="result-list-title"><i className="fas fa-check"
                                                          style={{color: '#09d542', fontSize: '20px'}}/><span>Анализ посадочных страниц (если таковые имеются)</span>
                    </div>
                    <div className="result-list-descr">Здесь предполагается подбор рекомендаций по созданию и изменению
                        у страниц заголовков title, описания description, заголовков h1 и h2, содержанию и расположению
                        контента.
                    </div>
                </div>

                <h2 className="service-subtitle sub-dop">Как заказать аудита сайта в студии D.Logic</h2>
                <p>По телефону, который приведен ниже, вы можете заказать услугу аудита сайта или получить ответы на все
                    интересующие вас вопросы. С нашей стороны клиенты гарантированно получают индивидуальный подход,
                    качественное выполнение заказа, справедливую цену. Конечная стоимость работ зависит только от
                    сложности и срочности заказа.</p>
                <p>&nbsp;</p>

                <div className="mn-panel mn-blue mn-card-4 mn-center mn-padding-16 inner-panel-1">
                    <b>(099)370-35-37</b><br/> Звоните. Ответим на все ваши вопросы.</div>
            </div>
        </div>
    )
}

export default Service14;