import React from 'react';

const TextUnderTopPhoto = (props) => {
    return (
        <div className="mn-container">
            <div className="mn-container mn-center mn-col l5">
                <img src={props.photoUrl}
                     className="mn-image" alt={props.photoAlt}
                     title={props.photoTitle}/>
            </div>
            <div className="mn-col l7 dop-1">
                <h2>{props.title}</h2>
                <div className="mn-justify mn-padding-12 main-txt">
                    {props.textBody}
                </div>
            </div>
        </div>
    );
}

export default TextUnderTopPhoto;