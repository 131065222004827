import React from 'react';

const TechnologyStack = (props) => {

    let technologyStack = props.stack.map(item => {
        return (
            <div className="main-carusel-item"><img src={item.imgUrl} className="mn-image"
                                                    alt={item.alt} title={item.title}/></div>
        )
    })

    return (
        <div className="mn-container mn-padding-64">
            <div className="mn-center"><h3>{props.title}</h3></div>
            <div className="owl-carousel owl-theme main-carusel">
                {technologyStack}
            </div>
        </div>
    );
}

export default TechnologyStack;