//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    mainInfo: {
        brcr: {
            link1: '/',
            linkName1: 'Главная',
            link2: '/services',
            linkName2: 'Список наших услуг',
            brcrPageName: ''
        },
        pageTitle: '',
        pageText: [
            <div>

            </div>
        ],
        serviceList: [
            {
                id: 1,
                toUaPage: 'stvorennya-korporatyvnyh-saitiv',
                toRuPage: 'sozdanie-korporativnyh-saitov',
                serviceDescr1: 'Разработка корпоративного сайта',
                serviceTitle: 'Создание корпоративного сайта под ключ',
            },
            {
                id: 2,
                toUaPage: 'rozrobka-internet-magazyniv',
                toRuPage: 'razrabotka-internet-magazinov',
                serviceDescr1: 'Создание интернет магазина',
                serviceTitle: 'Создание интернет магазина от D.Logic',
            },
            {
                id: 3,
                toUaPage: 'stvorennya-saitu-vizytky',
                toRuPage: 'sozdanie-saita-vizitki',
                serviceDescr1: 'Разработка сайта визитки',
                serviceTitle: 'Создание сайта визитки под ключ',
            },
            {
                id: 4,
                toUaPage: 'rozrobka-landing-page',
                toRuPage: 'razrabotka-landing-page',
                serviceDescr1: 'Создание лендинга',
                serviceTitle: 'Создание Landing Page',
            },
            {
                id: 5,
                toUaPage: 'stvorennya-mobilnoi-versii-saitu',
                toRuPage: 'sozdanie-mobilnoi-versii-saita',
                serviceDescr1: 'Создание мобильной версии сайта',
                serviceTitle: 'Создание мобильной версии сайта',
            },
            {
                id: 6,
                toUaPage: 'rozrobka-saitu-kataloga',
                toRuPage: 'razrabotka-saita-kataloga',
                serviceDescr1: 'Создание сайта каталога',
                serviceTitle: 'Создание сайта каталога',
            },
            {
                id: 7,
                toUaPage: 'rozrobka-onlain-servisiv',
                toRuPage: 'razrabotka-onlain-servisov',
                serviceDescr1: 'Создание личного кабинета',
                serviceTitle: 'Разработка личного кабинета на вашем сайте',
            },
            {
                id: 8,
                toUaPage: 'kompleksna-pidtrymka-saitiv',
                toRuPage: 'kompleksnaya-podderzshka-saitov',
                serviceDescr1: 'Поддержка сайтов, сервисов',
                serviceTitle: 'Поддержка, сопровождение и обслуживание сайтов',
            },
            {
                id: 9,
                toUaPage: 'rozrobka-dyzainu-saita',
                toRuPage: 'razrabotka-dizaina-saita',
                serviceDescr1: 'Создание дизайна сайта',
                serviceTitle: 'Разработка качественного дизайна сайта',
            },
            {
                id: 10,
                toUaPage: 'rozrobka-dyzainu-internet-magazynu',
                toRuPage: 'razrabotka-dizaina-internet-magazina',
                serviceDescr1: 'Создание дизайна интернет магазина',
                serviceTitle: 'Разработка дизайна для интернет магазина',
            },
            {
                id: 11,
                toUaPage: 'proektuvannya-interfeisiv',
                toRuPage: 'proektirovanie-interfeisov',
                serviceDescr1: 'Создание интерфейсов',
                serviceTitle: 'Дизайн и проектирование пользовательского интерфейса',
            },
            {
                id: 12,
                toUaPage: 'redyzain-saitu',
                toRuPage: 'redizain-saita',
                serviceDescr1: 'Редизайн сайта',
                serviceTitle: 'Создание нового дизайна для сайта. Редизайн сайта',
            },
            {
                id: 13,
                toUaPage: 'internet-reklama-u-google-facebook',
                toRuPage: 'internet-reklama-v-google-facebook',
                serviceDescr1: 'Реклама в интернете',
                serviceTitle: 'Настройка контекстной рекламы в Google',
            },
            {
                id: 14,
                toUaPage: 'kompleksnyi-audyt-saitu',
                toRuPage: 'kompleksnyi-audit-saita',
                serviceDescr1: 'Полный аудит сайта',
                serviceTitle: 'Профессиональный поисковый аудит сайта',
            },
            {
                id: 15,
                toUaPage: 'poshukove-prosuvannya-saitu',
                toRuPage: 'poiskovoe-prodvizenie-saita',
                serviceDescr1: 'SEO продвижение сайта',
                serviceTitle: 'SEO продвижение сайта',
            },
            {
                id: 16,
                toUaPage: 'pidbir-domenu-hostyng',
                toRuPage: 'podbor-domena-hosting',
                serviceDescr1: 'Подбор домена, хостинг',
                serviceTitle: 'Регистрация доменов и хостинг сайтов',
            },
            {
                id: 17,
                toUaPage: 'rozrobka-logotypu',
                toRuPage: 'razrabotka-logotipa',
                serviceDescr1: 'Создание логотипа',
                serviceTitle: 'Разработка логотипа в Киеве',
            },
            {
                id: 18,
                toUaPage: 'rozrobka-korporatyvnogo-stylyu',
                toRuPage: 'razrabotka-korporativnogo-stilya',
                serviceDescr1: 'Создание корпоративного стиля',
                serviceTitle: 'Разработка фирменного стиля',
            },
            {
                id: 19,
                toUaPage: 'rozrobka-brendbuku',
                toRuPage: 'sozdanie-brendbuka',
                serviceDescr1: 'Разработка брендбука для организации',
                serviceTitle: 'Создание брендбука',
            },
        ]
    }
}

const serviceReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default serviceReducer;