//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

let initialState = {
    footerDlogicAndSocialsIcons: {
        title: 'D.Logic',
        description: 'всегда поможем вам реализовать новые идеи и вывести ваши проекты на новый уровень',
        socialsIcons: [
            {id: 1, link: 'https://www.facebook.com', iconTitle: 'Мы в Facebook', iconStyle: 'fab fa-facebook-f'},
            {id: 2, link: 'https://twitter.com', iconTitle: 'Мы в Twitter', iconStyle: 'fab fa-twitter'},
            {id: 3, link: 'https://telegram.org', iconTitle: 'Мы в Telegram', iconStyle: 'fab fa-telegram-plane'}
        ]
    },
    footerServices: {
        title: 'Услуги',
        serviceList: [
            {id:1, link: '/', linkText: 'Создание и разработка сайта'},
            {id:2, link: '/service/razrabotka-internet-magazinov', linkText: 'Создание интернет магазина'},
            {id:3, link: '/service/sozdanie-korporativnyh-saitov', linkText: 'Разработка корпоративного сайта'},
            {id:4, link: '/service/sozdanie-saita-vizitki', linkText: 'Создание сайта визитки'},
            {id:5, link: '/service/razrabotka-dizaina-saita', linkText: 'Разработка дизайна сайта'},
            {id:6, link: '/zakazat-uslugu', linkText: 'Сделать заказ'}
        ]
    },
    footerStudios: {
        title: 'Студия',
        studiosList: [
            {id:1, link: '/company', linkText: 'О нас'},
            {id:2, link: '/services', linkText: 'Услуги'},
            {id:3, link: '/portfolio', linkText: 'Портфолио'},
            {id:4, link: '/clients', linkText: 'Клиенты'},
            {id:5, link: '/contact', linkText: 'Контакты'}
        ]
    },
    footerContacts: {
        title: 'Контакты',
        contactList: [
            {id:1, icon: '/assets/pics/main_bott_menu_icon_1.png',
                contact1: 'Украина, г. Киев',
                contact2: 'ул. Тимошенко, 26 (Оболонь)'},
            {id:2, icon: '/assets/pics/main_bott_menu_icon_2.png',
                contact1: '+38 (044) 383-20-18',
                contact2: '+38 (099) 370-35-37'},
            {id:3, icon: '/assets/pics/main_bott_menu_icon_5.png',
                contact1: 'mail [at] dlogic.com.ua',
                contact2: ''}
        ]
    }
}

const ruFooterReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default ruFooterReducer;