import React from 'react';
import PerformedSiteProjectsTitle from "./PerformedSiteProjectsTitle/PerformedSiteProjectsTitle";
import PerformedSiteProjectsList from "./PerformedSiteProjectsList/PerformedSiteProjectsList";
import PerformedSiteProjectsButton from "./PerformedSiteProjectsButton/PerformedSiteProjectsButton";
import PerformedLogotypeProjectsTitle from "./PerformedLogotypeProjectsTitle/PerformedLogotypeProjectsTitle";
import PerformedLogotypeProjectsList from "./PerformedLogotypeProjectsList/PerformedLogotypeProjectsList";
import PerformedLogotypeProjectsButton from "./PerformedLogotypeProjectsButton/PerformedLogotypeProjectsButton";
import {connect} from "react-redux";

class PerformedProjectsContainer extends React.Component {
    render() {
        /*<div className="parallax-window main-6-0-2"
             data-parallax="scroll"
             data-image-src={this.props.performedProjects.performedProjectsInfo.fonImgUrl}>*/
        return (
            <div className="main-6-0-2">
                <div className="left-right-flex-pad">
                    <PerformedSiteProjectsTitle {...this.props.performedProjects.performedProjectsInfo} />
                    <PerformedSiteProjectsList {...this.props.performedProjects.performedProjectsInfo} />
                    <div className="mn-container mn-padding-16"/>
                    <PerformedSiteProjectsButton {...this.props.performedProjects.performedProjectsInfo} />
                    <div className="mn-container mn-padding-48"/>

                    <PerformedLogotypeProjectsTitle {...this.props.performedProjects.performedProjectsInfo} />
                    <PerformedLogotypeProjectsList {...this.props.performedProjects.performedProjectsInfo} />
                    <div className="mn-container mn-padding-16"/>
                    <PerformedLogotypeProjectsButton {...this.props.performedProjects.performedProjectsInfo} />
                    <div className="mn-container mn-padding-48"/>
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        performedProjects: state.landingPerformedProjects
    }
}

export default connect(mapStateToProps)(PerformedProjectsContainer);