import React from 'react';

const Service6 = () => {

    return (
        <div>
            <div className="l-info-block-txt">
                <p><b>Создание сайта каталога</b> – это идеальное решение для любой компании, прайс-листы у которых
                    содержат тысячи или десятки тысяч наименований продукции в десятках или сотнях разделах.</p>

                <p>Такие электронные каталоги содержат в себе полное описание товаров, технические и любые другие
                    характеристики товаров. Качественно созданный сайт каталог с удобной и понятной навигацией, с
                    возможностью осуществлять выборку или фильтрацию товаров по определенному критерию, ускорит для
                    пользователя такого каталога процесс поиска необходимой информации.</p>

                <p>Также, такой электронный каталог можно снабдить возможностью совершать онлайн продажу выбранных из
                    него товаров, что ускорит расширение клиентской базы.</p>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/3-ecatalog_1.jpg" className="mn-image"
                                                       alt="Вид созданного сайта каталога"
                                                       title="Создание сайта каталога"/></div>
                <p>&nbsp;</p>

                <p>Каталог сайт обязательно снабжается нами системой управления контентом или CMS (Content Management
                    System) для того, чтобы менеджеры компании заказчика могли самостоятельно менять на нем всю
                    текстовую информацию, цены, контактные данные, а также фото- и видеоматериалы. </p>

                <p><b>Время разработки сайта каталога:</b> 7 - 10 недель.</p>
            </div>

            <div className="service-title-2">Что получает заказчик в результате?</div>
            <h2 className="service-subtitle">1. Основные моменты</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Уникальный дизайн</span>
                </div>
                <div className="result-list-descr">Каждый сайт каталог получает Авторский дизайн от нашего творческого
                    коллектива в соответствии с пожеланиями заказчика. Для каждого проекта мы готовим до 2 (двух)
                    вариантов дизайна для главной страницы, внутренней страницы, витрины с выкладкой товаров, товарной
                    страницы и предоставляем их клиенту для выбора.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Адаптивность и кроссбраузерность</span>
                </div>
                <div className="result-list-descr">Каждый интернет каталог созданный в D.Logic имеет адаптивный дизайн,
                    т.е. корректно отображается на экранах любого размера – от маленьких на смартфонах и планшетах, до
                    больших у ноутбуков и мониторов настольных систем. Более того, код каталога позволяем ему корректно
                    отображаться во всех популярных браузерах настольных и мобильных системах - Chrome, Firefox, Opera,
                    Internet Explorer, Safari и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Система управления контентом (CMS)</span>
                </div>
                <div className="result-list-descr">Сайт снабжается удобной Системой управления контентом, чтобы
                    пользователь мог легко самостоятельно обновлять любую информацию: цены, тексты, фото- и
                    видеоматериалы.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Начальное наполнение сайта</span>
                </div>
                <div className="result-list-descr">Осуществляем первоначальное наполнение сайта каталога всей
                    необходимой информацией, объем которой согласовывается с заказчиком.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Размещение проекта на веб-сервере</span>
                </div>
                <div className="result-list-descr">После выполнения всех работ по созданию сайта, наши специалисты
                    размещают все материалы каталога на веб-сервере и настраивают его работу.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Обучение работе с админкой каталога</span>
                </div>
                <div className="result-list-descr">После размещения проекта на веб-сервере, мы проводим обучение
                    заказчика работе с электронным каталогом. Процесс обучение обычно длится 1-2 часа и хорошо
                    воспринимается неподготовленным пользователем.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/3-ecatalog_2.jpg" className="mn-image"
                                                       alt="Разработка админки для каталога"
                                                       title="Создание админки для сайта каталога"/></div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">2. Функционал каталога</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Новостная лента</span>
                </div>
                <div className="result-list-descr">Данный функционал позволяет отображать новости компании на страницах
                    сайта. Краткое содержание новостей может отображаться на других страница ресурса.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Каталог товаров или витрина</span>
                </div>
                <div className="result-list-descr">Группировка товаров по категориям и подкатегориям, отображение их в
                    соответствующем порядке.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Выборки и фильтры подбора товара</span>
                </div>
                <div className="result-list-descr">Данный функционал позволяет делать поиск товара или группы товаров,
                    осуществлять выборку или фильтрацию товаров по определенному критерию, выполнять ранжир при
                    отображении товаров на витрине по тому или иному критерию, структурировать результаты выборки.
                    Менять тип отображения списка товаров.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Ярлык на товарах</span>
                </div>
                <div className="result-list-descr">Назначение товарам ярлыка: новинка, акция, хит и т.п.</div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Отзывник на товар</span>
                </div>
                <div className="result-list-descr">Позволяет посетителям писать отзывы на товары. Все отзывы проходят
                    модерацию, прежде чем будут опубликованы на сайте.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Личный кабинет (напр., для дилеров)</span>
                </div>
                <div className="result-list-descr">Можно просматривать список товаров с персональными скидками,
                    редактировать контактные данные.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Поиск по каталогу</span>
                </div>
                <div className="result-list-descr">Дает возможность пользователю производить поиск информации,
                    размещенной в интернет каталоге.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Форма обратной связи</span>
                </div>
                <div className="result-list-descr">Позволяет пользователям отправлять сообщения или запросы по поводу
                    той или иной интересующей их информации.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Фотогалерея</span>
                </div>
                <div className="result-list-descr">Позволяет на сайте отображать пользователям набор фотографий в
                    наилучшем виде.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Карта проезда</span>
                </div>
                <div className="result-list-descr">На основе сервиса Google Maps, на сайте можно разместить
                    интерактивную масштабируемую карту подъезда к офису.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Статистика сайта</span>
                </div>
                <div className="result-list-descr">По желанию заказчика на сайт ставим систему статистики и аналитики
                    Google Analytics и рассказываем об основных моментах работы с ней.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/3-ecatalog_3.jpg" className="mn-image"
                                                       alt="Разнообразие функционала электронного каталога"
                                                       title="Созданный сайт каталог содержит в себе богатый функционал"/>
                </div>
                <p>&nbsp;</p>
            </div>

            <h2 className="service-subtitle">3. Дополнительно</h2>
            <div className="result-list">
                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Иконки переходов в группы соц. сетей</span>
                </div>
                <div className="result-list-descr">Есть возможность размещать на сайте иконки и виджеты групп социальных
                    сетей (Facebook, Twitter).
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Формирование Sitemap</span>
                </div>
                <div className="result-list-descr">Формирование XML-карты каталога для создания условий более полной
                    индексации страниц поисковыми системами Google, Yandex и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Понятный URL (ЧПУ)</span>
                </div>
                <div className="result-list-descr">Каждый сайт-каталог имеет короткие и понятные внутренние ссылки. Это
                    позволяет людям и поисковым машинам распознавать содержимое страниц по URL.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита админки интернет каталога</span>
                </div>
                <div className="result-list-descr">Доступ к админке сайта каталога не имеет прямых ссылок со страниц
                    сайта. Это не позволяет автоматически обнаруживать ее URL анализаторами кода. Также, используются и
                    другие приемы препятствующие определению типа CMS сайта. Более того, сама система аутентификации
                    защищена от систем скоростного подбора паролей.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Защита и безопасность</span>
                </div>
                <div className="result-list-descr">Все наши сайты используют защиту базы данных от инъекций, защиту от
                    удаленного запуска, защиту от запуска скриптов и другие виды защит.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Кросспостинг</span>
                </div>
                <div className="result-list-descr">Есть возможность публиковать записи из новостной ленты сайта,
                    товарных страниц в аккаунтах Facebook, Twitter и др.
                </div>

                <div className="result-list-title"><i className="fas fa-check"
                                                      style={{color: '#09d542', fontSize: '20px'}}/><span>Оптимизация каталога</span>
                </div>
                <div className="result-list-descr">Внесение на сайт мета-информации, подбор ключевых слов, оптимизация
                    по ключевым словам.
                </div>
                <div>&nbsp;</div>
                <div className="mn-center dp-img"><img src="/assets/photos/3-ecatalog_4.jpg" className="mn-image"
                                                       alt="Сайт каталог и структура информации"
                                                       title="Структура и адаптивность сайта каталога"/></div>
                <p>&nbsp;</p>
            </div>
        </div>
    )
}

export default Service6;