import React from 'react';
import MainBrcr from "./MainBrcr";
import Service1 from "./ServicePages/Service1";
import Service2 from "./ServicePages/Service2";
import Service3 from "./ServicePages/Service3";
import Service4 from "./ServicePages/Service4";
import Service5 from "./ServicePages/Service5";
import Service6 from "./ServicePages/Service6";
import Service7 from "./ServicePages/Service7";
import Service8 from "./ServicePages/Service8";
import Service9 from "./ServicePages/Service9";
import Service10 from "./ServicePages/Service10";
import Service11 from "./ServicePages/Service11";
import Service12 from "./ServicePages/Service12";
import Service13 from "./ServicePages/Service13";
import Service14 from "./ServicePages/Service14";
import Service15 from "./ServicePages/Service15";
import Service16 from "./ServicePages/Service16";
import Service17 from "./ServicePages/Service17";
import Service18 from "./ServicePages/Service18";
import Service19 from "./ServicePages/Service19";
import RightMenu from "./RightMenu";

const Main = (props) => {

    let servicePage = () => {
        switch (props.project.id) {
            case 1: return <Service1/>;
            case 2: return <Service2/>;
            case 3: return <Service3/>;
            case 4: return <Service4/>;
            case 5: return <Service5/>;
            case 6: return <Service6/>;
            case 7: return <Service7/>;
            case 8: return <Service8/>;
            case 9: return <Service9/>;
            case 10: return <Service10/>;
            case 11: return <Service11/>;
            case 12: return <Service12/>;
            case 13: return <Service13/>;
            case 14: return <Service14/>;
            case 15: return <Service15/>;
            case 16: return <Service16/>;
            case 17: return <Service17/>;
            case 18: return <Service18/>;
            case 19: return <Service19/>;
            default: return <></>;
        }
    }

    return (
        <div className="left-right-flex-pad mn-padding-16">
            <div className="inner-gap"/>
            <MainBrcr brcr={props.brcr} pageName={props.project.serviceDescr1} />

            <div className="mn-container mn-padding-16 main-rl-padding">
                <div className="mn-container mn-cell-row">
                    <div className="mn-cell l-info-block">
                        <div className="inner-info"><h1>{props.project.serviceTitle}</h1></div>
                        {servicePage()}
                    </div>
                    <RightMenu
                        ruServicesListInfo={props.ruServicesListInfo}
                        path={props.path}/>
                </div>
            </div>
        </div>
    )
}

export default Main;