import React from 'react';
import {connect} from "react-redux";
import TimeLine from "./TimeLine";

class TimeLineContainer extends React.Component {
    render() {
        return (
            <div>
                <TimeLine {...this.props.timeLine.timeLineInfo} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        timeLine: state.landingTimeLine
    }
}

export default connect(mapStateToProps)(TimeLineContainer);