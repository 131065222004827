import React from 'react';
import {connect} from "react-redux";
import TimeLineButton from "./TimeLineButton";

class TimeLineButtonContainer extends React.Component {
    render() {
        return (
            <div>
                <TimeLineButton {...this.props.landingTimeLineButton.button} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        landingTimeLineButton: state.landingTimeLineButtonR
    }
}

export default connect(mapStateToProps)(TimeLineButtonContainer);