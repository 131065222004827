//const SET_FOOTER_DLOGIC = 'SET_FOOTER_DLOGIC';

import React from "react";

let initialState = {
    seoTexts: [
        {id: 1, textTitle: 'Разработать сайт для решения бизнес задач.', textBody: [<p>Мы предлагаем функциональные и высокопроизводительные решения для вашего бизнеса. Сайты, созданные нами, имеют модульную структуру. Что позволяет использовать, а значит и оплачивать, только то, что нужно для решения именно ваших задач. Ничего лишнего. Кроме того, все наши сайты имеют высокую производительность и способны выдерживать высокие нагрузки, что случается при наплыве большого количества посетителей.</p>,<p>Каждый сайт снабжается удобной системой управления контентом (CMS). Это позволяет легко добавлять, редактировать или удалять любую текстовую информацию, фото и видео материалы.</p>,<p>После создания и размещения сайта на веб сервере мы обучаем заказчика работе с сайтом.</p>]},
        {id: 2, textTitle: 'Что важно знать при создании сайта в Киеве.', textBody: [<p>Создание сайта с нуля — это именно тот инструмент, который достойным образом представит ваш бизнес потенциальному заказчику. В настоящее время сайт является важным моментом для быстрого построения успешного бизнеса. И именно сайты большинства современных компаний позволяют привлекать для них новых клиентов.</p>,<p>Создание продающего интернет-магазина или сайта продвигающего ваши услуги и сервисы – это главное направление деятельности компании D.Logic. Размещая у нас заказ на создание сайта, в результате вы получаете полностью готовое решение &quot;под ключ&quot; - сайт, который разработан, изготовлен, развернут на веб сервере и настроен для бесперебойной работы.</p>,<p>Обращаясь к нам, вы получаете комплексный подход к решению важных для вас задач при разработке сайта – начиная от составления технического задания (ТЗ) и создания прототипа проекта, до разработки индивидуального дизайна проекта и сборки сайта с адаптивным дизайном.</p>]}
    ]
}

const landingSeoTextsBlockReducer = (state = initialState, action) => {

    switch (action.type) {
        /*case SET_FOOTER_DLOGIC:
            return {
                ...state
            }*/

        default:
            return state;
    }
}

//export const setFooterDlogicAndSocials = (footerDlogicAndSocials) => ({type: SET_FOOTER_DLOGIC})

export default landingSeoTextsBlockReducer;